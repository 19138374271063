import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DistrictService} from "../../services/district.service";

@Component({
  selector: 'app-district-view',
  templateUrl: './district-view.component.html',
  styleUrls: ['./district-view.component.scss']
})
export class DistrictViewComponent implements OnInit {
  ready: boolean;
  selected: string;
  districtId: string;

  select(v: string) {
    this.selected = v;
  }

  isActive(v: string): boolean {
    return v === this.selected;
  }

  ngOnInit(): void {
    this.activeRoute.params.subscribe(p => {
      this.districtId = p['districtId'];
      this.ready = true;
    });
  }

  constructor(private district: DistrictService, private activeRoute: ActivatedRoute) {
    this.ready = false;
  }
}

import {Component, OnInit} from "@angular/core";
import {TitleService} from "../../services/title.service";
import {AuthService} from "../../services/authService";
import {Router} from "@angular/router";

@Component({
  selector: 'IntroPage',
  templateUrl: 'introPage.component.html',
  styleUrls: ['introPage.component.scss']
})
export class IntroPageComponent implements OnInit {
  constructor(
    private titleService: TitleService,
    private authService: AuthService,
    private router: Router) {
    this.titleService.setTitle("Welcome");
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(loggedIn => {
      if (loggedIn) {
        this.router.navigate(['/home'])
      }
    });
  }

}

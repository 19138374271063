export class MeetingsSubProblem {
  name: string;
  type: string;
  int_value: number;
  str_value: string;

  static createFrom(source: any) {
    const result = new MeetingsSubProblem();
    result.name = source['name'];
    result.type = source['type'];
    result.int_value = source['int_value'];
    result.str_value = source['str_value'];
    return result;
  }
}

<form>
  <div class="container">
    <div [ngClass]="containerClass()">
      <div [ngClass]="nameClass(districtName)">
        <label id="name_label">Name</label>
      </div>
      <div [ngClass]="dataClass()">
        <input type="text" class="form-control" placeholder="Name"
               aria-label="District Name" aria-describedby="name_label"
               name="district_name" [(ngModel)]="district.name" required
               minlength="4" #districtName="ngModel">
      </div>
    </div>

    <div [ngClass]="containerClass()">
      <div [ngClass]="nameClass(districtMaxActive)">
        <label id="maxactive_label">Max Active Students</label>
      </div>
      <div [ngClass]="dataClass()">
        <input type="number" class="form-control" placeholder="Maximum Active Students"
               aria-label="Max Active Students" aria-describedby="maxactive_label"
               name="district_domains" [(ngModel)]="district.max_active_students" required
               #districtMaxActive="ngModel">
      </div>
    </div>

    <div [ngClass]="containerClass()">
      <div [ngClass]="nameClass(districtDataPath)">
        <label id="datapath_label">Data Path</label>
      </div>
      <div [ngClass]="dataClass()">
        <input type="number" class="form-control" placeholder="Data Path"
               aria-label="Data Path" aria-describedby="datapath_label"
               name="district_datapath" [(ngModel)]="district.data_path" required
               #districtDataPath="ngModel">
      </div>
    </div>

  </div>
</form>

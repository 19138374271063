import {animate, animation, state, style, transition, trigger} from "@angular/animations";

export class Animations {
  public static validationFadeInOut = trigger('fadeInOut', [
    state('false',
      style({opacity: 0, position: 'relative', transform:'translateY(30px)', 'z-index':-1}),
    ),
    state('true',
      style({opacity: 1, position: 'relative', transform:'translateY(0px)', 'z-index':0, 'color':'red'})
    ),
    transition('* => *', [animate('200ms ease-in')])
  ])
}

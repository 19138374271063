export class Achievement {
  id: string;
  type: string;
  date: Date;
  evidence: string;

  static createFrom(source: any) {
    const result = new Achievement();
    result.id = source['id'];
    result.type = source['type'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.evidence = source['evidence'];
    return result;
  }
}


<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <span>{{message}}</span>
</div>
<div class="modal-footer">
  <button type="button" type="button" class="btn btn-outline-dark mr-auto" (click)="activeModal.close(true)">{{yesMessage}}</button>
  <button type="button" type="button" class="btn btn-outline-dark" (click)="activeModal.close(false)">{{noMessage}}</button>
</div>

import {Component} from "@angular/core";
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'PrivacyPage',
  templateUrl: 'privacyPage.component.html'
})
export class PrivacyPageComponent {
  constructor(private titleService: TitleService) {
    this.titleService.setTitle("Privacy Policy");
  }
}

<div class="container">
  <h4>Request a Demo</h4>
  <p>The Student Information and Data Review or SIDR is an individualized, student focused, data gathering system. This tool is designed to help educators collect academic and behavior progress monitoring data, across multiple measures, in order to provide targeted academic or behavior interventions.</p>

  <p>In order to provide education that serves to foster the whole child, SIDR stores academic, assessment, behavior, Social Emotional Learning and attendance data to effectively recognize which students are in need of intervention.</p>

  <p>Initially, through the Multi Tiered Systems of Support (MTSS) and Student Assistance Team process, staff will include information and data into the 6 sub categories in the SIDR. These area are:</p>
  <ul>
    <li>Meetings</li>
    <li>Student achievements</li>
    <li>Parent communication</li>
    <li>Behavior interventions</li>
    <li>Academic interventions</li>
    <li>Outside staff observations</li>
  </ul>
  <p>The above information is combined with the following data (uploaded from various sources) to give a complete picture of a student’s needs.</p>
  <ul>
    <li>Assessments (i.e. M-STEP, NWEA, STAR, DRA, DIBELS, etc.)</li>
    <li>Attendance</li>
    <li>Behavior</li>
    <li>Grades</li>
  </ul>

  <p>With the SIDR, staff and administrators no longer have to gather information from multiple locations or from multiple formats (i.e. digital, paper, etc).  Everything that is needed to examine the history, needs and progress of the whole student is right at the users fingertips.</p>

  <p>The design of the SIDR was heavily influenced by actual school staff and administrators, so it functions exactly as they need it to. The interface is user friendly and makes excellent use of staff time.</p>

  <p>
    <strong>Contact:</strong><br/>
    For more information or to schedule a demonstration, contact Ryan Bishop <a href="mailto:ryan@sidronline.com">ryan@sidronline.com</a>.
  </p>
</div>

<nav class="navbar" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}">
  <span class="navbar-brand">Individualized Reading Plan</span>
  <span class="mr-auto"></span>
  <button-new (btnClick)="open(null,null)" btnClass="btn-sm" [hidden]="selectedIRIP !== null" label="New"></button-new>
</nav>
<div class="table-responsive" [hidden]="selectedIRIP !== null">
  <table class="table">
    <thead>
      <tr>
        <td>Grade</td>
        <td>School Year</td>
        <td>Action</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let irip of irips; index as i">
        <td>{{irip?.grade}}</td>
        <td>{{irip?.school_year}}</td>
        <td>
          <span class="mr-auto"></span>
          <button-more (btnClick)="open(i, irip)" btnClass="pull-right"></button-more>
          <button (click)="print(irip.id)" class="btn ml-1 btn-primary">
            <i class="fa fa-print" aria-hidden="true"></i>
          </button>
          <button-delete btnClass="ml-1" (btnClick)="delete(irip.id)" [roles]="['can_admin']"></button-delete>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-print-none d-flex flex-row" *ngIf="selectedIRIP !== null">
  <app-student-irip #openIrip class="col" [districtId]="districtId" [studentId]="studentId" [(irip)]="selectedIRIP" (closeEvent)="close($event)"></app-student-irip>
</div>
<app-student-irip *ngFor="let irip of irips" class="d-none d-print-block" [studentId]="studentId" [irip]="irip"></app-student-irip>

import {Component, OnInit} from '@angular/core';
import {DistrictService} from '../../../services/district.service';
import {AuthService} from "../../../services/authService";
import {DistrictDto} from "../../../common/dtos/districtDto";

@Component({
  selector: `district-name`,
  templateUrl: 'districtName.component.html',
  styleUrls: ['districtName.component.scss']
})
export class DistrictNameComponent implements OnInit {
  name: string
  imageUrl: string;

  constructor(private districtService: DistrictService,
              private authService: AuthService) {
  }

  clean() {
    this.name = '';
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(r => {
        if (r) {
          const token = this.authService.parseJwt();
          if (token != null) {
            this.name = token.districtName;
          }

          this.districtService.get(token.districtId).subscribe((r: DistrictDto) => {
            if(r.districtImageUrl) {
              this.imageUrl = r.districtImageUrl;
            }
          });

        } else {
          this.clean();
        }
      }
    );

  }
}

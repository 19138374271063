import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: `arrayFilter`,
  pure: false
})
export class ArrayFilterPipe implements PipeTransform {
  transform(values: any[], ...filters: any[]): any[] {
    filters = filters.filter(x => x != undefined);
    if(filters.length === 0)
      return values;
    let ret = [];
    values.forEach(value => {
      let notKeep = false;
      for(let f = 0; f < filters.length; f++) {
        let filter = filters[f];
        for(let key in filter) {
          if(filter[key].length == 0) {
            continue;
          }
          notKeep = filter[key].indexOf(value[key]) === -1
          if(notKeep) {
            break;
          }
        }
      }
      if(!notKeep) {
        ret.push(value);
      }
    });
    return ret;
  }

}

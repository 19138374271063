/**
 * Created by ptdave on 6/1/17.
 */

import {CanDeactivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NgForm} from '@angular/forms';
import {StudentPageComponent} from "../../student/studentPage/studentPage.component";

@Injectable()
export class StudentCanDeactivate implements CanDeactivate<StudentPageComponent> {
  canDeactivate(component: StudentPageComponent): Observable<boolean> | Promise<boolean> | boolean {
    const forms: NgForm[] = [];

    if(component.RunningRecord && component.RunningRecord.Table) {
      forms.push(component.RunningRecord.Table.addForm);
    }
    if(component.Achievements && component.Achievements.Table) {
      forms.push(component.Achievements.Table.addForm);
    }
    if(component.OtherFactors && component.OtherFactors.Table) {
      forms.push(component.OtherFactors.Table.addForm);
    }
    if(component.InterventionBehaviors && component.InterventionBehaviors.Table) {
      forms.push(component.InterventionBehaviors.Table.addForm);
    }
    if(component.Communication && component.Communication.Table) {
      forms.push(component.Communication.Table.addForm);
    }

    for (let i = 0; i < forms.length; i++) {
      if(forms[i] && forms[i].form.dirty || !forms[i].form.pristine) {
        return confirm('Lose unsaved changes?');
      }
    }

    return true;
  }

}

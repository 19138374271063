import {Component, OnInit} from "@angular/core";
import {DistrictService} from "../../services/district.service";
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {LogDto} from "../../common/dtos/logDto";

@Component({
  selector: `district-logs`,
  templateUrl: 'districtLogs.component.html'
})
export class DistrictLogsComponent implements OnInit {
  districtId: string;
  pageSize = 25
  pageNum = 0
  contains: string
  logs: LogDto[]

  constructor(private districtService: DistrictService, private activeRoute: ActivatedRoute, private titleService: TitleService) {
  }

  ngOnInit(): void {
    this.activeRoute.parent.params.subscribe(p => {
      if (p['districtId'] == undefined || p['districtId'] == null)
        return;

      this.districtId = p['districtId']
      this.districtService.getLogs(this.districtId, this.contains, this.pageNum, this.pageSize).subscribe(x => {
        this.logs = x || [];
      })
    });
    this.titleService.setTitle("District Staff");

  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-building-admin',
  template: `
    <p>
      home-building-admin works!
    </p>
  `,
  styles: []
})
export class HomeBuildingAdminComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

/**
 * Created by ptdave on 6/25/17.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {StaffService} from '../../services/staff.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'problemDesc'
})
export class ProblemDescPipePipe implements PipeTransform {
  private output: Observable<string>;

  constructor(private staffService: StaffService) {

  }
  transform(value: any, ...args: any[]): string {
    if (!value) {
      return 'N/A';
    }

    const t = typeof value;

    if (t === 'number') {
      switch (value) {
        case 4:
          return '4 - Above Average';
        case 3:
          return '3 - Average';
        case 2:
          return '2 - Below Average';
        case 1:
          return '1 - Significantly Below Average';
        default:
          return 'N/A';
      }
    } else {
      switch (value) {
        case '4':
          return '4 - Above Average';
        case '3':
          return '3 - Average';
        case '2':
          return '2 - Below Average';
        case '1':
          return '1 - Significantly Below Average';
        default:
          return 'N/A';
      }
    }


  }
}

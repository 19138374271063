import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {Header} from "../../components/helpers/sortableTable/sortableTable.component";
import {Assessment} from "../../models/student/assessment.model";
import {StudentService} from "../../services/student.service";

@Component({
  selector: 'app-student-assessments',
  templateUrl: './student-assessments.component.html',
  styleUrls: ['./student-assessments.component.scss']
})
export class StudentAssessmentsComponent implements OnChanges {
  @Input() districtId: string;
  @Input() studentId: string;
  headers: Header[];
  assessments: Assessment[];
  loading = false;
  @Input() printOptions: PrintOptions;

  constructor(private studentService: StudentService, private route: ActivatedRoute) {
    this.assessments = [];
    this.headers = [];
    this.headers.push(new Header('School Year', 'school_year', true));
    this.headers.push(new Header('Grade', 'grade', true));
    this.headers.push(new Header('Period', 'period', true));
    this.headers.push(new Header('Type', 'type', true));
    this.headers.push(new Header('Measure', 'measure', true));
    this.headers.push(new Header('Score', 'score', false));
    this.headers.push(new Header('Benchmark', 'bench', true));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }

  getData() {
    if(this.studentId && this.districtId) {
      this.loading = true;
      this.studentService.getAssessments(this.districtId, this.studentId).subscribe((resp) => {
        this.assessments = resp || [];
        this.assessments.sort((a: Assessment, b: Assessment) => {
          return -1 * a.school_year.localeCompare(b.school_year);
        });
      }, (error) => {
        this.assessments = [];
      }, () => {
        this.loading = false;
      });
    }
  }

  printLine(a: Assessment): boolean {
    return true;
  }

  colorize(a: Assessment): string {
    if (a.bench === 'Yes' || a.bench === 'yes' || a.bench === 'YES') {
      return 'table-success';
    }
    if (a.bench === 'No' || a.bench === 'no' || a.bench === 'NO') {
      return 'table-danger';
    }
    if (a.score === 'A' || a.score === 'a' || a.score === 'A-' || a.score === 'a-') {
      return 'table-success';
    }
    if (a.score === 'B' || a.score === 'b' || a.score === 'B+' || a.score === 'b+' || a.score === 'B-' || a.score === 'b-') {
      return 'table-success';
    }
    if (a.score === 'C' || a.score === 'c' || a.score === 'C+' || a.score === 'c+' || a.score === 'C-' || a.score === 'c-') {
      return 'table-warning';
    }
    if (a.score === 'D' || a.score === 'd' || a.score === 'D+' || a.score === 'd+' || a.score === 'D-' || a.score === 'd-') {
      return 'table-warning';
    }
    if (a.score === 'E' || a.score === 'e') {
      return 'table-danger';
    }
  }

  distinctSchoolYears(): string[] {
    const ret: string[] = [];

    if (this.assessments !== null) {
      for (let i = 0; i < this.assessments.length; i++) {
        if (ret.indexOf(this.assessments[i].school_year) > -1) {
          continue;
        }
        ret.push(this.assessments[i].school_year);
      }
    }

    ret.sort((a: string, b: string) => {
      return a.localeCompare(b) * -1;
    });

    return ret;
  }

  valuesForYear(year: string) {
    const ret: Assessment[] = [];

    if (this.assessments !== null) {
      for (let i = 0; i < this.assessments.length; i++) {
        if (this.assessments[i].school_year === year) {
          ret.push(this.assessments[i]);
        }
      }
    }

    return ret;
  }
}

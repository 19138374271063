<span class="container">All add and removes are immediate. No saving necessary</span>
<ul ngbNav  #nav="ngbNav" class="nav-tabs">
  <li ngbNavItem>
    <a ngbNavLink>Information</a>
    <ng-template ngbNavContent>
      <form #meetingTopics="ngForm">
        <div class="card">
          <div class="card-header">Topics</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex flex-row" *ngFor="let topic of topics">
              <span class="d-inline-block mr-auto">{{topic.name}}</span>
              <button-delete btnClass="btn-sm" (btnClick)="deleteTopic(topic)"></button-delete>
            </li>
            <li class="list-group-item d-flex flex-row">
              <input class="form-control d-flex" name="newTopicName" [(ngModel)]="newTopic.name" minlength="3">
              <button-add btnClass="btn-sm" class="align-self-center" (btnClick)="addTopic(newTopic)"></button-add>
            </li>
          </ul>
        </div>
      </form>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Problems</a>
    <ng-template ngbNavContent>
      <module-meetings-problems [districtId]="districtId"></module-meetings-problems>
    </ng-template>
  </li>
  <li ngbNavItem>
    <a ngbNavLink>Strengths / Concerns</a>
    <ng-template ngbNavContent>
      <form #meetingStrCon>
        <div class="card">
          <div class="card-header">Strength and Concerns</div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex flex-row" *ngFor="let strcon of strcons">
              <span class="d-inline-block mr-auto">{{strcon.name}}</span>
              <button-delete class="align-self-center" btnClass="btn-sm" (btnClick)="deleteStrCon(strcon)"></button-delete>
            </li>
            <li class="list-group-item d-flex flex-row">
              <input class="form-control" name="newStrCon" [(ngModel)]="newStrCon.name">
              <button-add btnClass="btn-sm" (btnClick)="addStrCon()"></button-add>
            </li>
          </ul>
        </div>
      </form>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav" class="mt-2"></div>

/**
 * Created by ptdave on 6/25/17.
 */

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Location} from '@angular/common';


import {Router} from '@angular/router';
import {Role} from '../models/district/role.model';
import {HttpClient} from '@angular/common/http';

import {StaffDto} from "../common/dtos/staffDto";
import {StudentDto} from "../common/dtos/studentDto";
import {TokenDto} from "../common/dtos/tokenDto";

@Injectable()
export class StaffService {
  constructor(private http: HttpClient, private location: Location, private router: Router) {

  }

  get(staff_id: string, includeStudents?: boolean, includeRoles?: boolean): Observable<StaffDto> {
    if(staff_id?.length == 0) {
      return null;
    }

    const postFix = [
      `students=${includeStudents != undefined ? includeStudents : false}`,
      `roles=${includeRoles != undefined ? includeRoles : false}`,
    ].join('&');
    return this.http.get<StaffDto>(`/api/staff/${staff_id}?${postFix}`);
  }
  getRoles(staff_id: string): Observable<Role> {
    return this.http.get<Role>('/api/staff/' + staff_id + '/roles');
  }

  getStudent(staff_id: string): Observable<StudentDto[]> {
    return this.http.get<StudentDto[]>('/api/staff/' + staff_id + '/students');
  }

  patch(staff: StaffDto): Observable<StaffDto> {
    return this.http.patch<StaffDto>('/api/staff/' + staff.id, staff);
  }

  impersonate(id: string): Observable<TokenDto> {
    return this.http.get<TokenDto>(`/api/staff/${id}/impersonate`);
  }

  getGoogleUpdate(staff: StaffDto): Observable<StaffDto> {
    return this.http.get<StaffDto>('/api/staff/' + staff.id + '/update');
  }
}

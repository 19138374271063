import {Component, Input, OnInit} from '@angular/core';
import {DistrictService} from '../../../services/district.service';
import {MeetingTopic} from '../../../models/district/meeting_topic.model';
import {MeetingsProblem} from '../../../models/student/meeting_problem.model';
import {MeetingsSubProblem} from '../../../models/student/meeting_subproblem.model';
import {MeetingsStrCon} from '../../../models/student/strcon.model';
import {ActivatedRoute} from "@angular/router";
import {DistrictMeetingProblemDto} from "../../../common/dtos/districtMeetingProblemDto";

@Component({
  selector: `module-meetings`,
  templateUrl: 'moduleMeetings.component.html'
})
export class ModuleMeetingsComponent implements OnInit {
  @Input() districtId: string;
  topics: MeetingTopic[];
  newTopic: MeetingTopic;

  problems: DistrictMeetingProblemDto[];

  strcons: MeetingsStrCon[];
  newStrCon: MeetingsStrCon;

  addStrCon() {
    this.newStrCon.districtId = this.districtId;
    this.districtService.postMeetingStrCon(this.districtId, this.newStrCon).subscribe(r => {
      this.newStrCon = {id: null, districtId: null, name: '', concern: null, concernEvidence: '', strength: null, strengthEvidence: ''};
      this.getStrCons();
    }, () => {

    });
  }

  addProblem() {
    this.problems.push(new DistrictMeetingProblemDto);
  }

  addSubProblem(index: number) {
    this.problems[index].sub_problems = this.problems[index].sub_problems || [];

    this.problems[index].sub_problems.push(new MeetingsSubProblem);
  }

  deleteStrCon(strcon: MeetingsStrCon) {
    this.districtService.deleteMeetingStrCon(this.districtId, strcon.id).subscribe(r => {
      this.getStrCons();
    }, () => {

    });
  }

  deleteProblem(index: number) {
    this.districtService.deleteMeetingProblem(this.districtId, this.problems[index].id).subscribe(r => {
      this.getProblems();
    }, () => {

    });
  }

  patchProblem(index: number) {
    this.districtService.patchMeetingProblems(this.districtId, this.problems[index]).subscribe(r => {
      this.problems[index] = r;
    }, () => {

    });
  }

  addTopic(topic: MeetingTopic) {
    this.districtService.postMeetingTopic(this.districtId, topic).subscribe(r => {
      this.newTopic = {id: null, districtId: this.districtId, name: ''};
      this.getTopics();
    }, (err) => console.log(err));
  }

  deleteTopic(topic: MeetingTopic) {
    this.districtService.deleteMeetingTopic(this.districtId, topic).subscribe(() => {
      this.getTopics();
    }, (err) => {
      console.log(err);
    });
  }

  getTopics() {
    this.districtService.getMeetingTopics(this.districtId).subscribe(r => {
      this.topics = r;
    }, () => {
      this.topics = [];
    });
  }

  getProblems() {
    this.districtService.getMeetingProblems(this.districtId).subscribe(r => {
      this.problems = r;
    }, () => {
      this.problems = [];
    });
  }

  getStrCons() {
    this.districtService.getMeetingStrCons(this.districtId).subscribe(r => {
      this.strcons = r;
    }, () => {
      this.strcons = [];
    });
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (p['districtId'] == undefined || p['districtId'] == null)
        return;

      this.districtId = p['districtId']
      this.getTopics();
      this.getProblems();
      this.getStrCons();
    });
  }

  constructor(private districtService: DistrictService, private activeRoute: ActivatedRoute) {
    this.districtId = 'me';
    this.newTopic = {id: null, districtId: null, name: ''};
    this.topics = [];
    this.newStrCon = {id: null, districtId: null, name: '', concern: null, concernEvidence: '', strength: null, strengthEvidence: ''};
  }
}

import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

(window as any).consoleLog = environment.consoleLog;

@Injectable()
export class LoggingService {
  Information(message?: any, ...optionalParams: any[]): boolean {
    if (!environment.production || (window as any).consoleLog) {
      console.info(message, optionalParams);
      return true;
    }
    return false;
  }

  Error(message?: any, ...optionalParams: any[]): boolean {
    if (!environment.production || (window as any).consoleLog) {
      console.error(message, optionalParams);
      return true;
    }
    return false;
  }

  Log(...data: any[]): boolean {
    if (!environment.production || (window as any).consoleLog) {
      console.log(...data);
      return true;
    }
    return false;
  }
}

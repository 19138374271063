import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {AuthService} from "../../services/authService";

@Directive({
  selector: `[isSignedIn]`
})
export class IsSignedInDirective implements OnInit {
  @Input('isSignedIn') isSignedIn: boolean = true;

  constructor(private el: ElementRef, private authToken: AuthService) {
  }

  ngOnInit(): void {
    this.authToken.isLoggedIn.subscribe(x => {
      this.el.nativeElement.style.display = x == this.isSignedIn ? '' : 'none';
    });
  }
}

/**
 * Created by ptdave on 5/30/17.
 */

export class ChartRow {
  data: number[];
  label: string;

  constructor(d: number[], l: string) {
    this.data = d;
    this.label = l;
  }
}

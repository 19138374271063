import {NgModule} from '@angular/core';
import {DistrictGeneralComponent} from './district.general/general.component';
import {DistrictReportsComponent} from './district.reports/reports.component';
import {DistrictBuildingsComponent} from './district.buildings/buildings.component';
import {DistrictStaffComponent} from './district.staff/staff.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ComponentsModule} from '../components/components.module';
import {CommonModule} from '@angular/common';
import {DistrictImportsComponent} from './district.imports/imports.component';
import {ModuleIRIPComponent} from './district.modules/irip/moduleIRIP.component';
import {ModuleMeetingsComponent} from './district.modules/meetings/moduleMeetings.component';
import {DistrictViewComponent} from './district.view/district-view.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {SIDRCommonModule} from '../common/sidrCommon.module';
import {DistrictModulesComponent} from './district.modules/districtModules.component';
import {ModuleBasicTypesComponent} from './district.modules/basicTypes/moduleBasicTypes.component';
import {ImportDescComponent} from './district.imports/importDesc.component';
import {DistrictLogsComponent} from './logs/districtLogs.component';
import {ModuleMeetingProblemsComponent} from './district.modules/meetings/meetingProblems.component';
import {ModuleMeetingProblemComponent} from './district.modules/meetings/meetingProblem.component';
import {DistrictContactComponent} from './district.contact/contact.component';
import {DistrictDomainsComponent} from './district.domains/domains.component';
import {DistrictSchoolYearComponent} from './district.modules/schoolYear/schoolYear.component';

@NgModule({
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ComponentsModule,
    FormsModule,

    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    SIDRCommonModule
  ],
  providers: [],
  declarations: [
    DistrictViewComponent,
    DistrictBuildingsComponent,
    DistrictDomainsComponent,
    DistrictGeneralComponent,
    DistrictContactComponent,
    DistrictReportsComponent,
    DistrictSchoolYearComponent,
    DistrictStaffComponent,
    DistrictImportsComponent,
    ImportDescComponent,
    DistrictModulesComponent,
    ModuleMeetingProblemsComponent,
    ModuleMeetingProblemComponent,
    DistrictLogsComponent,
    ModuleBasicTypesComponent,
    ModuleIRIPComponent,
    ModuleMeetingsComponent,
  ],
  exports: [
    DistrictViewComponent,
    DistrictBuildingsComponent,
    DistrictDomainsComponent,
    DistrictGeneralComponent,
    DistrictContactComponent,
    DistrictReportsComponent,
    DistrictSchoolYearComponent,
    DistrictStaffComponent,
    DistrictImportsComponent,
    ModuleBasicTypesComponent,
    ModuleIRIPComponent,
    ModuleMeetingsComponent,
    ModuleMeetingProblemsComponent,
    ModuleMeetingProblemComponent,
    ImportDescComponent,
    DistrictLogsComponent,
  ],
  bootstrap: [],
})
export class DistrictModule {

}


export class ApprInst {
  br_hq?: boolean;
  br_glce?: boolean;
  br_curriculum?: boolean;
  br_fidelity?: boolean;
  br_attendance?: boolean;
  rf_hq?: boolean;
  rf_glce?: boolean;
  rf_curriculum?: boolean;
  rf_fidelity?: boolean;
  rf_attendance?: boolean;
  rc_hq?: boolean;
  rc_glce?: boolean;
  rc_curriculum?: boolean;
  rc_fidelity?: boolean;
  rc_attendance?: boolean;
  we_hq?: boolean;
  we_glce?: boolean;
  we_curriculum?: boolean;
  we_fidelity?: boolean;
  we_attendance?: boolean;
  mc_hq?: boolean;
  mc_glce?: boolean;
  mc_curriculum?: boolean;
  mc_fidelity?: boolean;
  mc_attendance?: boolean;
  mp_hq?: boolean;
  mp_glce?: boolean;
  mp_curriculum?: boolean;
  mp_fidelity?: boolean;
  mp_attendance?: boolean;
  lc_hq?: boolean;
  lc_glce?: boolean;
  lc_curriculum?: boolean;
  lc_fidelity?: boolean;
  lc_attendance?: boolean;
  oe_hq?: boolean;
  oe_glce?: boolean;
  oe_curriculum?: boolean;
  oe_fidelity?: boolean;
  oe_attendance?: boolean;

  static createFrom(source: any) {
    const result = new ApprInst();
    result.br_hq = source['br_hq'];
    result.br_glce = source['br_glce'];
    result.br_curriculum = source['br_curriculum'];
    result.br_fidelity = source['br_fidelity'];
    result.br_attendance = source['br_attendance'];
    result.rf_hq = source['rf_hq'];
    result.rf_glce = source['rf_glce'];
    result.rf_curriculum = source['rf_curriculum'];
    result.rf_fidelity = source['rf_fidelity'];
    result.rf_attendance = source['rf_attendance'];
    result.rc_hq = source['rc_hq'];
    result.rc_glce = source['rc_glce'];
    result.rc_curriculum = source['rc_curriculum'];
    result.rc_fidelity = source['rc_fidelity'];
    result.rc_attendance = source['rc_attendance'];
    result.we_hq = source['we_hq'];
    result.we_glce = source['we_glce'];
    result.we_curriculum = source['we_curriculum'];
    result.we_fidelity = source['we_fidelity'];
    result.we_attendance = source['we_attendance'];
    result.mc_hq = source['mc_hq'];
    result.mc_glce = source['mc_glce'];
    result.mc_curriculum = source['mc_curriculum'];
    result.mc_fidelity = source['mc_fidelity'];
    result.mc_attendance = source['mc_attendance'];
    result.mp_hq = source['mp_hq'];
    result.mp_glce = source['mp_glce'];
    result.mp_curriculum = source['mp_curriculum'];
    result.mp_fidelity = source['mp_fidelity'];
    result.mp_attendance = source['mp_attendance'];
    result.lc_hq = source['lc_hq'];
    result.lc_glce = source['lc_glce'];
    result.lc_curriculum = source['lc_curriculum'];
    result.lc_fidelity = source['lc_fidelity'];
    result.lc_attendance = source['lc_attendance'];
    result.oe_hq = source['oe_hq'];
    result.oe_glce = source['oe_glce'];
    result.oe_curriculum = source['oe_curriculum'];
    result.oe_fidelity = source['oe_fidelity'];
    result.oe_attendance = source['oe_attendance'];
    return result;
  }
}

/**
 * Created by ptdave on 5/31/17.
 */


import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {StudentService} from '../../services/student.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {DistrictOtherFactor, DistrictService} from '../../services/district.service';
import {OtherFactor} from '../../models/student/otherfactor.model';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {DataType, Header, SortableTableComponent} from '../../components/helpers/sortableTable/sortableTable.component';
import {Level, ToastService} from '../../services/toast.service';
import {TypesService} from "../../services/types.service";
import {LoggingService} from "../../services/logging";

@Component({
  selector: `student-otherfactors`,
  templateUrl: 'otherFactors.component.html'
})
export class StudentOtherFactorsComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @ViewChild(SortableTableComponent) public Table: SortableTableComponent;
  @Input() districtId: string;
  @Input() studentId: string;

  constructor(private studentService: StudentService,
              private districtService: DistrictService,
              private route: ActivatedRoute,
              private router: Router,
              private typesService: TypesService,
              private toastService: ToastService,
              private log: LoggingService) {
    this.otherfactors = [];
    this.newOtherFactor = new OtherFactor();
    this.options = [];
    this.headers = [];

    this.headers.push(new Header('Date', 'date', false, DataType.Date, {
      dateFormat: 'shortDate'
    }));
    this.headers.push(new Header('Type', 'type', true, DataType.Select, {
      //dropdownValues: v
    }));
    this.headers.push(new Header('Description', 'desc', false, DataType.String));
  }

  otherfactors: OtherFactor[];
  newOtherFactor: OtherFactor;
  options: DistrictOtherFactor[];
  headers: Header[];
  descError = '';

  NotificationTitle = 'Other Factors';

  @ViewChild(NgForm) public Form: NgForm;

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }

  submit(f: NgForm) {
    this.studentService.postOtherFactors(this.districtId, this.studentId, this.newOtherFactor).subscribe((resp) => {
      this.getData();
      this.newOtherFactor = new OtherFactor();
      this.Form.resetForm();
    });
  }

  save(d: OtherFactor) {
    if (!d.id) {
      this.studentService.postOtherFactors(this.districtId, this.studentId, d).subscribe(resp => {
        this.getData();
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved other factors');
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, 'Error saving other factor');
      });
    } else {
      this.studentService.patchOtherFactors(this.districtId, this.studentId, d).subscribe(resp => {
        this.getData();
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved other factor');
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, 'Error saving other factor');
      });
    }
  }

  del(d: OtherFactor) {
    this.studentService.deleteOtherFactors(this.districtId, this.studentId, d.id).subscribe(resp => {
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Removed other factor');
      this.getData();
    });
  }

  public dirty(): boolean {
    return this.Form.dirty;
  }

  public pristine(): boolean {
    return this.Form.pristine;
  }


  getData() {
    if (!this.districtId && !this.studentId) {
      return;
    }
    this.log.Log('Getting other factors');
    this.typesService.getBasicType(this.districtId, TypesService.OtherFactor).subscribe(resp => {
      const v = (resp || []).map(v => v.name);

      this.headers[1] = new Header('Type', 'type', true, DataType.Select, {
        dropdownValues: v
      });
    });

    this.studentService.getOtherFactors(this.districtId, this.studentId).subscribe(resp => {
      this.otherfactors = resp || [];
      this.otherfactors.sort((a: OtherFactor, b: OtherFactor) => a.date > b.date ? -1 : 1);
    }, (resp) => {
      this.otherfactors = [];
    });
  }

  remove(index: number) {
    this.studentService.deleteOtherFactors(this.districtId, this.studentId, this.otherfactors[index].id).subscribe(resp => {
      this.otherfactors.splice(index, 1);
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: `button-add`,
  template: `    
    <button class="btn btn-success d-print-none {{btnClass}}" [type]="type" [disabled]="disabled" aria-label="Add" (click)="onClick()" ngbTooltip="Add">
      <i class="fa fa-plus" aria-hidden="true"></i> {{label}}
    </button>
  `
})
export class AddbuttonComponent {
  @Output() btnClick = new EventEmitter();
  @Input() disabled = false;
  @Input() type = 'button';
  @Input() btnClass = '';
  @Input() label = '';

  onClick(): void {
    this.btnClick.emit();
  }
}

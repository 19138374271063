import { Component, OnInit, AfterContentInit, Input, ContentChildren, QueryList, EventEmitter, Output } from '@angular/core';

@Component({
  selector: `sidr-radio-button`,
  template: ``,
})
export class RadioButtonComponent implements OnInit {
  @Input() public icon: string;
  @Input() public label: string;
  @Input() public tooltip: string;
  @Input() public class: string;
  @Input() public ariaLabel: string;
  @Input() public value: string;
  @Input() public disabled: boolean;

  constructor() {
    this.icon = '';
    this.label = '';
    this.tooltip = '';
    this.class = '';
    this.ariaLabel = '';
    this.value = '';
    this.disabled = false;
  }

  ngOnInit(): void {

  }
}

@Component({
  selector: 'sidr-radio-buttons',
  template: `
    <div class="btn-group">
      <button *ngFor="let b of children" class="btn {{b.class}}" type="button" [disabled]="b.disabled"
              [ngbTooltip]="b.tooltip" [ngClass]="{'active':model === b.value}"
              (click)="change(b.value)" [name]="b.value">
        <span [class]="b.icon"></span>{{b?.label}}
      </button>
    </div>
  `,
  styles: []
})
export class RadioButtonsComponent {
  @ContentChildren(RadioButtonComponent) children: QueryList<RadioButtonComponent>;
  @Input() model: any;
  @Output() modelChange = new EventEmitter<any>();
  change(v: any) {
    this.modelChange.emit(v);
  }

  constructor() {

  }
}

import {Component, Input, OnChanges} from '@angular/core';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {Student} from "../../models/student/student.model";
import {StudentService} from "../../services/student.service";

@Component({
  selector: 'app-student-information',
  templateUrl: './student-information.component.html',
  styleUrls: ['./student-information.component.scss']
})
export class StudentInformationComponent implements OnChanges {
  student: Student;
  @Input() districtId: string;
  @Input() studentId: string;
  loading: boolean;
  @Input()
  printOptions: PrintOptions;

  constructor(
    private studentService: StudentService
  ) {
    this.loading = false;
  }

  getData() {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.loading = true;
    this.studentService.get(this.districtId, this.studentId).subscribe(r => {
      this.student = r;
    }, (err) => {

    }, () => {
      this.loading = false;
    });
  }

  ngOnChanges() {
    this.getData();
  }
}

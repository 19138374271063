import { Title } from "@angular/platform-browser";
import { Injectable } from "@angular/core";

@Injectable()
export class TitleService {
  public setTitle(name: String) {
    this.title.setTitle(`SIDR Online - ${name}`);
  }
  constructor(private title: Title) {}
}

import {Injectable} from '@angular/core';


export enum Level {
  INFORMATION,
  ERROR,
  WARNING,
  SUCCESS
}

export class ToastConfig {
  public DismissedAfter: number;
  public AutoDismiss: boolean;
  public Color: string;
  public BoxClass: string;
  public TextClass: string;

  constructor() {

  }
}

export class Toast {
  public Level: Level;
  public Message: string;
  public CreatedOn: Date;
  public Config: ToastConfig;
  public OnDismissed: Function;
  public Title: string;
  public State: string;

  constructor(level: Level, title: string, message: string, config: ToastConfig) {
    this.Level = level;
    this.Title = title;
    this.Message = message;
    this.CreatedOn = new Date();
    this.Config = config;
    this.State = 'active';
  }
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  Toasts: Toast[];
  InformationConfig: ToastConfig;
  ErrorConfig: ToastConfig;
  WarningConfig: ToastConfig;
  SuccessConfig: ToastConfig;

  constructor() {
    this.Toasts = [];
    this.InformationConfig = new ToastConfig();
    this.ErrorConfig = new ToastConfig();
    this.SuccessConfig = new ToastConfig();
    this.WarningConfig = new ToastConfig();

    this.InformationConfig.AutoDismiss = true;
    this.InformationConfig.DismissedAfter = 10;
    this.InformationConfig.BoxClass = 'bg-info';
    this.InformationConfig.TextClass = 'text-light';

    this.SuccessConfig.AutoDismiss = true;
    this.SuccessConfig.DismissedAfter = 10;
    this.SuccessConfig.BoxClass = 'bg-success';
    this.SuccessConfig.TextClass = 'text-light';

    this.WarningConfig.AutoDismiss = true;
    this.WarningConfig.DismissedAfter = 10;
    this.WarningConfig.BoxClass = 'bg-warning';
    this.WarningConfig.TextClass = 'text-dark';

    this.ErrorConfig.AutoDismiss = true;
    this.ErrorConfig.DismissedAfter = 10;
    this.ErrorConfig.BoxClass = 'bg-danger';
    this.ErrorConfig.TextClass = 'text-light';
  }

  public DismissNotification(toast: Toast) {
    const index = this.Toasts.indexOf(toast);
    this.Toasts.splice(index, 1);
  }

  public AddNotification(level: Level, title: string, message: string, config?: ToastConfig) {
    if (!config) {
      switch (level) {
        case Level.ERROR:
          config = this.ErrorConfig;
          break;
        case Level.INFORMATION:
          config = this.InformationConfig;
          break;
        case Level.SUCCESS:
          config = this.SuccessConfig;
          break;
        case Level.WARNING:
          config = this.WarningConfig;
          break;
      }
    }
    this.Toasts.push(new Toast(level, title,  message, config));
  }
}

import {Component, Input, OnChanges} from '@angular/core';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {ChartRow} from "../../components/helpers/chartrow.class";
import {StudentService} from "../../services/student.service";
import {AttendanceByPeriod} from "../../models/student/attendance_by_period.model";

@Component({
  selector: 'app-student-attendance-by-period',
  templateUrl: './student-attendance-by-period.component.html',
  styleUrls: ['./student-attendance-by-period.component.scss']
})
export class StudentAttendanceByPeriodComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  attendance: AttendanceByPeriod[];

  public lineChartOptions: any = {responsive: true};
  public lineChartLegend = true;
  public lineChartType = 'bar';
  ready = false;

  get loading(): boolean {
    return !this.ready;
  }

  chartOptions: any = {
    responsive: true
  };
  charLegend = true;
  chartType = 'line';

  public dataset: Array<ChartRow> = [];
  public labels: string[] = ['Tardy', 'Excused', 'Unexcused', 'Total Days'];

  getDataSet() {
    this.dataset = new Array(this.attendance.length);
    for (let i = 0; i < this.attendance.length; i++) {
      this.dataset[i] = new ChartRow([], this.attendance[i].school_year);
    }
  }

  ngOnChanges(): void {
    this.getData();
  }

  getData() {
    if (!this.districtId && !this.studentId) {
      return;
    }
    this.ready = false;
    this.studentService
      .getAttedanceByPeriod(this.districtId, this.studentId)
      .subscribe(resp => {
        this.attendance = resp || [];
        this.attendance.sort(
          (a: AttendanceByPeriod, b: AttendanceByPeriod) => {
            return a.school_year.localeCompare(b.school_year);
          }
        );
      }, () => {
        this.attendance = [];
      }, () => {
        this.ready = true;
      });
  }

  constructor(private studentService: StudentService) {
  }
}

import {NgModule} from "@angular/core";
import {ComponentsModule} from "../components/components.module";
import {StudentPrintDialogComponent} from "./student.printdialog.component";
import {StudentOtherFactorsComponent} from "./otherFactors/student.otherfactors.component";
import {StudentBehaviorInterventionsComponent} from "./behaviorInterventions/student.behaviorinterventions.component";
import {StudentModuleComponent} from "../components/student-module/student-module.component";
import {StudentMeetingsComponent} from "./meeting/student.meetings.component";
import {StudentAppropriateinstructionComponent} from "./appropriateInstructions/student.appropriateinstruction.component";
import {StudentMeetingStrconsComponents} from "./meeting/student.meeting-strcons.components";
import {StudentFilesComponent} from "../components/student/student-files/student-files.component";
import {StudentObservationDialogComponent} from "./observations/student.observationdialog.component";
import {StudentContactDialog} from "./contact/student.contact.component";
import {StudentMeetingProblemComponent} from "./meeting/student.meeting-problem.component";
import {StaffStudentListComponent} from "../components/helpers/staffStudentList.component";
import {StudentPhotoComponent} from "../components/helpers/student-photo/student-photo.component";
import {StudentHistoricalBehaviorsComponent} from "./historicalBehaviors/student.historicalbehaviors.component";
import {StudentObservationsComponent} from "./observations/student.observations.component";
import {StudentMeetingInfoComponent} from "./meeting/student.meeting-info.component";
import {StudentMeetingNextstepsComponent} from "./meeting/student.meeting-nextsteps.component";
import {StudentCommunicationComponent} from "./communication/student.communication.component";
import {StudentIntvLogComponent} from "./interventionLog/student.intvlog.component";
import {StudentMeetingProblemsComponent} from "./meeting/student.meeting-problems.component";
import {DistrictModule} from "../district/district.module";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SIDRCommonModule} from "../common/sidrCommon.module";
import {StudentPageComponent} from "./studentPage/studentPage.component";
import {StudentInformationComponent} from "./information/student-information.component";
import {StudentAchievementsComponent} from "./achievements/student-achievements.component";
import {StudentAssessmentsComponent} from "./assessments/student-assessments.component";
import {StudentAttendanceComponent} from "./attendance/student-attendance.component";
import {StudentRunningRecordComponent} from "./runningRecord/student-runningrecord.component";
import {StudentMeetingComponent} from "./meeting/student.meeting.component";
import {StudentIripListComponent} from "./irip/student-irip-list.component";
import {StudentIripComponent} from "./irip/student-irip.component";
import {StudentAppropriateInstructionComponent} from "./appropriateInstructions/student-appropriate-instruction.component";
import {StudentAttendanceByPeriodComponent} from "./attendanceByPeriod/student-attendance-by-period.component";
import {ChartsModule} from "ng2-charts";
import {StudentIntvlogDialogComponent} from "./interventionLog/intvlog.modal";

@NgModule({
  bootstrap:[],
  declarations:[
    StudentPrintDialogComponent,
    StudentHistoricalBehaviorsComponent,
    StudentOtherFactorsComponent,
    StudentCommunicationComponent,
    StudentContactDialog,
    StudentAppropriateinstructionComponent,
    StudentBehaviorInterventionsComponent,
    StudentIntvLogComponent,
    StudentIntvlogDialogComponent,
    StudentObservationsComponent,
    StudentObservationDialogComponent,
    StudentMeetingsComponent,
    StudentMeetingInfoComponent,
    StudentMeetingProblemsComponent,
    StudentMeetingProblemComponent,
    StudentMeetingStrconsComponents,
    StudentMeetingNextstepsComponent,
//    StaffStudentListComponent,
    StudentPhotoComponent,
    StudentFilesComponent,
    StudentModuleComponent,
    StudentInformationComponent,
    StudentPageComponent,
    StudentAchievementsComponent,
    StudentAssessmentsComponent,
    StudentAttendanceComponent,
    StudentRunningRecordComponent,
    StudentMeetingComponent,
    StudentIripListComponent,
    StudentIripComponent,
    StudentAppropriateInstructionComponent,
    StudentAttendanceByPeriodComponent
  ],
  entryComponents:[
    StudentContactDialog,
    StudentIntvlogDialogComponent,
    StudentObservationDialogComponent
  ],
  exports:[
    StudentPrintDialogComponent,
    StudentHistoricalBehaviorsComponent,
    StudentOtherFactorsComponent,
    StudentCommunicationComponent,
    StudentContactDialog,
    StudentAppropriateinstructionComponent,
    StudentBehaviorInterventionsComponent,
    StudentIntvLogComponent,
    StudentIntvlogDialogComponent,
    StudentObservationsComponent,
    StudentObservationDialogComponent,
    StudentMeetingsComponent,
    StudentMeetingInfoComponent,
    StudentMeetingProblemsComponent,
    StudentMeetingProblemComponent,
    StudentMeetingStrconsComponents,
    StudentMeetingNextstepsComponent,
    //StaffStudentListComponent,
    StudentPhotoComponent,
    StudentFilesComponent,
    StudentModuleComponent,
    StudentInformationComponent,
    StudentPageComponent,
    StudentAchievementsComponent,
    StudentAssessmentsComponent,
    StudentAttendanceComponent,
    StudentRunningRecordComponent,
    StudentMeetingComponent,
    StudentIripListComponent,
    StudentIripComponent,
    StudentAppropriateInstructionComponent,
    StudentAttendanceByPeriodComponent
  ],
  imports: [
    ChartsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DistrictModule,
    ComponentsModule,
    SIDRCommonModule,
    NgbModule
  ],
  providers: [],
})
export class StudentModule {}

/**
 * Created by ptdave on 6/4/17.
 */

export function DateToLocalISOString(date: Date) {
    date = new Date(date);
    const tzo = -date.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = function (num) {
      const norm = Math.abs(Math.floor(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    const ret = date.getFullYear() +
      '-' + pad(date.getMonth() + 1) +
      '-' + pad(date.getDate() + 1) +
      'T' + pad(date.getHours()) +
      ':' + pad(date.getMinutes()) +
      ':' + pad(date.getSeconds()) +
      dif + pad(tzo / 60) +
      ':' + pad(tzo % 60);

    return ret;
}

export function formatDate(date) {
const d = new Date(date);
let month = '' + (d.getMonth() + 1);
let day = '' + d.getDate();
const year = d.getFullYear();

if (month.length < 2) {
    month = '0' + month;
}
if (day.length < 2) {
    day = '0' + day;
}

return [year, month, day].join('-');
}


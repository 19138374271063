/**
 * Created by ptdave on 6/25/17.
 */

import {Pipe, PipeTransform} from '@angular/core';
import { shareReplay, catchError, map } from 'rxjs/operators';
import {DistrictService} from "../../services/district.service";
import {AuthService} from "../../services/authService";
import { Observable, of } from 'rxjs';
import {DistrictBuildingDto} from "../../models/district/districtBuildingDto";

@Pipe({
  name: 'buildingName'
})
export class BuildingNamePipe implements PipeTransform {
  private cache: Observable<Record<string,string>> | null = null;

  constructor(private districtService: DistrictService, private authService: AuthService) {}

  transform(value: string, ...args: any[]): Observable<string> | string  {
    if(!this.cache) {
      this.cache = this.districtService.getBuildings(this.authService.parseJwt().districtId)
        .pipe(
          map((buildings: DistrictBuildingDto[]) => {
            return buildings.reduce((acc, building) => {
              acc[building.id] = building.name;
              return acc;
            }, {} as Record<string, string>);
          }),
          shareReplay(1),
          catchError((err) => {
            delete this.cache;
            return of({error: err});
          })
        );
    }
    return this.cache.pipe(
      map((buildingNames: Record<string, string>) => {
        return buildingNames[value] || "Unknown Building";
      })
    );
  }
}

/**
 * Created by ptdave on 5/31/17.
 */

import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {StudentService} from '../../services/student.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Behavior} from '../../models/student/behavior.model';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {DataType, Header} from '../../components/helpers/sortableTable/sortableTable.component';


;

@Component({
  selector: `student-historicalbehaviors`,
  template: `
    <div class="d-print-none"
         [ngClass]="{'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.behaviors'))}">
      <nav class="navbar" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}">
        <span class="navbar-brand">Historical Behaviors</span>
      </nav>
      <sortable-table [headers]="headers" [data]="behaviors" defaultSort="date"></sortable-table>
    </div>
  `
})
export class StudentHistoricalBehaviorsComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;
  behaviors: Behavior[];

  headers: Header[];

  constructor(private studentService: StudentService, private route: ActivatedRoute, private router: Router) {
    this.behaviors = [];
    this.headers = [];
    this.headers.push(new Header('School Year', 'school_year', true, DataType.String));
    this.headers.push(new Header('Date', 'date', false, DataType.Date, {
      dateFormat: 'shortDate'
    }));
    this.headers.push(new Header('Code', 'code', true, DataType.String));
    this.headers.push(new Header('Role', 'role', true, DataType.String));
    this.headers.push(new Header('Violation', 'violation', false, DataType.String));
    this.headers.push(new Header('Disposition', 'disposition', false, DataType.String));
  }

  getData() {
    this.studentService.getBehaviors(this.districtId, this.studentId).subscribe(resp => {
      this.behaviors = resp || [];
    },
    (err) => {
      this.behaviors = [];
    });
  }

  ngOnChanges(): void {
    if(this.districtId && this.studentId) {
      this.getData();
    }
  }
}

<div class="modal-header">
  <h4 class="modal-title">Observation</h4>
  <button-close (btnClick)="close()" btnClass="pull-right btn-primary-outline"></button-close>
</div>
<div class="modal-body">
  <form #obsForm="ngForm" (ngSubmit)="submit(obsForm)">
    <table class="table">
      <tbody>
      <tr>
        <td>Date</td>
        <td *ngIf="!edit">{{observation.date | date: 'shortDate'}}</td>
        <td *ngIf="edit"><input [(ngModel)]="observation.date" class="form-control" name="date" required
                                type="date"></td>
      </tr>
      <tr>
        <td>Observers</td>
        <td>
          <staff-list (staffChange)="onStaffChange(obsForm, $event)" [edit]="edit" [form]="obsForm"
                      [staff]="observation.observer_ids"></staff-list>
        </td>
      </tr>
      <tr>
        <td>Other Observers</td>
        <td *ngIf="!edit">{{observation.observer_str}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.observer_str" class="form-control"
                                   name="observer_str"></textarea></td>
      </tr>
      <tr>
        <td>Academic Area</td>
        <td *ngIf="!edit">{{observation.academic_area}}</td>
        <td *ngIf="edit">
          <select [(ngModel)]="observation.academic_area" class="custom-select" name="academic_area">
            <option *ngFor="let a of academic_areas" [value]="a.name">{{a.name}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Instructional Activities</td>
        <td *ngIf="!edit">{{observation.activities}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.activities" class="form-control"
                                   name="activities"></textarea></td>
      </tr>
      <tr>
        <td>Instructional Materials</td>
        <td *ngIf="!edit">{{observation.materials}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.materials" class="form-control"
                                   name="materials"></textarea></td>
      </tr>
      <tr>
        <td>Manner of Presentation</td>
        <td *ngIf="!edit">{{observation.presentation}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.presentation" class="form-control"
                                   name="presentation"></textarea></td>
      </tr>
      <tr>
        <td>Observations</td>
        <td *ngIf="!edit">{{observation.observation}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.observation" class="form-control"
                                   name="observation"></textarea></td>
      </tr>
      <tr>
        <td>Peer Comparison</td>
        <td *ngIf="!edit">{{observation.peer_comparison}}</td>
        <td *ngIf="edit"><textarea [(ngModel)]="observation.peer_comparison" class="form-control"
                                   name="peer_comparison"></textarea></td>
      </tr>
      </tbody>
    </table>
  </form>
</div>
<div class="modal-footer">
  <button-edit (btnClick)="edit = true" *ngIf="!edit" label="Edit"></button-edit>
  <button-save (btnClick)="submit(obsForm)" *ngIf="edit" [disabled]="!obsForm.form.dirty" label="Save"
               type="submit"></button-save>
  <button-close (btnClick)="close()" label="Cancel"></button-close>
</div>

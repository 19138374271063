import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {BehaviorIntervention} from '../../models/student/bhvrintv.model';
import {StudentService} from '../../services/student.service';
import {NgForm} from '@angular/forms';
import {DataType, Header, SortableTableComponent} from '../../components/helpers/sortableTable/sortableTable.component';

import {Level, ToastService} from '../../services/toast.service';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";

@Component({
  selector: `student-bhvrintv`,
  templateUrl: 'student.behaviorinterventions.html'
})
export class StudentBehaviorInterventionsComponent implements OnChanges {
  @ViewChild(SortableTableComponent) public Table: SortableTableComponent;
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;
  headers: Header[];

  bhvrintvs: BehaviorIntervention[];
  newBhvrIntv: BehaviorIntervention;

  NotificationTitle = 'Behavior Interventions';

  constructor(private studentService: StudentService, private router: ActivatedRoute, private toastService: ToastService) {
    this.newBhvrIntv = new BehaviorIntervention();
    this.headers = [];
    this.headers.push(new Header('Date', 'date', false, DataType.Date, {
      dateFormat: 'shortDate'
    }));
    this.headers.push(new Header('Behavior', 'behavior', false, DataType.String));
    this.headers.push(new Header('Intervention', 'intervention', false, DataType.String));
  }

  del(d: BehaviorIntervention) {
    this.studentService.deleteBehaviorIntervention(this.districtId, this.studentId, d.id).subscribe(resp => {
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Removed Behavior Intervention');
      this.getData();
    });
  }

  save(d: BehaviorIntervention) {
    if(!d.id) {
      this.studentService.postBehaviorIntervention(this.districtId,this.studentId, d).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved Behavior Intervention');
        this.getData();
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, 'Error saving Behavior Intervention');
      });
    } else {
      this.studentService.patchBehaviorIntervention(this.districtId, this.studentId, d).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved Behavior Intervention');
        this.getData();
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, 'Error saving Behavior Intervention');
      });
    }
  }

  submit(form: NgForm) {
    this.studentService.postBehaviorIntervention(this.districtId, this.studentId, this.newBhvrIntv).subscribe(resp => {
      this.newBhvrIntv = new BehaviorIntervention();
      form.form.reset();
      this.getData();
    });
  }

  delete(id: string) {
    this.studentService.deleteBehaviorIntervention(this.districtId, this.studentId, id).subscribe(resp => {
      this.getData();
    });
  }

  ngOnChanges(): void {
    if(this.districtId && this.studentId) {
      this.getData();
    }
  }

  getData() {
    this.studentService.getBehaviorIntervention(this.districtId, this.studentId).subscribe(resp => {
      this.bhvrintvs = resp || [];
      if (resp !== null) {
        this.bhvrintvs.sort((a: BehaviorIntervention, b: BehaviorIntervention) => {
          return a.date > b.date ? -1 : 1;
        });
      }
    }, (resp) => {
      this.bhvrintvs = [];
    });
  }
}

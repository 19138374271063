import {Component, Input} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PrintOptions } from "../common/components/buttons/print-options.helper";;

@Component({
  selector: `student-print`,
  template: `
    <ng-template #content let-c="close" let-d="dismiss">
      <div class="modal-header">
        <h4 class="modal-title">Print Options</h4>
        <button class="close" aria-label="Close" (click)="d('cancel')">
          <i class="fa fa-window-close" aria-hidden="true"></i>
        </button>
      </div>
      <div class="modal-body">
        <ngb-tabset>
          <ngb-tab *ngFor="let t of options.tabs" [disabled]="!options.getValue(t.disabledOn)">
            <ng-template ngbTabTitle>{{t.name}}</ng-template>
            <ng-template ngbTabContent>
              <ul class="list-group">
                <li *ngFor="let o of t.options" class="list-group-item">
                  <label class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" [(ngModel)]="o.value">
                    <span class="custom-control-label">{{o.name}}</span>
                  </label>

                </li>
              </ul>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
      <div class="modal-footer">
        <span class="mr-auto"></span>
        <button class="btn btn-secondary" aria-label="Print" (click)="c('print')">
          <i class="fa fa-print" aria-hidden="true"></i>
          Print
        </button>
        <button class="btn btn-secondary" aria-label="Print" (click)="c('print')">
          <i class="fa fa-window-close" aria-hidden="true"></i>
          Cancel
        </button>
      </div>
    </ng-template>
    <button class="btn btn-primary" (click)="open(content)">
      <i class="fa fa-print" aria-hidden="true"></i>
    </button>
  `
})
export class StudentPrintDialogComponent {
  @Input()
  options: PrintOptions;

  open(content) {
    this.modalService.open(content, {backdrop: 'static'}).result.then((result) => {
      if (result === 'print') {
        window.print();
      }
    });
  }

  constructor(private modalService: NgbModal) {
  }
}

import {Directive, ElementRef, OnInit} from "@angular/core";
import {AuthService} from "../../services/authService";
import {LoggingService} from "../../services/logging";

@Directive({
  selector: '[isSIDRAdmin]'
})
export class IsSIDRAdminDirective implements OnInit {

  constructor(private el: ElementRef, private tokenService: AuthService, private log: LoggingService) {
    this.el.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {
    const sidrAdmin = this.tokenService.parseJwt().sidrAdmin;
    this.log.Log(`isSIDRAdmin ${sidrAdmin}`);
    if (sidrAdmin) {
      this.el.nativeElement.style.display = '';
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }
}

/**
 * Created by ptdave on 6/25/17.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {StaffService} from '../../services/staff.service';
import {Observable} from 'rxjs';
import {Staff} from '../../models/staff.model';
import { map } from 'rxjs/operators';
import {StaffDto} from '../dtos/staffDto';

@Pipe({
  name: 'staffId'
})
export class StaffIdPipe implements PipeTransform {
  private output: Observable<string>;

  constructor(private staffService: StaffService) {

  }

  format(s: StaffDto): string {
    if (s.displayName.trim().length > 0) {
      return `${s.displayName} (${s.email})`;
    } else {
      return '(' + s.email + ')';
    }
  }

  transform(value: string, ...args: any[]): Observable<string> | string  {
    if (!value) {
      return null;
    }
    const that = this;
    if (args.length > 0) {
      return this.format(<StaffDto>args[0]);
    } else {
      return this.staffService.get(value).pipe(map((resp) => that.format(resp)));
    }
  }
}

import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {DataType, Header, SortableTableComponent} from "../../components/helpers/sortableTable/sortableTable.component";
import {Level, ToastService} from "../../services/toast.service";
import {StudentService} from "../../services/student.service";
import {DistrictAchievement, DistrictService} from "../../services/district.service";
import {Achievement} from "../../models/student/achievement.model";

@Component({
  selector: 'app-student-achievements',
  templateUrl: './student-achievements.component.html',
  styleUrls: ['./student-achievements.component.scss']
})
export class StudentAchievementsComponent implements OnChanges {
  loading = false;
  @ViewChild(SortableTableComponent) public Table: SortableTableComponent;
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  NotificationTitle = 'Achievements';

  achievements: Achievement[];
  types: DistrictAchievement[];
  newData: Achievement;
  @ViewChild(NgForm) Form: NgForm;

  headers: Header[];

  constructor(
    private studentService: StudentService,
    private districtService: DistrictService,
    private toastService: ToastService) {
    this.achievements = [];
    this.newData = new Achievement();
    this.headers = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.districtId || changes.studentId) {
      this.getData();
    }
  }

  add(a: Achievement) {
    this.studentService.postAchievement(this.districtId, this.studentId, a).subscribe(resp => {
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Successfully added achievements');
      this.getData();
    }, (err) => {
      console.log(err);
    });
  }

  patch(a: Achievement) {
    this.studentService.patchAchievement(this.districtId, this.studentId, a)
      .subscribe(
        resp => {
          this.getData();
          this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Updated achievement');
        },
        (err) => {
          this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, err.error);
        });
  }

  save(a: Achievement) {
    if(!a.id || a.id.length === 0) {
      this.studentService.postAchievement(this.districtId, this.studentId, a).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Successfully added achievements');
        this.getData();
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, "Error adding achievement");
      });
    } else {
      this.studentService.patchAchievement(this.districtId, this.studentId, a).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Updated achievement');
        this.getData();
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, "Error updating achievement");
      });
    }
  }

  remove(a: Achievement) {
    this.studentService.deleteAchievement(this.districtId, this.studentId, a.id).subscribe(resp => {
      this.getData();
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Removed achievement');
    });
  }

  getData(): void {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.loading = true;
    this.studentService.getAchievements(this.districtId, this.studentId).subscribe((resp) => {
      this.achievements = resp ?? [];
      if (this.achievements) {
        this.achievements.sort((a: Achievement, b: Achievement) => {
          return a.date > b.date ? -1 : 1;
        });
      }
      this.loading = false;
    }, () => {
      this.achievements = [];
      this.loading = false;
    });
    this.districtService.getAchievements(this.districtId).subscribe(resp => {
      const v = [];
      const data = resp || [];
      data.forEach(value => v.push(value.name));
      if (this.headers.length === 0) {
        this.headers.push(new Header('Date', 'date', false, DataType.Date, {
          dateFormat: 'shortDate',
        }));
        this.headers.push(new Header('Type', 'type', true, DataType.Select, {
          dropdownValues: v
        }));
        this.headers.push(new Header('Evidence', 'evidence', false, DataType.String));
      }

    });
  }
}

<div class="navbar navbar-dark bg-primary">
  <span class="navbar-brand">Your Students</span>
  <span class="mr-auto"></span>
  <sidr-radio-buttons [model]="display" (modelChange)="onDisplayChange($event)" name="display">
    <sidr-radio-button icon="fa fa-th" value="grid" class="btn-light"></sidr-radio-button>
    <sidr-radio-button icon="fa fa-list" value="list" class="btn-light"></sidr-radio-button>
  </sidr-radio-buttons>
</div>

<ng-container *ngFor="let building of students | keyvalue">
  <div class="navbar navbar-expand-lg navbar-light bg-light">
    <span class="navbar-brand">{{(building.key | buildingName | async)}}</span>
  </div>


    <ng-container *ngIf="display === 'grid'">
      <div class="container-fluid row">
        <div class="d-flex flex-wrap" style="min-width: 100%">
          <ng-container *ngFor="let student of building.value">
            <div class="col-xl-2 col-lg-2 col-sm-3 col-md-2 mb-1" [@slideInOut]>
              <div class="card">
                <ng-container *ngIf="student.photoUrl != null && student.photoUrl.length > 0 ">
                  <div class="card-img-container">
                    <a [routerLink]="['/student',student.districtId, student.id]">
                      <img [src]="student.photoUrl" class="card-img-top">
                    </a>
                  </div>

                </ng-container>
                <ng-container *ngIf="student.photoUrl == null || student.photoUrl.length == 0">
                  <a [routerLink]="['/student',student.districtId, student.id]">
                    <img src="assets/student_nophoto.png" class="card-img-top" style="opacity: 0.5">
                  </a>
                </ng-container>
                <div class="card-body text-center">
                  <a [routerLink]="['/student',student.districtId, student.id]" class="stretched-link">{{student.lastName}}, {{student.firstName}}</a>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="display === 'list'">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let student of building.value">
            <tr>
              <th scope="row">{{student.schoolId}}</th>
              <td>{{student.firstName}}</td>
              <td>{{student.lastName}}</td>
              <td><a [routerLink]="['/student',student.districtId, student.id]">View</a></td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </ng-container>
</ng-container>

<div class="col-12" *ngIf="isEmpty(students)">
  <h5>You don't appear to have any students in your classroom</h5>
  <p>But don't worry, you may have access to other students via the search in the top right of the screen</p>
  <p *ngIf="contact != null && contact.name.length > 0">Contact your district SIDR contact, {{contact.name}} via <a href="mailto:{{contact.email}}">email</a> if you believe this is in error.</p>
</div>

import {Component, Input, OnChanges} from '@angular/core';
import {PrintOptions} from '../../common/components/buttons/print-options.helper';
import {IRIP} from '../../models/student/irip.model';
import {Level, ToastService} from '../../services/toast.service';
import {StudentService} from '../../services/student.service';
import {IRIPDto} from '../../common/dtos/IRIPDto';

@Component({
  selector: 'app-student-irip-list',
  templateUrl: './student-irip-list.component.html',
  styleUrls: ['./student-irip-list.component.scss']
})
export class StudentIripListComponent implements OnChanges {
  irips: IRIPDto[];
  @Input() districtId: string;
  @Input() studentId: string;
  @Input() printOptions: PrintOptions;
  notificationTitle = 'IRIP';
  selectedIRIP: IRIPDto = null;

  isDirty: boolean;
  isTouched: boolean;

  constructor(private studentService: StudentService, private toastService: ToastService) {
  }

  ngOnChanges() {
    if (this.districtId && this.studentId) {
      this.getData();
    }
  }

  getData() {
    this.studentService.getIRIPs(this.districtId, this.studentId).subscribe(r => {
      this.irips = r || [];
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.notificationTitle, err.error);
    });
  }

  open(index: number, irip: IRIP) {
    if (irip === null) {
      this.selectedIRIP = new IRIPDto();
    } else {
      this.selectedIRIP = {...irip};
    }
  }

  print(id: string) {
    this.studentService.getIRIPPrint(this.districtId, this.studentId, id).subscribe(r => {
      let url = window.URL.createObjectURL(r)
      let pwa = window.open(url);
    })
  }

  close(canceled: boolean) {
    console.log(canceled);
    if (!canceled) {
      this.getData();
    }

    this.selectedIRIP = null;
  }

  delete(id: string) {
    this.studentService.deleteIRIP(this.districtId, this.studentId, id).subscribe(r => {
      this.getData();
    });
  }

  save() {
    this.selectedIRIP.id ? this.studentService.postIRIP(this.districtId, this.studentId, this.selectedIRIP).subscribe(data => {
      this.close(false);
    }) : this.studentService.patchIRIP(this.districtId, this.studentId, this.selectedIRIP).subscribe(data => {
      this.close(false);
    });
  }
}

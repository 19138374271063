import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpEvent } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

interface FileUpload {
  file: File;
  progress: number;
  isUploading: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class DistrictImportService {
  private overallProgressSubject = new Subject<number>();
  private files: FileUpload[] = [];
  private maxFiles = 1;
  filesAdded: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  getOverallProgress(): Observable<number> {
    return this.overallProgressSubject.asObservable();
  }

  uploadFiles(districtId: string, files: File[]): void {
    files.forEach(file => {
      this.files.push({ file, progress: 0, isUploading: false });
    });
    this.filesAdded.next(true);
    this.uploadNextFiles(districtId);
  }

  private uploadNextFiles(districtId: string): void {
    const activeFiles = this.files.filter(f => !f.isUploading && f.progress < 100).slice(0, this.maxFiles);
    if (activeFiles.length === 0) {
      return;
    }

    const formData = new FormData();
    activeFiles.forEach(fileUpload => {
      fileUpload.isUploading = true;
      formData.append('upload', fileUpload.file, fileUpload.file.name);
    });

    this.http.post(`/api/district/${districtId}/imports`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((event: HttpEvent<any>) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            if (event.total) {
              const progress = Math.round(100 * (event.loaded / event.total));
              activeFiles.forEach(fileUpload => {
                fileUpload.progress = progress;
              });
              this.updateOverallProgress();
            }
            break;
          case HttpEventType.Response:
            activeFiles.forEach(fileUpload => {
              fileUpload.isUploading = false;
              fileUpload.progress = 100; // Mark file as fully uploaded
            });
            this.updateOverallProgress();
            this.uploadNextFiles(districtId);
            break;
        }
      })
    ).subscribe();
  }

  get uploads(): FileUpload[] {
    return this.files;
  }

  clear() {
    this.files = [];
    this.overallProgressSubject.next(0);
  }

  private updateOverallProgress(): void {
    const totalFiles = this.files.length;
    const uploadedFiles = this.files.filter(file => file.progress === 100).length;
    const overallProgress = totalFiles ? (uploadedFiles / totalFiles) * 100 : 0;
    this.overallProgressSubject.next(overallProgress);
  }
}

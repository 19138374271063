<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" orientation="vertical" [destroyOnHide]="false">
    <ng-container *ngFor="let report of reports">
      <li [ngbNavItem]="report.name">
        <a ngbNavLink>{{report.name}}</a>
        <ng-template ngbNavContent>
          <p>{{report.description}}</p>
          <button class="btn btn-success pull-right" type="button" (click)="getReport(report)">Generate</button>
        </ng-template>
      </li>
    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-4 mt-2"></div>
</div>

import {NgModule} from '@angular/core';
import {ArrayFilterPipe} from './pipes/arrayFilter.pipe';
import {BlankDatePipePipe} from './pipes/blankDate.pipe';
import {DistinctPipe} from './pipes/distinct.pipe';
import {InterventionPipe} from './pipes/intervention.pipe';
import {ProblemDescPipePipe} from './pipes/problemDesc.pipe';
import {ProblemsSiftPipe} from './pipes/problemsSift.pipe';
import {StaffIdPipe} from './pipes/staffid.pipe';
import {StrconSiftPipe} from './pipes/strconSift.pipe';
import {RequireRolesDirective} from './directives/requireRole';
import {AddbuttonComponent} from './components/buttons/addbutton.component';
import {ClosebuttonComponent} from './components/buttons/closebutton.component';
import {DeletebuttonComponent} from './components/buttons/deletebutton.component';
import {EditbuttonComponent} from './components/buttons/editbutton.component';
import {NewbuttonComponent} from './components/buttons/newbutton.component';
import {MorebuttonComponent} from './components/buttons/morebutton.component';
import {SavebuttonComponent} from './components/buttons/savebutton.component';
import {IsSIDRAdminDirective} from './directives/isSIDRAdmin';
import {GoogleAuthComponent} from './components/googleAuth/googleAuth.component';
import {IsSignedInDirective} from './directives/isSignedIn';
import {BuildingNamePipe} from './pipes/buildingName.pipe';
import {NotificationComponent} from './components/notification/notification.component';

@NgModule({
  imports: [],
  exports: [
    ArrayFilterPipe,
    BlankDatePipePipe,
    BuildingNamePipe,
    DistinctPipe,
    InterventionPipe,
    ProblemDescPipePipe,
    ProblemsSiftPipe,
    RequireRolesDirective,
    IsSIDRAdminDirective,
    IsSignedInDirective,
    StaffIdPipe,
    StrconSiftPipe,

    AddbuttonComponent,
    ClosebuttonComponent,
    DeletebuttonComponent,
    EditbuttonComponent,
    NewbuttonComponent,
    MorebuttonComponent,
    SavebuttonComponent,
    GoogleAuthComponent,
    NotificationComponent
  ],
  declarations: [
    ArrayFilterPipe,
    BlankDatePipePipe,
    BuildingNamePipe,
    DistinctPipe,
    InterventionPipe,
    ProblemDescPipePipe,
    ProblemsSiftPipe,
    RequireRolesDirective,
    IsSIDRAdminDirective,
    IsSignedInDirective,
    StaffIdPipe,
    StrconSiftPipe,

    AddbuttonComponent,
    ClosebuttonComponent,
    DeletebuttonComponent,
    EditbuttonComponent,
    NewbuttonComponent,
    MorebuttonComponent,
    SavebuttonComponent,
    GoogleAuthComponent,
    NotificationComponent
  ],
  providers: [

  ]
})
export class SIDRCommonModule { }

/**
 * Created by ptdave on 6/3/17.
 */



import {DistrictContactDto, DistrictDto} from "../common/dtos/districtDto";
import {DistrictBuildingDto} from "../models/district/districtBuildingDto";
import {StaffDto} from "../common/dtos/staffDto";
import {DistrictTypeDto} from "../common/dtos/districtTypeDto";
import {RoleDto} from "../common/dtos/roleDto";
import {StudentDto} from "../common/dtos/studentDto";
import {LogDto} from "../common/dtos/logDto";
import {DistrictMeetingProblemDto} from "../common/dtos/districtMeetingProblemDto";
import { Injectable } from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, throwError as observableThrowError} from "rxjs";
import {District} from "../models/district/district.model";
import {InterventionType} from "../models/district/interventions.model";
import {InterventionTier} from "../models/district/interventiontiers.model";
import {MeetingTopic} from "../models/district/meeting_topic.model";
import {MeetingNextStep} from "../models/district/meeting_next_steps.model";
import {MeetingsStrCon} from "../models/student/strcon.model";
import {Feature} from "../models/district/feature.model";
import {FormGroup, NgForm} from "@angular/forms";
import {DistrictImportDto} from '../common/dtos/districtImportDto';
import {map} from 'rxjs/operators';

export class DistrictType {
  id: string;
  districtId: string;
  name: string;
}

export class DistrictAcademicArea extends DistrictType {
}

export class DistrictAchievement extends DistrictType {
}

export class DistrictAssessment extends DistrictType {
}

export class DistrictAssessmentMeasure extends DistrictType {
}

export class DistrictContactMethod extends DistrictType {
}

export class DistrictGradeLevel extends DistrictType {
}

export class DistrictOtherFactor extends DistrictType {
}

export class DistrictPeriod extends DistrictType {
}

export class DistrictSchoolYear extends DistrictType {
  startDate: Date | null;
  endDate: Date | null;
}

@Injectable()
export class DistrictService {
  private basePath = `/api/district`;

  protected handleError(error: Response) {
    console.error(error);
    return observableThrowError(error || `Server error`);
  }

  constructor(private http: HttpClient) {
  }


  public getDistricts(): Observable<DistrictDto[]> {
    return this.http.get<DistrictDto[]>(this.basePath);
  }

  public get(districtId: string): Observable<DistrictDto> {
    return this.http.get<DistrictDto>(`${this.basePath}/${districtId}`);
  }

  public getDistrictContact(districtId: string): Observable<DistrictContactDto> {
    return this.http.get<DistrictContactDto>(`${this.basePath}/${districtId}/contact`);
  }

  public update(district: DistrictDto): Observable<DistrictDto> {
    return this.http.post<DistrictDto>(`${this.basePath}/${district.id}`, district);
  }

  public updateAsForm(districtId: string, district: FormData): Observable<DistrictDto> {
    return this.http.post<DistrictDto>(`${this.basePath}/${districtId}`, district);
  }

  public getContact(districtId: string): Observable<DistrictContactDto> {
    return this.http.get<any>(`${this.basePath}/${districtId}/contact`);
  }

  public updateContact(districtId: string, data: DistrictContactDto): Observable<any> {
    return this.http.post<any>(`${this.basePath}/${districtId}/contact`, data);
  }

  public getDomains(districtId: string): Observable<string[]> {
    return this.http.get<string[]>(`${this.basePath}/${districtId}/domains`);
  }

  public updateDomains(districtId: string, domains: string[]): Observable<string[]> {
    return this.http.post<string[]>(`${this.basePath}/${districtId}/domains`, domains);
  }

  public getLogs(districtId: string, contains: string, pageNum: number, pageSize: number): Observable<LogDto[]> {
    let query = [];
    if ((contains || "").length > 0) {
      query.push(`contains=${contains}`);
    }
    query.push(`pageNum=${pageNum}`);
    query.push(`pageSize=${pageSize}`);
    return this.http.get<LogDto[]>(`${this.basePath}/${districtId}/logs?${query.join(`&`)}`);
  }

  public scanFiles(districtId: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/${districtId}/scan_files`);
  }

  public patch(district: District): Observable<District> {
    return this.http.patch<District>(`${this.basePath}/${district.id}`, district);
  }

  public getBuildings(districtId: string): Observable<DistrictBuildingDto[]> {
    return this.http.get<DistrictBuildingDto[]>(`${this.basePath}/${districtId}/buildings`);
  }

  public patchBuilding(districtId: string, building: DistrictBuildingDto): Observable<DistrictBuildingDto> {
    return this.http.patch<DistrictBuildingDto>(`${this.basePath}/${districtId}/building/${building.id}`, building);
  }

  public postBuilding(districtId: string, building: DistrictBuildingDto): Observable<DistrictBuildingDto> {
    return this.http.post<DistrictBuildingDto>(`${this.basePath}/${districtId}/building`, building);
  }

  public deleteBuilding(districtId: string, building_id: string): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/building/${building_id}`);
  }

  public getAcademicAreas(districtId: string): Observable<DistrictAcademicArea[]> {
    return this.http.get<DistrictAcademicArea[]>(`${this.basePath}/${districtId}/types/academic_areas`);
  }

  public postAcademicArea(districtId: string, area: DistrictAcademicArea) {
    return this.http.post<DistrictAcademicArea>(`${this.basePath}/${districtId}/types/academic_areas`, area);
  }

  public deleteAcademicArea(districtId: string, area: DistrictAcademicArea) {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/academic_areas/${area.id}`);
  }

  public getAchievements(districtId: string): Observable<DistrictAchievement[]> {
    return this.http.get<DistrictAchievement[]>(`${this.basePath}/${districtId}/types/basic/achievements`);
  }

  public getAssessment(districtId: string): Observable<DistrictAssessment[]> {
    return this.http.get<DistrictAssessment[]>(`${this.basePath}/${districtId}/types/assessments`);
  }

  public getAssessmentMeasures(districtId: string): Observable<DistrictAssessmentMeasure[]> {
    return this.http.get<DistrictAssessmentMeasure[]>(`${this.basePath}/${districtId}/types/assessment_measures`);
  }

  public getContactMethods(districtId: string): Observable<DistrictContactMethod[]> {
    return this.http.get<DistrictContactMethod[]>(`${this.basePath}/${districtId}/types/contact_methods`);
  }

  public getGradeLevels(districtId: string): Observable<DistrictGradeLevel[]> {
    return this.http.get<DistrictGradeLevel[]>(`${this.basePath}/${districtId}/types/basic/grades`);
  }

  public getOtherFactors(districtId: string): Observable<DistrictOtherFactor[]> {
    return this.http.get<DistrictOtherFactor[]>(`${this.basePath}/${districtId}/types/basic/otherfactor`);
  }

  public postOtherFactor(districtId: string, other_factor: DistrictOtherFactor) {
    return this.http.post<DistrictOtherFactor>(`${this.basePath}/${districtId}/types/basic/otherfactor`, other_factor);
  }

  public deleteOtherFactor(districtId: string, other_factor: DistrictOtherFactor) {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/other_factors/${other_factor.id}`);
  }

  public getPeriods(districtId: string): Observable<DistrictPeriod[]> {
    return this.http.get<DistrictPeriod[]>(`${this.basePath}/${districtId}/types/periods`);
  }

  public getSchoolYears(districtId: string): Observable<DistrictSchoolYear[]> {
    return this.http.get<DistrictSchoolYear[]>(`${this.basePath}/${districtId}/types/school_years`);
  }

  public deleteSchoolYear(districtId: string, schoolYearId: string): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/school_year/${schoolYearId}`);
  }

  public postSchoolYear(districtId: string, schoolYear: DistrictSchoolYear): Observable<DistrictSchoolYear> {
    return this.http.post<DistrictSchoolYear>(`${this.basePath}/${districtId}/types/school_years`, schoolYear);
  }

  public patchSchoolYear(districtId: string, schoolYear: DistrictSchoolYear): Observable<DistrictSchoolYear> {
    return this.http.patch<DistrictSchoolYear>(`${this.basePath}/${districtId}/types/school_year/${schoolYear.id}`, schoolYear);
  }

  public getStaff(districtId: string): Observable<StaffDto[]> {
    return this.http.get<StaffDto[]>(`${this.basePath}/${districtId}/staff`);
  }

  public getActiveStaff(districtId: string): Observable<StaffDto[]> {
    return this.http.get<StaffDto[]>(`${this.basePath}/${districtId}/active_staff`);
  }

  public getBuildingStaff(districtId: string, building_id: string): Observable<StaffDto[]> {
    return this.http.get<StaffDto[]>(`${this.basePath}/${districtId}/building/${building_id}/staff`);
  }

  public getRoles(districtId: string): Observable<RoleDto[]> {
    return this.http.get<RoleDto[]>(`${this.basePath}/${districtId}/roles`);
  }

  public getBasicType(districtId: string, type: string): Observable<DistrictTypeDto[]> {
    return this.http.get<DistrictTypeDto[]>(`${this.basePath}/${districtId}/types/basic/${type}`);
  }

  public postBasicType(districtId: string, type: string, data: DistrictTypeDto): Observable<DistrictTypeDto> {
    return this.http.post<DistrictTypeDto>(`${this.basePath}/${districtId}/types/basic/${type}`, data);
  }

  public patchBasicType(districtId: string, type: string, id: string, data: DistrictTypeDto): Observable<DistrictTypeDto> {
    return this.http.patch<DistrictTypeDto>(`${this.basePath}/${districtId}/types/basic/${type}/${id}`, data);
  }

  public deleteBasicType(districtId: string, type: string, id: string): Observable<DistrictTypeDto[]> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/basic/${type}/${id}`);
  }


  public getInterventionTypes(districtId: string): Observable<InterventionType[]> {
    return this.http.get<InterventionType[]>(`${this.basePath}/${districtId}/types/basic/intervention_types`);
  }

  public postInterventionType(districtId: string, intervention_type: InterventionType) {
    return this.http.post<InterventionType>(`${this.basePath}/${districtId}/types/intervention_types`, intervention_type);
  }

  public patchInterventionType(districtId: string, intervention_type: InterventionType) {
    return this.http.patch<InterventionType>(`${this.basePath}/${districtId}/types/intervention_types/${intervention_type.id}`, intervention_type);
  }

  public deleteInterventionType(districtId: string, intervention_type: InterventionType) {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/intervention_types/` + intervention_type.id);
  }

  public getInterventionTiers(districtId: string): Observable<InterventionTier[]> {
    return this.http.get<InterventionTier[]>(`${this.basePath}/${districtId}/types/intervention_tiers`);
  }

  public getDisabledStaff(districtId: string): Observable<StaffDto[]> {
    return this.http.get<StaffDto[]>(`${this.basePath}/${districtId}/disabled_staff`);
  }

  public getMeetingTopics(districtId: string): Observable<MeetingTopic[]> {
    return this.http.get<MeetingTopic[]>(`${this.basePath}/${districtId}/types/meeting_topics`);
  }

  public postMeetingTopic(districtId: string, topic: MeetingTopic): Observable<MeetingTopic> {
    return this.http.post<MeetingTopic>(`${this.basePath}/${districtId}/types/meeting_topics`, topic);
  }

  public deleteMeetingTopic(districtId: string, topic: MeetingTopic): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/meeting_topics/` + topic.id);
  }

  public getMeetingProblems(districtId: string): Observable<DistrictMeetingProblemDto[]> {
    return this.http.get<DistrictMeetingProblemDto[]>(`${this.basePath}/${districtId}/types/meeting_problems`);
  }

  public postMeetingProblems(districtId: string, problem: DistrictMeetingProblemDto): Observable<DistrictMeetingProblemDto> {
    return this.http.post<DistrictMeetingProblemDto>(`${this.basePath}/${districtId}/types/meeting_problems`, problem);
  }

  public deleteMeetingProblem(districtId: string, problem_id: string): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/meeting_problem/` + problem_id);
  }

  public patchMeetingProblems(districtId: string, problem: DistrictMeetingProblemDto): Observable<DistrictMeetingProblemDto> {
    return this.http.patch<DistrictMeetingProblemDto>(`${this.basePath}/${districtId}/types/meeting_problem/` + problem.id, problem);
  }

  public getMeetingNextSteps(districtId: string): Observable<MeetingNextStep[]> {
    return this.http.get<MeetingNextStep[]>(`${this.basePath}/${districtId}/types/meeting_nextsteps`);
  }

  public getMeetingStrCons(districtId: string): Observable<MeetingsStrCon[]> {
    return this.http.get<MeetingsStrCon[]>(`${this.basePath}/${districtId}/types/meeting_strcons`);
  }

  public deleteMeetingStrCon(districtId: string, strcon_id: string): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/meeting_strcons/` + strcon_id);
  }

  public postMeetingStrCon(districtId: string, strcon: MeetingsStrCon): Observable<MeetingsStrCon> {
    return this.http.post<MeetingsStrCon>(`${this.basePath}/${districtId}/types/meeting_strcons`, strcon);
  }

  public getStudentBySchoolId(districtId: string, school_id: string): Observable<StudentDto> {
    return this.http.get<StudentDto>(`${this.basePath}/${districtId}/find_student/` + school_id);
  }

  public getIRIPInterventions(districtId: string): Observable<DistrictType[]> {
    return this.http.get<DistrictType[]>(`${this.basePath}/${districtId}/types/irip_intervention`);
  }

  public postIRIPInterventions(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.post<DistrictType>(`${this.basePath}/${districtId}/types/irip_intervention`, data);
  }

  public patchIRIPInterventions(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.patch<DistrictType>(`${this.basePath}/${districtId}/types/irip_intervention/` + data.id, data);
  }

  public deleteIRIPInterventions(districtId: string, data: DistrictType): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/irip_intervention/` + data.id);
  }

  public getIRIPImplementer(districtId: string): Observable<DistrictType[]> {
    return this.http.get<DistrictType[]>(`${this.basePath}/${districtId}/types/irip_implementer`);
  }

  public postIRIPImplementer(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.post<DistrictType>(`${this.basePath}/${districtId}/types/irip_implementer`, data);
  }

  public patchIRIPImplementer(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.patch<DistrictType>(`${this.basePath}/${districtId}/types/irip_implementer/` + data.id, data);
  }

  public deleteIRIPImplementer(districtId: string, data: DistrictType): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/irip_implementer/` + data.id);
  }

  public getIRIPHowOften(districtId: string): Observable<DistrictType[]> {
    return this.http.get<DistrictType[]>(`${this.basePath}/${districtId}/types/irip_how_often`);
  }

  public postIRIPHowOften(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.post<DistrictType>(`${this.basePath}/${districtId}/types/irip_how_often`, data);
  }

  public patchIRIPHowOften(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.patch<DistrictType>(`${this.basePath}/${districtId}/types/irip_how_often/` + data.id, data);
  }

  public deleteIRIPHowOften(districtId: string, data: DistrictType): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/irip_how_often/` + data.id);
  }

  public getIRIPAreasOfGrowth(districtId: string): Observable<DistrictType[]> {
    return this.http.get<DistrictType[]>(`${this.basePath}/${districtId}/types/irip_areas_of_growth`);
  }

  public postIRIPAreasOfGrowth(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.post<DistrictType>(`${this.basePath}/${districtId}/types/irip_areas_of_growth`, data);
  }

  public patchIRIPAreasOfGrowth(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.patch<DistrictType>(`${this.basePath}/${districtId}/types/irip_areas_of_growth/` + data.id, data);
  }

  public deleteIRIPAreasOfGrowth(districtId: string, data: DistrictType): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/irip_areas_of_growth/` + data.id);
  }

  public getIRIPResults(districtId: string): Observable<DistrictType[]> {
    return this.http.get<DistrictType[]>(`${this.basePath}/${districtId}/types/irip_result`);
  }

  public postIRIPResults(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.post<DistrictType>(`${this.basePath}/${districtId}/types/irip_result`, data);
  }

  public patchIRIPResults(districtId: string, data: DistrictType): Observable<DistrictType> {
    return this.http.patch<DistrictType>(`${this.basePath}/${districtId}/types/irip_result/` + data.id, data);
  }

  public deleteIRIPResults(districtId: string, data: DistrictType): Observable<Response> {
    return this.http.delete<any>(`${this.basePath}/${districtId}/types/irip_result/` + data.id);
  }

  public getFeatures(districtId: string): Observable<Feature[]> {
    return this.http.get<any[]>(`${this.basePath}/${districtId}/features`);
  }

  public getFeature(districtId: string, code: string): Observable<Feature> {
    return this.http.get<Feature>(`${this.basePath}/${districtId}/features/` + code);
  }

  public postImport(districtId: string, files: File[]): Observable<any> {
    const formData = new FormData();
    files.forEach(file => formData.append('upload', file, file.name));
    return this.http.post(`/api/district/${districtId}/imports`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public getImports(districtId: string, page: number = 0, pageSize: number = 20): Observable<DistrictImportDto[]> {
    return this.http.get<DistrictImportDto[]>(`${this.basePath}/${districtId}/imports?page=${page}&pageSize=${pageSize}`);
  }

  public getImportCount(districtId: string): Observable<number> {
    return this.http.get<any>(`${this.basePath}/${districtId}/importsCount`)
      .pipe(map(resp => resp.total));
  }

  public getImportTypes(districtId: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/${districtId}/import/types`);
  }
}

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {NgForm} from '@angular/forms';
import {DistrictContactDto} from "../../common/dtos/districtDto";
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {IDirty} from "../../services/canDeactivate.service";
import {Level, ToastService} from "../../services/toast.service";

@Component({
  selector: `district-contact`,
  templateUrl: 'contact.component.html'
})
export class DistrictContactComponent implements OnInit, IDirty {
  @Output() ready = new EventEmitter();
  @ViewChild('contactForm') contactForm: NgForm;

  districtId: string;
  contact: DistrictContactDto;

  save(f: NgForm) {
    f.form.disable();
    console.log(f.form.value);
    this.districtService.updateContact(this.districtId, this.contact)
      .subscribe(r => {
        f.form.markAsPristine();
        this.toastService.AddNotification(Level.SUCCESS, "Success", "Contact information saved successfully.")
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "Error", "Error saving contact information. Please try again.")
      }, () => {
        f.form.enable();
      });
  }
  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      this.districtId = p['districtId'];
      this.districtService.getContact(this.districtId).subscribe(r => {
        this.contact = r;
        this.ready.emit();
      }, (err) => {
        this.toastService.AddNotification(Level.ERROR, "Error", "Error loading contact information. Please try again.")
      });
      this.titleService.setTitle("District Contact Settings")
    });
  }

  constructor(
    private districtService: DistrictService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService) {
    this.contact = null;
  }

  isDirty(): boolean {
    return this.contactForm.dirty;
  }
}

<form #problemForm="ngForm">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="input-group">
            <input class="form-control" name="problem_name" [(ngModel)]="problemDelta.name" required id="problem_name" placeholder="Name of the meeting problem" ngbTooltip="Name of the meeting problem">
            <div class="input-group-append">
              <button class="btn btn-success" (click)="addSubProblem()"><span class="fa fa-plus"></span></button>
            </div>
          </div>

        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <ng-container *ngFor="let sub of problemDelta.sub_problems; let subI = index">
              <li class="list-group-item d-flex flex-row">
                <div class="input-group">
                  <input class="form-control" [name]="problemDelta.name+'_'+subI" [(ngModel)]="sub.name" required minlength="3">
                  <div class="input-group-append">
                    <select class="custom-select" [(ngModel)]="sub.type"
                            [name]="problemDelta.name+'_'+sub.name + '_type'" ngbTooltip="What kind of data is stored here?" required>
                      <ng-container *ngFor="let type of subProblemTypes">
                        <option [value]="type.value">{{type.name}}</option>
                      </ng-container>
                    </select>
                    <button class="btn btn-danger" type="button" (click)="deleteSubProblem(subI)" ngbTooltip="Delete the sub problem"><i class="fa fa-trash" aria-hidden="true"></i></button>
                  </div>
                </div>
              </li>
            </ng-container>
            <ng container *ngIf="problemDelta.sub_problems.length == 0">
              <div class="d-flex justify-content-center">
                <span class="text-muted">No sub problems</span>
              </div>
            </ng>
          </ul>
        </div>
        <div class="card-footer">
          <div class="d-flex">
            <span class="mr-auto"></span>
            <button class="btn btn-success mr-1" type="submit" (click)="saveMeetingProblem()" [disabled]="!problemForm.dirty || problemForm.untouched" ngbTooltip="Save the meeting problem"><i class="fa fa-floppy-o" aria-hidden="true"></i>Save</button>
            <button class="btn btn-warning" type="reset" (click)="resetMeetingProblem()" [disabled]="!problemForm.dirty || problemForm.untouched" ngbTooltip="Reset the meeting problem"><i class="fa fa-undo" aria-hidden="true"></i>Reset</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<h3>District General Information</h3>
<ng-container *ngIf="district">
  <form #generalForm="ngForm" novalidate (ngSubmit)="save(generalForm)" enctype="multipart/form-data" >
    <input type="hidden" name="id" [(ngModel)]="district.id">
    <div class="form-group">
      <label for="districtName">Distict Name</label>
      <input class="form-control" type="text" id="districtName" minlength="3" name="name" [(ngModel)]="district.name" aria-describedby="districtNameHelp" placeholder="Name of your school district" required #districtName="ngModel">
      <div *ngIf="districtName.invalid && (districtName.dirty || districtName.touched)" class="text-danger">
        District name is required
      </div>
    </div>
    <div class="form-group">
      <label for="districtName">District Slogan</label>
      <input class="form-control" type="text" id="districtSlogan" minlength="3" name="slogan" [(ngModel)]="district.slogan" aria-describedby="districtSloganHelp" placeholder="Slogan" #districtSlogan="ngModel">
    </div>
    <div class="form-group">
      <label for="districtImage">District Image</label>
      <input class="form-control form-control-file" type="file" id="districtImage" (change)="onFileSelected($event, generalForm)" name="newDistrictImage">
    </div>
    <div class="form-group row">
      <button label="Save"
              [disabled]="!generalForm.form.dirty"
              (click)="save(generalForm)"
              class="btn btn-success pull-right ml-auto"
              type="button">Save
      </button>
    </div>
  </form>
</ng-container>

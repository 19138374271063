<p class="text-center">Select a import on the left to see further information. Place your mouse over some columns to
  receive additional
  information.</p>

<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" [keyboard]="true">
    <ng-container *ngFor="let import of imports">
      <li [ngbNavItem]="import.filename">
        <a ngbNavLink>{{import.name}}</a>
        <ng-template ngbNavContent>
          <h3>{{import.name}}</h3>

          <div class="row">
            <span class="col-md-2"><b>File Name:</b></span>
            <span class="col-md-10 mb-2">{{import.filename}}</span>
            <span class="col-md-2"><b>Description:</b></span>
            <span class="col-md-10 mb-2">{{import.description}}</span>
          </div>

          <table class="table table-sm">
            <thead>
            <tr>
              <td>Column Name</td>
              <td>Accepted Header Values</td>
              <td></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let column of import.columns">
              <td>
                <span [ngbTooltip]="column.description"><b>{{ column.name }}</b></span>
                <span *ngIf="column.required">*</span>
              </td>
              <td>
                {{ column.headerValues.join(", ") }}
              </td>
              <td>
                <i class="fas fa-info" *ngIf="column.notes.length > 0" [ngbTooltip]="column.notes"></i>
              </td>
            </tr>
            </tbody>
          </table>

        </ng-template>
      </li>
    </ng-container>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-4 mt-2"></div>
</div>
<p class="text-right">* indicates required</p>

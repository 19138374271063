import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {ReportService} from "../../services/report.service";

export class Report {
  name: string;
  description: string;
  path: string;
}

@Component({
  selector:`district-reports`,
  templateUrl:'reports.component.html'
})
export class DistrictReportsComponent implements OnInit {
  districtId: string;
  reports: Report[];
  active: string;

  constructor(private activeRoute: ActivatedRoute, private titleService: TitleService, private reportService: ReportService) {
    this.reports = [];
    this.reports.push({
      name: "IRIP",
      path: 'irip',
      description: "Gathers every IRIP created for your district and returns it as a CSV that can be used how you wish. Not all information is available in the report due to representation in a spreadsheet."
    });
    this.titleService.setTitle("District Reports");
  }

  ngOnInit() {
    this.activeRoute.parent.params.subscribe(p => {
      this.districtId = p['districtId'];
    });
  }

  getReport(report: Report) {
    this.reportService.getReport(this.districtId, report.path).subscribe(x => {
      const blob = new Blob([x], {type: 'text/csv'});
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = report.name + '.csv';
      anchor.href = url;
      anchor.click();
      //window.open(url);
    })
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {TokenDto} from "../common/dtos/tokenDto";
import {LoggingService} from "./logging";
import {switchMap} from "rxjs/operators";
import { Router } from '@angular/router';


export interface TokenPayload {
  jti: string
  exp: number
  iat: number
  iss: string
  sub: string,
  districtId: string
  districtName: string
  firstname: string
  lastname: string
  title: string
  googleId: string
  roleIds: string[]
  features: string[]
  buildingIds: string[]
  sidrAdmin: boolean,
  roles: string[]
}

const JWT_KEY = 'jwt';
const REFRESH_KEY = 'refresh';

@Injectable()
export class AuthService {
  public isLoggedIn: BehaviorSubject<boolean>;
  private tokenDto: Subject<TokenDto>;

  isAuthenticated(): boolean {
    const value = !this.tokenIsExpired() && !this.refreshIsExpired();
    this.isLoggedIn.next(!this.tokenIsExpired() && !this.refreshIsExpired());
    return value;
  }

  setToken(token: string) {
    window.localStorage.setItem(JWT_KEY,token);
  }

  setRefresh(token: string) {
    window.localStorage.setItem(REFRESH_KEY, token);
  }

  getToken() {
    const token = window.localStorage.getItem(JWT_KEY);
    return token && !this.tokenIsExpired() ? token : "";
  }

  getRefresh() {
    return window.localStorage.getItem(REFRESH_KEY) || "";
  }

  exchangeToken(code: string):Observable<TokenDto> {
    return this.http.get<TokenDto>(`/api/user/token?code=${code}`)
      .pipe(
        switchMap(dto => {
          this.setRefresh(dto.refresh);
          this.setToken(dto.token);
          this.isLoggedIn.next(true);
          return of(dto);
        })
      );
  }

  tokenIsExpired(): boolean {
    if((localStorage.getItem(JWT_KEY) || "").length == 0) {
      localStorage.removeItem(JWT_KEY);
      return true;
    }
    const payload = this.parseJwt();
    return payload.exp < Date.now() / 1000;
  }

  refreshIsExpired(): boolean {
    if ((localStorage.getItem(REFRESH_KEY) || "").length == 0) {
      localStorage.removeItem(REFRESH_KEY);
      return true;
    }
    const payload = this.parseRefresh();
    return payload.exp < Date.now() / 1000;
  }

  clear() {
    localStorage.removeItem(JWT_KEY);
    localStorage.removeItem(REFRESH_KEY);
    this.isLoggedIn.next(false);
  }

  parseJwt(): TokenPayload | null {
    const token = localStorage.getItem(JWT_KEY);
    if (!token || token.length == 0)
      return null;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return <TokenPayload>JSON.parse(jsonPayload);
  }

  parseRefresh(): TokenPayload | null {
    const token = localStorage.getItem(REFRESH_KEY);
    if (!token || token.length == 0)
      return null;

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return <TokenPayload>JSON.parse(jsonPayload);
  }

  login() {
    window.location.href = window.location.protocol + '//' + window.location.hostname + '/api/user/login';
  }


  constructor(private http: HttpClient, private log: LoggingService, private router: Router) {
    this.isLoggedIn = new BehaviorSubject<boolean>(false);
    this.tokenDto = new Subject<TokenDto>();

    if(router.isActive("/", true) && this.tokenIsExpired() && this.refreshIsExpired()) {
      return;
    }

    const isLoggedIn = !this.tokenIsExpired();
    if (!isLoggedIn && this.refreshIsExpired()) {
      this.log.Log(`Token and Refresh are expired`);
      this.isLoggedIn.next(false);
      this.router.navigate(['/']);
      this.clear();
    } else if (!isLoggedIn && !this.refreshIsExpired()) {
      this.log.Log(`Token is expired but Refresh is not`);
      this.isLoggedIn.next(true);
    } else if (isLoggedIn) {
      this.log.Log(`Is logged in`);
      this.isLoggedIn.next(isLoggedIn);
    } else {
      this.log.Log(`Is logged out`);
      this.isLoggedIn.next(isLoggedIn);
    }
  }

}

export class BehaviorIntervention {
  id: string;
  date: Date;
  behavior: string;
  intervention: string;

  static createFrom(source: any) {
    const result = new BehaviorIntervention();
    result.id = source['id'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.behavior = source['behavior'];
    result.intervention = source['intervention'];
    return result;
  }
}

/**
 * Created by ptdave on 6/4/17.
 */

import {Component, Input, OnChanges} from '@angular/core';
import {StudentService} from '../../services/student.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StudentContactDialog} from '../contact/student.contact.component';
import {Staff} from '../../models/staff.model';
import {InitialContact} from '../../models/student/initial_contact.model';
import {ContactLog} from '../../models/student/contactlog.model';
import {Level, ToastService} from '../../services/toast.service';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";

@Component({
  selector: `student-communication`,
  template: `
    <div class="card">
        <form #initialForm="ngForm">
          <div class="card-block">
            <h4 class="card-title" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}">
              Initial Contact
            </h4>
            <table class="table">
              <tbody>
              <tr>
                <td class="col-7">Date staff members notified parent/guardian of concern?</td>
                <td class="col-5"><input type="date" name="parent_notified" class="form-control d-print-none"
                                         [(ngModel)]="initial.parent_notified"> <span
                  class="d-none d-print-block">{{initial.parent_notified | date: 'shortDate'}}</span></td>
              </tr>
              <tr>
                <td class="col-7">Parents share concern?</td>
                <td class="col-5">
                  <select class="custom-select d-print-none" name="share_concern" [(ngModel)]="initial.share_concern">
                    <option *ngFor="let o of concern" [ngValue]="o">{{o}}</option>
                  </select>
                  <span class="d-none d-print-block">{{initial.share_concern}}</span></td>
              </tr>
              <tr>
                <td class="col-7">Communicator?</td>
                <td class="col-5">
                  <staff-select [edittable]="true" [multiple]="false" [(selected)]="initial.communicator" [districtId]="districtId"></staff-select>
                </td>
              </tr>
              <tr>
                <td class="col-7">Date district policies were given to the parent/guardian?</td>
                <td class="col-5">
                  <input type="date" name="policies_given" class="form-control d-print-none"
                         [(ngModel)]="initial.policies_given">
                  <span class="d-none d-print-block">{{initial.policies_given | date: 'shortDate'}}</span>
                </td>
              </tr>
              <tr>
                <td class="col-7">Date written notice that parent can request evaluation was provided?</td>
                <td class="col-5"><input type="date" name="written_notice" class="form-control d-print-none"
                                         [(ngModel)]="initial.written_notice">
                  <span class="d-none d-print-block">{{initial.written_notice | date: 'shortDate'}}</span></td>
              </tr>
              <tr>
                <td class="col-7"></td>
                <td class="col-5">
                  <button-save (btnClick)="save()"></button-save>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
      <nav class="navbar d-flex flex-row" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}">
        <span class="navbar-brand mr-auto">Communication</span>
        <button-new (btnClick)="newContact()" label="New"></button-new>
      </nav>
      <div class="container-fluid">
        <div class="py-1 row d-print-none" *ngFor="let c of contacts">
          <div class="col">
            {{c.date | date: 'shortDate'}}
          </div>
          <div class="col">
            {{c.type}}
          </div>
          <div class="col">
            {{c.staff_id | staffId | async}}
          </div>
          <button-more (btnClick)="openContact(c)"></button-more>
        </div>
      </div>
      <div *ngFor="let c of contacts" class="d-none d-print-block card" style="break-inside: avoid;">
        <div class="card-block">
          <div class="row">
            <div class="col-2">Date</div>
            <div class="col">{{c.date | date: 'shortDate'}}</div>
          </div>
          <div class="row">
            <div class="col-2">Method</div>
            <div class="col">{{c.type}}</div>
          </div>
          <div class="row">
            <div class="col-2">Assessment Shared</div>
            <div class="col">{{c.assess_shared}}</div>
          </div>
          <div class="row">
            <div class="col-2">Information Shared</div>
            <div class="col">{{c.info_shared}}</div>
          </div>
          <div class="row">
            <div class="col-2">Staff</div>
            <div class="col">{{c.staff_id | staffId | async}}{{c.staff_name}}</div>
          </div>
          <div class="row">
            <div class="col-2">Outcome</div>
            <div class="col">{{c.outcome}}</div>
          </div>
        </div>
      </div>
  `
})
export class StudentCommunicationComponent implements OnChanges {

  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  contacts: ContactLog[];
  initial: InitialContact;
  concern: string[] = ['N/A', 'Yes', 'No'];
  staff: Staff[];

  NotificationTitle = 'Communication';

  save() {
    this.studentService.patchInitialContact(this.districtId, this.studentId, this.initial).subscribe(resp => {
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved Initial Contact');
      this.initial = resp;
    });
  }

  ngOnChanges() {
    if (this.districtId && this.studentId) {
      this.getData();
    }
  }

  getData() {
    this.studentService.getContacts(this.districtId, this.studentId).subscribe(resp => {
      this.contacts = resp || [];
      this.contacts.sort((a: ContactLog, b: ContactLog) => {
        return a.date > b.date ? -1 : 1;
      });
    }, (resp) => {
      this.contacts = [];
    });
    this.studentService.getInitialContact(this.districtId, this.studentId).subscribe(resp => {
      this.initial = resp || new InitialContact();
    });
  }

  newContact() {
    const m = this.modalService.open(StudentContactDialog, {size: 'lg', keyboard: false});
    m.componentInstance.contact = {
      date: new Date()
    };
    m.componentInstance.studentId = this.studentId;
    m.componentInstance.districtId = this.districtId;
    m.componentInstance.staff = this.staff;
    m.componentInstance.edit = true;
    m.result.then(resp => {
      this.getData();
    });
  }

  openContact(c: ContactLog) {
    const m = this.modalService.open(StudentContactDialog, {size: 'lg', keyboard: false});
    m.componentInstance.contact = c;
    m.componentInstance.studentId = this.studentId;
    m.componentInstance.edit = false;
    m.componentInstance.districtId = this.districtId;
    m.result.then((resp) => {
      console.log(resp);
      this.getData();
    });
  }

  constructor(private studentService: StudentService,
              private modalService: NgbModal,
              private toastService: ToastService) {
    this.contacts = [];
    this.initial = new InitialContact();
    this.staff = [];
  }
}

<form #iripForm="ngForm" (ngSubmit)="save(iripForm)" novalidate>
  <div class="d-flex flex-row">
    <span class="mr-auto"></span>
    <button-save (btnClick)="save(iripForm)" [disabled]="(iripForm.form.invalid || teacherComponent?.invalid) || !(iripForm.form.touched || teacherComponent?.touched)" label="Save"></button-save>
    <button-cancel (btnClick)="cancel()" label="Cancel"></button-cancel>
  </div>
  <table class="table">
    <tr>
      <td>School Year</td>
      <td>
        <div class="input-group">
          <input #schoolYear="ngModel" type="text" class="form-control" name="school_year" [(ngModel)]="irip.school_year" placeholder="School Year" required>
          <span class="input-group-btn" ngbDropdown placement="bottom">
            <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
            <div ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let i of school_years" (click)="$event.preventDefault(); yearChange(i)">{{i}}</button>
            </div>
          </span>
        </div>
        <p *ngIf="schoolYear.invalid && (schoolYear.dirty || schoolYear.touched)" class="text-danger">
          You must specify a school year
        </p>
      </td>
    </tr>
    <tr>
      <td>Grade*</td>
      <td>
          <div class="input-group">
            <input class="form-control" name="grade" [(ngModel)]="irip.grade" #grade="ngModel" required>
            <span class="input-group-btn" ngbDropdown placement="bottom">
              <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
              <div ngbDropdownMenu>
                <button class="dropdown-item" *ngFor="let i of grades" (click)="$event.preventDefault(); gradeChange(i)">{{i}}</button>
              </div>
            </span>
          </div>
          <p *ngIf="grade.invalid && (grade.dirty || grade.touched)" class="text-danger">
            You must specify a grade level
          </p>
      </td>
    </tr>
    <tr>
      <td>Parent / Guardian*</td>
      <td class="d-print-none">
        <input class="form-control" name="guardian" [(ngModel)]="irip.guardian" #guardian="ngModel" required>
        <p *ngIf="guardian.invalid && (guardian.dirty || guardian.touched)"
             class="text-danger">
          You must specify a Parent or Guardian
        </p>
      </td>
      <td class="d-none d-print-block">{{irip.guardian}}</td>
    </tr>
    <tr>
      <td>Teacher*</td>
      <td>
        <staff-select [edittable]="true" [districtId]="districtId" [(selected)]="irip.teacher_id" required="true"
        (onChange)="staffChange($event)" [multiple]="false" #teacher></staff-select>
        <p *ngIf="teacher.invalid && teacher.touched" class="text-danger">You must specify a teacher.</p>
      </td>
    </tr>
    <tr>
      <td>Data Dialogue Date*</td>
      <td class="d-print-none">
        <input class="form-control" name="date" type="date" [(ngModel)]="irip.date" #date="ngModel" required>
        <p *ngIf="date.invalid && (date.dirty || date.touched)"
           class="text-danger">
        Please specify the date of the data dialogue
      </p>
      </td>
      <td class="d-none d-print-block">{{irip.date | date: 'shortDate' }}</td>
    </tr>
    <tr>
      <td>Team Members</td>
      <td class="d-print-none">
        <staff-select [edittable]="true" [districtId]="districtId" [selected]="irip.team_members"
                      (selectedChange)="teamChange($event)" [multiple]="true"></staff-select>
      </td>
    </tr>
    <tr>
      <td>Assessment Results</td>
      <td>
        <div class="container-fluid" *ngIf="irip.assessment_result_obj != null">
          <div class="row mb-1">
            <div class="col"></div>
            <div class="col-3 text-center">Fall</div>
            <div class="col-3 text-center">Winter</div>
            <div class="col-3 text-center">Spring</div>
          </div>
          <div class="row mb-1">
            <div class="col text-right">DRA</div>
            <div class="col-3"><input type="text" class="form-control" name="dra_fall" [(ngModel)]="irip.assessment_result_obj.dra.fall"></div>
            <div class="col-3"><input type="text" class="form-control" name="dra_winter" [(ngModel)]="irip.assessment_result_obj.dra.winter"></div>
            <div class="col-3"><input type="text" class="form-control" name="dra_spring" [(ngModel)]="irip.assessment_result_obj.dra.spring"></div>
          </div>
          <div class="row mb-1">
            <div class="col text-right">ACADIENCE</div>
            <div class="col-3"><input type="text" class="form-control" name="acadience_fall" [(ngModel)]="irip.assessment_result_obj.acadience.fall"></div>
            <div class="col-3"><input type="text" class="form-control" name="acadience_winter" [(ngModel)]="irip.assessment_result_obj.acadience.winter"></div>
            <div class="col-3"><input type="text" class="form-control" name="acadience_spring" [(ngModel)]="irip.assessment_result_obj.acadience.spring"></div>
          </div>
          <div class="row">
            <div class="col text-right">NWEA-ELA</div>
            <div class="col-3"><input type="text" class="form-control" name="nwea-fall" [(ngModel)]="irip.assessment_result_obj.nwea.fall"></div>
            <div class="col-3"><input type="text" class="form-control" name="nwea-winter" [(ngModel)]="irip.assessment_result_obj.nwea.winter"></div>
            <div class="col-3"><input type="text" class="form-control" name="nwea-spring" [(ngModel)]="irip.assessment_result_obj.nwea.spring"></div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td>Assessment Result</td>
      <td class="d-print-none">
        <textarea class="form-control" name="assessment_result"
                  [(ngModel)]="irip.assessment_result"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.assessment_result}}</td>
    </tr>
    <tr>
      <td>Other Performance Factors</td>
      <td class="d-print-none">
        <textarea class="form-control" name="other_factors"
                  [(ngModel)]="irip.other_factors"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.other_factors}}</td>
    </tr>
    <tr>
      <td>Strengths</td>
      <td class="d-print-none">
        <textarea class="form-control" name="strengths"
                  [(ngModel)]="irip.strengths"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.strengths}}</td>
    </tr>
    <tr>
      <td>Area(s) of Focus Based on Assessments</td>
      <td>
        <ul class="list-group">
          <li class="list-group-item d-flex flex-row" *ngFor="let aog of irip.areas_of_growth">
            <span class="mr-auto">{{aog}}</span>
            <button-delete btnClass="btn-sm d-print-none" (btnClick)="delAOG(aog)"></button-delete>
          </li>
          <li class="list-group-item d-flex flex-row d-print-none">
            <div class="input-group">
              <input type="text" class="form-control" name="newAOG" [(ngModel)]="newAOG">
              <span class="input-group-btn" ngbDropdown placement="bottom-right">
                <button class="btn btn-outline-primary" ngbDropdownToggle (click)="$event.preventDefault();"></button>
                <div ngbDropdownMenu>
                  <button type="button" class="dropdown-item" *ngFor="let aog of aog_values" (click)="$event.preventDefault(); newAOG=aog">{{aog}}</button>
                </div>
              </span>
            </div>
            <button-add btnClass="btn-sm" (btnClick)="addAOG()"></button-add>
          </li>
        </ul>
      </td>
    </tr>
    <tr>
      <td>Student Learning Goals</td>
      <td class="d-print-none">
        <textarea class="form-control" name="learning_goals"
                  [(ngModel)]="irip.learning_goals"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.learning_goals}}</td>
    </tr>
    <tr>
      <td>Intervention/Strategies</td>
      <td>
        <table class="table">
          <thead>
          <tr>
            <th>Intervention</th>
            <th>Implementer</th>
            <th>Days per week</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Result</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let intv of irip?.interventions; let i = index">
            <ng-container *ngIf="editIntvIndex !== i">
              <td>{{ intv.intervention }}</td>
              <td>{{ intv.implementer }}</td>
              <td>{{ intv.how_often }}</td>
              <td>{{ intv.start_date | date: 'shortDate' }}</td>
              <td>{{ intv.end_date | date: 'shortDate' }}</td>
              <td>{{ intv.result }}</td>
            </ng-container>
            <ng-container *ngIf="editIntvIndex == i">
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" [name]="editIntv" [(ngModel)]="intv.intervention" placeholder="Intervention">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of intv_values" (click)="$event.preventDefault(); intv.intervention = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newImpl" [(ngModel)]="intv.implementer" placeholder="Implementer">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of impl_values" (click)="$event.preventDefault(); intv.implementer = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newHowOften" [(ngModel)]="intv.how_often" placeholder="How Often">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of how_often_values" (click)="$event.preventDefault(); intv.how_often = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <input type="date" class="form-control" name="newStart" [(ngModel)]="intv.start_date">
              </td>
              <td>
                <input type="date" class="form-control" name="newEnd" [(ngModel)]="intv.end_date">
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newResult" [(ngModel)]="intv.result" placeholder="Result">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of result_values" (click)="$event.preventDefault(); intv.result = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
            </ng-container>

            <td class="d-print-none">
              <div style="display: flex;">
                <button-edit *ngIf="editIntvIndex == -1" (btnClick)="editIntv(i)" btnClass="btn-sm"></button-edit>
                <button-save *ngIf="editIntvIndex == i" (btnClick)="editIntvIndex = -1" btnClass="btn-sm"></button-save>
                <button-delete (btnClick)="removeIntv(i)" btnClass="btn-sm"></button-delete>
              </div>
            </td>
          </tr>
          </tbody>
          <tfoot class="d-print-none">
            <tr>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newIntv" [(ngModel)]="newIntv.intervention" placeholder="Intervention">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of intv_values" (click)="$event.preventDefault(); newIntv.intervention = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newImpl" [(ngModel)]="newIntv.implementer" placeholder="Implementer">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of impl_values" (click)="$event.preventDefault(); newIntv.implementer = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newHowOften" [(ngModel)]="newIntv.how_often" placeholder="How Often">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of how_often_values" (click)="$event.preventDefault(); newIntv.how_often = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <input type="date" class="form-control" name="newStart" [(ngModel)]="newIntv.start_date">
              </td>
              <td>
                <input type="date" class="form-control" name="newEnd" [(ngModel)]="newIntv.end_date">
              </td>
              <td>
                <div class="input-group">
                  <input type="text" class="form-control" name="newResult" [(ngModel)]="newIntv.result" placeholder="Result">
                  <span class="input-group-btn" ngbDropdown placement="bottom">
                    <button class="btn btn-outline-primary" ngbDropdownToggle type="button" (click)="$event.preventDefault();"></button>
                    <div ngbDropdownMenu>
                      <button class="dropdown-item" *ngFor="let i of result_values" (click)="$event.preventDefault(); newIntv.result = i">{{i}}</button>
                    </div>
                  </span>
                </div>
              </td>
              <td>
                <button-add btnClass="btn-sm" (btnClick)="addIntv()"></button-add>
              </td>
            </tr>
          </tfoot>
        </table>
      </td>
    </tr>
    <tr>
      <td>Intervention Notes</td>
      <td class="d-print-none">
        <textarea class="form-control" name="result_of_intervention"
                  [(ngModel)]="irip.result_of_intervention"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.result_of_intervention}}</td>
    </tr>
    <tr>
      <td>Parent / Guardian Notifications</td>
      <td class="d-print-none">
        <input type="date" name="guardian_notification_date" class="form-control"
               [(ngModel)]="irip.guardian_notification_date">
      </td>
      <td class="d-none d-print-block">{{irip.guardian_notification_date | date: 'shortDate'}}</td>
    </tr>
    <tr>
      <td>Home Plan Provided Date</td>
      <td class="d-print-none">
        <input type="date" name="home_plan_provided" class="form-control"
               [(ngModel)]="irip.home_plan_provided">
      </td>
      <td class="d-none d-print-block">{{irip.home_plan_provided | date: 'shortDate'}}</td>
    </tr>
    <tr>
      <td>Parent / Guardian Collaboration Date</td>
      <td class="d-print-none">
        <input type="date" name="guardian_collaboration_date" class="form-control"
               [(ngModel)]="irip.guardian_collaboration_date">
      </td>
      <td class="d-none d-print-block">{{irip.guardian_collaboration_date | date: 'shortDate'}}</td>
    </tr>
    <tr>
      <td>Notes from Parent / Guardian Collaboration</td>
      <td class="d-print-none">
        <textarea class="form-control" name="notes_from_guardian_collaboration"
                  [(ngModel)]="irip.notes_from_guardian_collaboration"></textarea>
      </td>
      <td class="d-none d-print-block">{{irip.notes_from_guardian_collaboration}}</td>
    </tr>

  </table>
  <div class="d-flex flex-row">
      <span class="mr-auto"></span>
      <button-save (btnClick)="save(iripForm)" [disabled]="!iripForm.form.dirty && !iripForm.form.touched" label="Save"></button-save>
      <button-cancel (btnClick)="cancel()" label="Cancel"></button-cancel>
    </div>
</form>

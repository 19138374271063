import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MeetingNextStep} from '../../models/district/meeting_next_steps.model';
import {Meeting} from '../../models/student/meeting.model';

@Component({
  selector: `meeting-nextsteps`,
  template: `
    <h3 class="d-none d-print-block">Next Steps</h3>
    <table class="table table-sm" *ngIf="!edit">
      <tbody>
        <tr *ngFor="let ns of meeting.next_step_list">
          <td>{{ns}}</td>
        </tr>
      </tbody>
    </table>

    <table class="table table-sm" *ngIf="edit">
      <tbody>
      <tr *ngFor="let ns of nextsteps" class="form-check" (click)="check(ns.name)">
        <td>&nbsp;</td>
        <td>
          <div class="form-check">
            <input type="checkbox" [checked]="checked(ns.name)" class="form-check-input">
          </div>
        </td>
        <td>{{ns.name}}</td>
      </tr>
      </tbody>
    </table>

    <span *ngIf="!edit">{{meeting.next_steps}}</span>
    <textarea *ngIf="edit" class="form-control" [(ngModel)]="meeting.next_steps" name="next_steps"
              (change)="meetingChange.emit(meeting);"></textarea>
  `
})
export class StudentMeetingNextstepsComponent {
  @Input() meeting: Meeting;
  @Output() meetingChange = new EventEmitter<Meeting>();
  @Input() nextsteps: MeetingNextStep[];
  @Input() edit: boolean;
  @Output() editChange = new EventEmitter<boolean>();

  check(v: string) {
    let f = -1;
    for (let i = 0; i < this.meeting.next_step_list.length; i++) {
      if (v === this.meeting.next_step_list[i]) {
        f = i;
        break;
      }
    }

    if (f > -1) {
      this.meeting.next_step_list.splice(f, 1);
    } else {
      this.meeting.next_step_list.push(v);
    }

    this.meetingChange.emit(this.meeting);
  }

  checked(v: string): boolean {
    for (let i = 0; i < this.meeting.next_step_list.length; i++) {
      if (v === this.meeting.next_step_list[i]) {
        return true;
      }
    }
    return false;
  }
}

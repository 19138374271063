import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm-dialog',
  templateUrl: 'confirm.modal.html'
})
export class ConfirmModal {
  @Input() title = 'Are you sure?';
  @Input() message = 'Are you sure?';
  @Input() yesMessage = 'Yes';
  @Input() noMessage = 'No';

  constructor(public activeModal: NgbActiveModal) {}
}

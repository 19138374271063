import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from "@angular/core";
import {AuthService} from "../../services/authService";
import {LoggingService} from "../../services/logging";

@Directive({
  selector: '[roles]'
})
export class RequireRolesDirective implements OnChanges {
  @Input('roles') roles: string[] | string;

  _roles: string[];

  constructor(private el: ElementRef, private tokenService: AuthService, private log: LoggingService) {
    this.el.nativeElement.style.display = 'none';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (Array.isArray(this.roles)) {
      this._roles = this.roles;
    } else {
      this._roles = this.roles.split(',')
    }

    const userRoles = this.tokenService.parseJwt().roles || [];
    for (let r = 0; r < this._roles.length; r++) {
      if (userRoles.indexOf(this._roles[r].trim()) < 0) {
        this.log.Log(`Could not find role ${this._roles[r]}`)

        this.el.nativeElement.style.display = 'none';
        return;
      }
    }

    this.log.Log(`Had roles ${JSON.stringify(this._roles)}`)

    this.el.nativeElement.style.display = '';
  }
}

<app-student-module ModuleName="Student Attendance by Period" [PrintOptions]="printOptions" [Loading]="loading">
    <div class=" d-flex flex-row">
        <table class="table">
          <thead>
          <tr>
            <th>School Year</th>
            <th>Reason</th>
            <th>0</th>
            <th>1</th>
            <th>2</th>
            <th>3</th>
            <th>4</th>
            <th>5</th>
            <th>6</th>
            <th>7</th>
            <th>8</th>
            <th>9</th>
          </tr>
          </thead>
          <tbody *ngFor="let a of attendance">
          <tr>
            <th [rowSpan]="a.reasons.length+1">{{a.school_year}}</th>
          </tr>
          <tr *ngFor="let r of a.reasons">
            <td>{{r.reason}}</td>
            <td *ngFor="let p of r.periods">
              {{p}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
</app-student-module>

export class ContactLog {
  id: string;
  date: Date;
  staff_id: string;
  staff_name: string;
  contact_id: string;
  type: string;
  info_shared: string;
  assess_shared: string;
  outcome: string;

  static createFrom(source: any) {
    const result = new ContactLog();
    result.id = source['id'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.staff_id = source['staff_id'];
    result.staff_name = source['staff_name'];
    result.contact_id = source['contact_id'];
    result.type = source['type'];
    result.info_shared = source['info_shared'];
    result.assess_shared = source['assess_shared'];
    result.outcome = source['outcome'];
    return result;
  }
}

<p>
  School Years are used for staff members to select. This helps uniform data for input.
</p>

<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
    <ng-container *ngFor="let schoolYear of schoolYears">
      <li [ngbNavItem]="schoolYear.id">
        <a ngbNavLink (click)="select(schoolYear);">{{schoolYear.name}}</a>
        <ng-template ngbNavContent>
          <form *ngIf="selected" ngForm #yearForm="ngForm" (ngSubmit)="submit(yearForm, selected)">
            <div class="form-group">
              <label for="name">Name*</label>
              <input type="text" class="form-control" id="name" name="name" [(ngModel)]="selected.name" required>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="startDate">Start Date*</label>
                <input type="date" class="form-control" id="startDate" name="startDate" [(ngModel)]="selected.startDate" required>
              </div>
              <div class="col-md-6">
                <label for="endDate">End Date*</label>
                <input type="date" class="form-control" id="endDate" name="endDate" [(ngModel)]="selected.endDate" required>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-danger mr-2" (click)="delete(selected)">Delete</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="active = null; selected = null;">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="yearForm.valid && !yearForm.touched">Save</button>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
    </ng-container>
    <li ngbNavItem="create">
      <a ngbNavLink (click)="addYear()">Add School Year</a>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-2 mt-2 flex-grow-1"></div>
</div>

import {Component, OnInit} from '@angular/core';
import {AdminService} from '../../services/admin.service';
import {District} from '../../models/district/district.model';
import {ActivatedRoute} from '@angular/router';
import {NgModel} from '@angular/forms';

@Component({
  selector: 'sidr-admin-district',
  templateUrl: 'adminDistrict.component.html',
  styles: [
    `.remove_padding {
      padding-left: 0px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }`
  ]
})
export class AdminDistrictComponent implements OnInit {
  district: District;

  constructor(
    private adminService: AdminService,
    private router: ActivatedRoute
  ) {
    this.district = new District();

    this.router.params.subscribe(p => {
      this.adminService
        .getDistrict(p['district_id'])
        .subscribe(d => {
          this.district = d;
        });
    });
  }

  ngOnInit() {
  }


  containerClass(): string {
    return 'mt-2 d-flex flex-row';
  }

  nameClass(model: NgModel): string {
    let ret = 'p-1 mb-1 text-light col-3 bg-success';
    if (model && model.invalid) {
      if (model.touched || model.dirty) {
        ret += 'bg-danger';
      }
    }
    return ret;
  }

  dataClass(): string {
    return 'remove_padding col-9';
  }
}

<div class="d-flex">
  <ul ngbNav #problemNav="ngbNav" class="nav-pills" orientation="vertical" destroyOnHide="false">
    <li *ngFor="let problem of problems; let i = index" [ngbNavItem]="problem.id"  destroyOnHide="false">
      <a ngbNavLink>
        <div class="d-flex">
          {{ problem.name }}
          <span class="ml-auto fa fa-trash" (click)="deleteProblem(i)"></span>
        </div>

      </a>
      <ng-template ngbNavContent>
        <module-meetings-problem [(problem)]="problems[i]" [districtId]="districtId"></module-meetings-problem>
      </ng-template>
    </li>
    <li ngbButton class="nav-item">
      <a href (click)="addProblem()" class="nav-link">Create a new meeting problem</a>
    </li>
  </ul>
  <div [ngbNavOutlet]="problemNav" class="col-10"></div>
</div>

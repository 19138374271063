import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {SIDRCommonModule} from "../common/sidrCommon.module";
import {StaffPageComponent} from "./staffPage/staffPage.component";
import {FormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {StaffDialogComponent} from "./staffDialog/staffDialog.component";

@NgModule({
  declarations:[
    StaffPageComponent,
    StaffDialogComponent,
  ],
  exports:[
    StaffPageComponent,
    StaffDialogComponent
  ],
  imports:[
    NgbModule,
    CommonModule,
    FormsModule,
    SIDRCommonModule
  ],
})
export class StaffModule {

}

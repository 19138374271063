import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from '../../services/user.service';
import {SIDRUploaderDirective} from '../../components/helpers/fileupload/fileupload.directive';
import {DistrictService} from '../../services/district.service';
import {ActivatedRoute} from '@angular/router';
import {TitleService} from '../../services/title.service';
import {Upload} from './upload';
import {DistrictImportService} from '../../services/districtImport.service';
import {DistrictImportDto} from '../../common/dtos/districtImportDto';


@Component({
  // tslint:disable-next-line:component-selector
  selector: `district-imports`,
  templateUrl: 'imports.component.html'
})
export class DistrictImportsComponent implements OnInit {
  @ViewChild('sidrUploader') sidrUploader: SIDRUploaderDirective;
  districtId: string;
  public hasBaseDropZoneOver = false;
  public hasAnotherDropZoneOver = false;
  imports: DistrictImportDto[];
  pageNumber: number = 0;
  totalImports: number = 0;
  pageSize: number = 20;


  constructor(private userService: UserService,
              private activeRoute: ActivatedRoute,
              private titleService: TitleService,
              private districtService: DistrictService,
              private importService: DistrictImportService) {
  }

  public ngOnInit() {
    this.activeRoute.parent.params.subscribe(p => {
      this.districtId = p['districtId'];
      this.getData();
    });

    this.titleService.setTitle('District Imports');
  }

  getData() {
    if (!this.districtId) {
      return;
    }
    this.districtService.getImports(this.districtId, this.pageNumber, this.pageSize).subscribe(imports => {
      this.imports = imports || [];
    });
    this.districtService.getImportCount(this.districtId).subscribe(count => {
      this.totalImports = count;
    });
  }

  get totalPages(): number {
    return Math.ceil(this.totalImports / this.pageSize);
  }

  goToFirstPage() {
    this.pageNumber = 0;
    this.getData();
  }

  prevPage() {
    if (this.pageNumber > 0) {
      this.pageNumber--;
      this.getData();
    }
  }

  nextPage() {
    if ((this.pageNumber + 1) * this.pageSize < this.totalImports) {
      this.pageNumber++;
      this.getData();
    }
  }

  goToLastPage() {
    this.pageNumber = this.totalPages - 1;
    this.getData();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }


  onFilesChangedEmitter($event: FileList) {
    const files: File[] = new Array($event.length);
    for (let i = 0; i < $event.length; i++) {
      files[i] = $event.item(i);
    }
    this.importService.uploadFiles(this.districtId, files);
  }
}

import { Component, OnInit } from '@angular/core';
import { DistrictImportService } from '../../services/districtImport.service';

@Component({
  selector: 'app-import-dropdown',
  templateUrl: './importDropdown.component.html'
})
export class ImportDropdownComponent implements OnInit {
  overallProgress = 0;
  currentPage = 1;
  itemsPerPage = 5;
  visible = false;
  expanded = false;

  constructor(private importService: DistrictImportService) {}

  ngOnInit(): void {
    this.importService.getOverallProgress().subscribe(progress => {
      this.overallProgress = progress;
    });
    this.importService.filesAdded.subscribe(p => {
      this.visible = true;
    });
  }

  get paginatedFiles() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.importService.uploads.slice(start, end);
  }

  get allFilesUploaded(): boolean {
    return this.importService.uploads.every(file => file.progress === 100);
  }

  nextPage() {
    if (this.currentPage * this.itemsPerPage < this.importService.uploads.length) {
      this.currentPage++;
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  toggleExpandOrClose() {
    if (this.allFilesUploaded) {
      this.importService.clear();
      this.visible = false;
    } else {
      this.expanded = !this.expanded;
    }
  }
}

import {Component, Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";

export interface IDirty {
  isDirty(): boolean;
}

@Component({
  selector: 'candeactivate-modal',
  template: `
<div class="modal-header">
    <h4 class="modal-title">Unsaved changes</h4>
</div>
<div class="modal-body">
    <p>You have unsaved changes, are you sure you want to navigate away? All changes will be lost.</p>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.close(true)">Yes</button>
    <button type="button" class="btn btn-success" (click)="activeModal.close(false)">No</button>
</div>
  `
})
export class CanDeactivateServiceModal {
  constructor(public activeModal: NgbActiveModal) {
  }
}

@Injectable()
export class CanDeactivateService implements CanDeactivate<IDirty> {

  constructor(private modalService: NgbModal) {

  }

  canDeactivate(
    component: IDirty,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (component == null) {
      return true;
    }
    let dirty: boolean = component.isDirty();
    console.log(dirty);
    if (dirty) {
      const ctx = this.modalService.open(CanDeactivateServiceModal, {
        backdrop: "static",
        centered: true,
        scrollable: false,
      });
      const o = new Promise<boolean>((resolve, reject) => {
        ctx.result.then(r => {
          resolve(r);
        });
      });
      return o;
    } else {
      return true;
    }
  }

}

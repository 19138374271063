/**
 * Created by ptdave on 6/22/17.
 */
import {Component, Input} from '@angular/core';
import {StudentService} from '../../services/student.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {formatDate} from '../../date.prototype';
import {DistrictService} from '../../services/district.service';
import {ContactLog} from '../../models/student/contactlog.model';
import {Level, ToastService} from '../../services/toast.service';
import {StaffDto} from "../../common/dtos/staffDto";

@Component({
  selector: `student-contact-dialog`,
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Parent / Guardian Contact</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-6">Date:</div>
        <div class="col-6" *ngIf="!edit">{{contact.date | date : 'shortDate'}}</div>
        <div class="col-6" *ngIf="edit">
          <input class="form-control" type="date" [(ngModel)]="editContact.date">
        </div>
      </div>
      <div class="row">
        <div class="col-6">Method</div>
        <div class="col-6" *ngIf="!edit">
          {{contact.type}}
        </div>
        <div class="col-6" *ngIf="edit">
          <input class="form-control" type="text" [(ngModel)]="editContact.type">
        </div>
      </div>
      <div class="row">
        <div class="col-6">Communicator:</div>
        <div class="col-6" *ngIf="!edit">{{contact.staff_name}}{{contact.staff_id | staffId | async}}</div>
        <div class="col-6" *ngIf="edit">
          <staff-select [multiple]="false" [edittable]="true" [(selected)]="editContact.staff_id" [districtId]="districtId"></staff-select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">Assessment Shared:</div>
        <div class="col-6" *ngIf="!edit">{{contact.assess_shared}}</div>
        <div class="col-6" *ngIf="edit">
          <input type="text" class="form-control" [(ngModel)]="editContact.assess_shared">
        </div>
      </div>
      <div class="row">
        <div class="col-6">Information Shared:</div>
        <div class="col-6" *ngIf="!edit">{{contact.info_shared}}</div>
        <div class="col-6" *ngIf="edit">
          <input class="form-control" type="text" [(ngModel)]="editContact.info_shared">
        </div>
      </div>
      <div class="row">
        <div class="col-6">Outcome:</div>
        <div class="col-6" *ngIf="!edit">{{contact.outcome}}</div>
        <div class="col-6" *ngIf="edit">
          <textarea class="form-control" rows="1" [(ngModel)]="editContact.outcome"></textarea>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" *ngIf="edit" (click)="save()">
        <i class="fa fa-floppy-o" aria-hidden="true"></i>
        Save
      </button>
      <button class="btn btn-primary" *ngIf="!edit" (click)="markEditContact()">
        <i class="fa fa-pencil" aria-hidden="true"></i>
        Edit
      </button>
      <button class="btn btn-secondary pull-right" (click)="closeWindow()">
        <i class="fa fa-times" aria-hidden="true"></i>
        Close
      </button>
    </div>
  `
})
export class StudentContactDialog {
  editContact: any;
  @Input() contact: ContactLog = new ContactLog();
  @Input() edit: boolean;
  @Input() studentId: string;
  @Input() districtId: string;
  @Input() staff: StaffDto[];

  NotificationTitle = 'Communication';

  constructor(
    public activeModal: NgbActiveModal,
    public studentService: StudentService,
    public districtService: DistrictService,
    private toastService: ToastService) {
    this.edit = false;
    this.editContact = {
      date: new Date()
    };
  }

  markEditContact() {
    this.districtService.getStaff(this.districtId).subscribe(resp => {
      this.staff = resp;
      this.edit = true;
      this.editContact = this.contact;
      this.editContact.date = formatDate(this.contact.date);
    });
  }

  closeWindow() {
    if (this.edit) {
      this.activeModal.close('cancelled');
    } else {
      this.activeModal.dismiss();
    }
  }

  save() {
    if (!this.contact.id) {
      // This contact is being editted, not new
      this.studentService.patchContact(this.districtId, this.studentId, this.editContact).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Updated contact');
        this.activeModal.close(resp);
      });
    } else {
      this.studentService.postContact(this.districtId, this.studentId, this.editContact).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved contact');
        this.activeModal.close(resp);
      });
    }
  }
}

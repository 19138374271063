import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DistrictTypeDto} from '../common/dtos/districtTypeDto';
import {Observable} from 'rxjs';

@Injectable()
export class TypesService {
  public static readonly Achievements = 'achievements';
  public static readonly AcademicArea = 'academic_area';
  public static readonly Grades = 'grades';
  public static readonly InterventionTier = 'intervention_tier';
  public static readonly InterventionType = 'intervention_type';
  public static readonly MeetingNextStep = 'meeting_nextstep';
  public static readonly MeetingProblem = 'meeting_problem';
  public static readonly MeetingStrCon = 'meeting_strcon';
  public static readonly MeetingTopic = 'meeting_topic';
  public static readonly OtherFactor = 'otherfactor';
  public static readonly SchoolYear = 'school_year';

  constructor(private http: HttpClient) {
  }

  public getBasicType<T extends DistrictTypeDto = DistrictTypeDto>(districtId: string, basicType: string): Observable<T[]> {
    return this.http.get<T[]>(`/api/district/${districtId}/types/basic/${basicType}`);
  }

  public getType<T extends DistrictTypeDto = DistrictTypeDto>(districtId: string, type: string): Observable<T[]> {
    return this.http.get<T[]>(`/api/district/${districtId}/types/${type}`);
  }

  public postType<T extends DistrictTypeDto = DistrictTypeDto>(districtId: string, type: string, data: T): Observable<T> {
    return this.http.post<T>(`/api/district/${districtId}/types/${type}`, data);
  }

  public deleteType<T extends DistrictTypeDto = DistrictTypeDto>(districtId: string, type: string, id: string): Observable<any> {
    return this.http.delete<T>(`/api/district/${districtId}/types/${type}/${id}`);
  }

  public patchType<T extends DistrictTypeDto = DistrictTypeDto>(districtId: string, type: string, id: string, data: T): Observable<T> {
    return this.http.patch<T>(`/api/district/${districtId}/types/${type}/${id}`, data);
  }
}

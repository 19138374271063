<app-student-module ModuleName="Student Information" [PrintOptions]="printOptions" [Loading]="loading">
  <div class="container">
    <div class="row">
      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>First Name</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.firstName }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Last Name</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.lastName }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Middle Name</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.middleName }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Date of Birth</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.dob | date : 'shortDate' }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Ethnicity</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.ethnicity }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Gender</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.gender }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Address</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">
        {{ student?.address }}<br />
        {{ student?.city }}, {{ student?.state }} {{ student?.zip }}
      </div>

      <div class="mb-5 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Phone Number</strong></div>
      <div class="mb-5 col-xs-6 col-sm-3">{{ student?.phone }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>School ID</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.schoolId }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Grade</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3">{{ student?.grade }}</div>

      <div class="mb-2 col-xs-6 col-sm-3 text-left text-sm-right"><strong>Active</strong></div>
      <div class="mb-2 col-xs-6 col-sm-3" *ngIf="student?.active">True</div>
      <div class="mb-2 col-xs-6 col-sm-3" *ngIf="!student?.active">False</div>


    </div>
  </div>
</app-student-module>

import {Subject} from 'rxjs';
/**
 * Created by ptdave on 5/7/17.
 */

export class PrintTabOption {
  name: string;
  value: any;
  simple_name: string;
  type: string;

  constructor(name: string, simple: string, type: string, defaultValue: any) {
    this.name = name;
    this.type = type;
    this.value = defaultValue;
    this.simple_name = simple;
  }
}

export class PrintTab {
  name: string;
  options: PrintTabOption[];
  disabledOn: string;

  constructor(name: string, disabledOn = '') {
    this.name = name;
    this.disabledOn = disabledOn;
    this.options = [];
  }
}

export class PrintOptions {
  tabs: PrintTab[];

  constructor() {
    this.tabs = [];
  }

  getValue(name: string): any {
    if (name === '') {
      return true;
    }

    for ( let t = 0; t < this.tabs.length; t++) {
      for ( let o = 0; o < this.tabs[t].options.length; o++) {
        if (this.tabs[t].options[o].simple_name === name) {
          return this.tabs[t].options[o].value;
        }
      }
    }

    // Doesn't exist, default true
    return true;
  }
}

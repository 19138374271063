import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DistrictService} from '../../services/district.service';

import {NgForm} from '@angular/forms';
import {StaffDto} from "../../common/dtos/staffDto";
import {LoggingService} from "../../services/logging";

@Component({
  selector: `staff-list`,
  template: `
    <div class="list-group">
      <div class="list-group-item" *ngIf="edit">
        <div class="input-group">
          <select class="custom-select" [(ngModel)]="toAdd" ngbTooltip="List of available staff members. Click the add button to the right to insert them into the document.">
            <option *ngFor="let s of staffCol" [value]="s.id">{{s.id | staffId: s}}</option>
          </select>
          <div class="input-group-append">
            <button type="button" class="btn btn-success" (click)="add(toAdd)" ngbTooltip="Add the selected staff member to the list">
                <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div *ngFor="let s of staff" class="list-group-item d-flex justify-content-between align-items-center">
        <span class="mr-auto">{{s | staffId | async}}</span>
        <button-delete *ngIf="edit" btnClass="btn-sm pull-right" [confirm]="false"
                       (btnClick)="remove(s)"></button-delete>
      </div>
    </div>
  `
})
export class StaffListComponent implements OnInit {
  @Input() district_id = 'me';
  @Input() staff: string[];
  @Input() edit = false;
  @Input() form: NgForm;
  @Output() staffChange = new EventEmitter();
  staffCol: StaffDto[];
  toAdd = '';

  ngOnInit(): void {
    this.districtService.getActiveStaff(this.district_id).subscribe(resp => {
      this.staffCol = resp;
    });
  }

  constructor(private districtService: DistrictService, private log: LoggingService) {
  }

  remove(id) {
    this.staff.splice(this.staff.indexOf(id), 1);
    if (!this.form) {
      this.form.form.markAsDirty();
    }
    this.staffChange.emit(this.staff);
  }

  add(s) {
    if (!s || s.length == 0) {
      return;
    }

    this.log.Log(`adding ${s}`);

    this.staff = this.staff || [];

    if (this.form) {
      this.form.form.markAsDirty();
    }
    this.staff.push(s);
    this.toAdd = '';
    this.staffChange.emit(this.staff);
  }
}

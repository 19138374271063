<nav class="navbar navbar-expand-lg navbar-light bg-light d-print-none">
  <span class="navbar-brand">{{ModuleName}}</span>
  <span class="ml-2">
    <div class="content-loading" [ngClass]="{'content-loading-hidden': !Loading}"></div>
  </span>
  <span class="mr-auto"></span>
  <button class="btn btn-primary btn-sm mr-2 d-print-none" *ngIf="canShowNew()" [disabled]="NewDisabled" [hidden]="NewHidden" (click)="onNew()">New</button>
  <button class="btn btn-success btn-sm mr-2 d-print-none" *ngIf="canShowSave()" [disabled]="SaveDisabled" [hidden]="SaveHidden" (click)="onSave()">Save</button>
  <button class="btn btn-warning btn-sm d-print-none" *ngIf="canShowCancel()" [disabled]="CancelDisabled" [hidden]="CancelHidden" (click)="onCancel()">Cancel</button>
  <button class="btn btn-sm d-print-none" [ngClass]="ExtraButtonClasses" *ngIf="canShowExtraButton()" [disabled]="ExtraButtonDisabled" [hidden]="ExtraButtonHidden" (click)="onExtraButton()">{{ExtraButtonLabel}}</button>
</nav>

<h3 class="d-none d-print-block" [ngClass]="{ 'd-print-none': !PrintOptions?.getValue('display.headers') }">{{ModuleName}}</h3>

<div class="container-fluid my-2 ng-content" [ngClass]="{'ng-content-loading':Loading}">
  <ng-content></ng-content>
</div>

<nav class="navbar navbar-expand-lg navbar-light bg-light d-print-none">
  <span class="mr-auto"></span>
  <button class="btn btn-primary btn-sm mr-2 d-print-none" *ngIf="canShowNew()" [disabled]="NewDisabled" [hidden]="NewHidden" (click)="onNew()">New</button>
  <button class="btn btn-success btn-sm mr-2 d-print-none" *ngIf="canShowSave()" [disabled]="SaveDisabled" [hidden]="SaveHidden" (click)="onSave()">Save</button>
  <button class="btn btn-warning btn-sm d-print-none" *ngIf="canShowCancel()" [disabled]="CancelDisabled" [hidden]="CancelHidden" (click)="onCancel()">Cancel</button>
</nav>

import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ReportService {
  private basePath;

  constructor(private http: HttpClient) {
    this.basePath = "/api/reports"
  }

  public getReport(districtId: string, report: string): Observable<string> {
    const path = `${this.basePath}/${districtId}/${report}`;
    return this.http.get(path, {responseType: "text"});
  }
}

import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';

@Component({
  // tslint:disable-next-line:component-selector
  selector: `googleAuth`,
  template: `<button (click)="authenticate()">Authenticate</button>`
})
export class GoogleAuthComponent {
  gApiSetup = false;
  authInstance: google.accounts.oauth2.CodeClient;

  constructor(private router: Router) {

  }

  async initGoogleAuth(): Promise<void> {
    this.authInstance = await google.accounts.oauth2.initCodeClient({
      client_id: environment.google_client_id,
      ux_mode: 'popup',
      redirect_uri: environment.redirect_uri,
      scope: 'profile email',
      callback: response => {
        this.router.navigate(['/token'], {
          queryParams: {
            code: response.code
          }
        });
      }
    });
    this.gApiSetup = true;
  }

  async authenticate(): Promise<void> {
    if (!this.gApiSetup) {
      await this.initGoogleAuth();
    }

    return new Promise(async () => {
      this.authInstance.requestCode();
    });
  }

}

<div class="container d-flex flex-column col p-1">
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Privacy Policy</h4>
      <p class="card-text">
        SIDR Online stores your user profile. This information is then used
        to login and apply the appropriate permissions to your user account,
        pending a home domain matches one that is on record for SIDR.<br/>
        <br/>
        The information requested will be: The users Google profile and email address.
        More specifically, the users Google Id and email address in the database are
        stored and used as reference. If a users Google Id is not known, a search for
        the users email address is done.<br/>
        <br/>
        Your information will be removed on request if you have logged in with
        an invalid account. To do this, please email david@sidronline.com using
        the Google account that you have signed in with.<br/>
      </p>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <h4 class="card-title">Changes</h4>
      <p class="card-text">
        This information is not shared with any third-parties. Should this
        change, all customers will be informed first.
      </p>
    </div>
  </div>
</div>

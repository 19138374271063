import {LoginComponent} from './main/login.component';
import {StudentSearchComponent} from './main/studentSearch.component';
import {StudentCanDeactivate} from './helpers/StudentCanDeactivate';
import {StudentListComponent} from './main/studentList.component';
import {StaffListComponent} from './helpers/staffList.component';
import {RoleToggleComponent} from './helpers/roleToggle.component';
import {ListInputComponent} from './helpers/listInput.component';
import {HomeDistrictAdminComponent} from './home/home-district-admin/home-district-admin.component';
import {BuildingToggleComponent} from './helpers/buildingToggle.component';
import {NotificationComponent} from '../common/components/notification/notification.component';
import {DistrictNameComponent} from './main/districtName/districtName.component';
import {HomeBuildingAdminComponent} from './home/home-building-admin/home-building-admin.component';
import {SortableTableComponent} from './helpers/sortableTable/sortableTable.component';
import {StaffDialogComponent} from '../staff/staffDialog/staffDialog.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgModule} from '@angular/core';
import {StaffButtonSelectDialogComponent, StaffSelectComponent} from './main/staffButton.component';


import {ConfirmDialog} from '../common/components/buttons/confirmDialog.component';
import {SIDRUploaderDirective} from './helpers/fileupload/fileupload.directive';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CancelbuttonComponent} from '../common/components/buttons/cancelbutton.component';
import {HeaderComponent} from './helpers/header.component';
import {ConfirmModal} from './modals/confirm/confirm.modal';
import {SIDRCommonModule} from '../common/sidrCommon.module';
import {RouterModule} from '@angular/router';
import {ImportDropdownComponent} from './importDropdown/importDropdown.component';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SIDRCommonModule
  ],
  entryComponents: [
    StaffDialogComponent,
    ConfirmDialog,
    StaffButtonSelectDialogComponent,
    ConfirmModal,
  ],
  providers: [
    StudentCanDeactivate
  ],
  declarations: [
    ConfirmModal,
    HeaderComponent,
    StaffButtonSelectDialogComponent,
    CancelbuttonComponent,
    StaffSelectComponent,
    SIDRUploaderDirective,
    ImportDropdownComponent,

    ConfirmDialog,
    StaffSelectComponent,
    LoginComponent,
    StudentSearchComponent,
    StudentListComponent,
    StaffListComponent,
    DistrictNameComponent,
    ListInputComponent,
    BuildingToggleComponent,
    RoleToggleComponent,

    SortableTableComponent,
    HomeBuildingAdminComponent,
    HomeDistrictAdminComponent,
  ],
  exports: [
    ConfirmModal,
    HeaderComponent,
    StaffButtonSelectDialogComponent,
    StaffSelectComponent,
    CancelbuttonComponent,
    SIDRUploaderDirective,
    ImportDropdownComponent,

    ConfirmDialog,
    StaffSelectComponent,
    LoginComponent,
    StudentSearchComponent,

    StudentListComponent,

    StaffListComponent,

    DistrictNameComponent,
    ListInputComponent,
    BuildingToggleComponent,
    RoleToggleComponent,

    SortableTableComponent,
    HomeBuildingAdminComponent,
    HomeDistrictAdminComponent,
  ],
  bootstrap: [],
})
export class ComponentsModule {

}

<nav [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}" class="navbar">
  <span class="navbar-brand">Observational Data</span>
</nav>
<table class="table table-striped">
  <thead>
  <tr>
    <th>Date</th>
    <th>
      <button-new (btnClick)="newObservation()" btnClass="btn-sm pull-right"></button-new>
    </th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let o of observations">
    <td>{{o.date | date : 'shortDate'}}</td>
    <td>
      <button-more (btnClick)="open(o)" btnClass="btn-sm pull-right"></button-more>
    </td>
  </tr>
  </tbody>
</table>

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PrintOptions } from "../../common/components/buttons/print-options.helper";;

@Component({
  selector: 'app-student-module',
  templateUrl: './student-module.component.html',
  styleUrls: ['./student-module.component.scss']
})
export class StudentModuleComponent implements OnInit {
  @Input() ModuleName: string;
  @Input() PrintOptions: PrintOptions;


  @Output() New: EventEmitter<any> = new EventEmitter();
  @Input() NewDisabled: boolean;
  @Input() NewHidden: boolean;

  @Output() Save: EventEmitter<any> = new EventEmitter();
  @Input() SaveDisabled: boolean;
  @Input() SaveHidden: boolean;

  @Output() Cancel: EventEmitter<any> = new EventEmitter();
  @Input() CancelDisabled: boolean;
  @Input() CancelHidden: boolean;

  @Output() ExtraButton: EventEmitter<any> = new EventEmitter();
  @Input() ExtraButtonDisabled: boolean;
  @Input() ExtraButtonHidden: boolean;
  @Input() ExtraButtonLabel: string;
  @Input() ExtraButtonClasses: string;

  @Input() Loading: boolean;

  canShowNew(): boolean {
    return this.New.observers.length > 0;
  }

  canShowSave(): boolean {
    return this.Save.observers.length > 0;
  }

  canShowCancel(): boolean {
    return this.Cancel.observers.length > 0;
  }

  canShowExtraButton(): boolean {
    return this.ExtraButton.observers.length > 0;
  }


  onNew() {
    if (this.NewDisabled || this.NewHidden) {
      return;
    }
    this.New.emit(null);
  }

  onSave() {
    if (this.SaveDisabled || this.SaveHidden) {
      return;
    }
    this.Save.emit(null);
  }

  onCancel() {
    if (this.CancelDisabled || this.CancelHidden) {
      return;
    }
    this.Cancel.emit(null);
  }

  onExtraButton() {
    if (this.ExtraButtonDisabled || this.ExtraButtonHidden) {
      return;
    }
    this.ExtraButton.emit(null);
  }

  constructor() {
    this.ModuleName = 'UnNamed';
    this.PrintOptions = null;

    this.NewDisabled = false;
    this.NewHidden = false;

    this.SaveDisabled = false;
    this.SaveHidden = false;

    this.CancelDisabled = false;
    this.CancelHidden = false;

    this.Loading = false;
  }

  ngOnInit() {
  }

}

import {Component, OnInit} from '@angular/core';
import {AdminService} from 'src/app/services/admin.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit {
  ngOnInit(): void {

  }
  constructor(private adminService: AdminService, private activatedRoute: ActivatedRoute) {}
}

import {NgModule} from '@angular/core';
import {AdminDistrictsComponent} from './districts/adminDistricts.component';
import {AdminDistrictComponent} from './districts/adminDistrict.component';
import {AdminFilesComponent} from './files/admin.files.component';
import {AdminOptionsComponent} from './options/admin.options.component';
import {AdminTokensComponent} from './tokens/admin.tokens.component';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AdminViewComponent} from './view/admin-view.component';
import {RouterModule} from '@angular/router';

@NgModule({
  bootstrap: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule
  ],
  declarations: [
    AdminViewComponent,
    AdminDistrictComponent,
    AdminDistrictsComponent,
    AdminFilesComponent,
    AdminOptionsComponent,
    AdminTokensComponent
  ],
  exports: [
    AdminViewComponent,
    AdminDistrictComponent,
    AdminDistrictsComponent,
    AdminFilesComponent,
    AdminOptionsComponent,
    AdminTokensComponent
  ]
})
export class AdminModule {

}

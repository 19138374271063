import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {NgForm} from '@angular/forms';
import {DistrictDto} from "../../common/dtos/districtDto";
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {IDirty} from "../../services/canDeactivate.service";
import {Level, ToastService} from "../../services/toast.service";

@Component({
  selector: `district-general`,
  templateUrl: 'general.component.html'
})
export class DistrictGeneralComponent implements OnInit, IDirty {
  @Output() ready = new EventEmitter();
  @ViewChild('generalForm') generalForm: NgForm;

  districtId: string;
  district: DistrictDto;
  selectedFile: File;

  save(f: NgForm) {
    f.form.disable();

    const formData = new FormData();
    for (let key in f.value) {
      if (f.value.hasOwnProperty(key)) {
        if (f.value[key] instanceof File) {
          formData.append(key, f.value[key], f.value[key].name);
        } else {
          formData.append(key, f.value[key]);
        }
      }
    }

    if (this.selectedFile) {
      formData.append('newDistrictImage', this.selectedFile, this.selectedFile.name);
    }

    this.districtService.updateAsForm(this.districtId, formData)
      .subscribe(r => {
        f.form.markAsPristine();
        this.district = r;
        f.form.enable();
        this.toastService.AddNotification(Level.SUCCESS, "Success", "District information saved successfully.")
      }, err => {
        f.form.enable();
        this.toastService.AddNotification(Level.ERROR, "Failed to save", "Error saving district information. Please try again.");
      });
  }

  onFileSelected(event, generalForm: NgForm) {
    this.selectedFile = <File>event.target.files[0];
    generalForm.form.markAsTouched();
    generalForm.form.markAsDirty()
  }

  ngOnInit(): void {
    this.titleService.setTitle("District General Settings");
    this.activeRoute.parent.parent.params.subscribe(params => {
      console.log(params);
      this.districtId = params['districtId'];
      this.getData();
    });
  }

  constructor(
    private districtService: DistrictService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService) {
    this.district = null;
  }

  getData() {
    if(this.districtId) {
      this.districtService.get(this.districtId).subscribe(r => {
        this.district = r;
        this.ready.emit();
      }, (err) => {
        this.toastService.AddNotification(Level.ERROR, "Failed to load", "Error loading district information. Please try again.");

      });
    }
  }

  isDirty(): boolean {
    return this.generalForm.dirty;
  }
}

<app-student-module ModuleName="Student Attendance" [PrintOptions]="printOptions" [Loading]="loading">
  <div class="d-flex flex-column">
    <div class="col-md-6">
      <canvas baseChart *ngIf="ready && showChart" [datasets]="dataset" [options]="lineChartOptions" [legend]="lineChartLegend"
        [chartType]="lineChartType" [labels]="labels"></canvas>
    </div>
    <div>
      <table class="table">
        <thead>
          <tr>
            <th>School Year</th>
            <th>Tardy</th>
            <th>Excused</th>
            <th>Unexcused</th>
            <th>Total Days</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let a of attendance">
            <td>{{a.school_year}}</td>
            <td>{{a.tardy}}</td>
            <td>{{a.excused}}</td>
            <td>{{a.unexcused}}</td>
            <td>{{a.total_days}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</app-student-module>

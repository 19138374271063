<form #addForm="ngForm" (ngSubmit)="save(newValue)">
  <table class="table table-bordered table-sm">
    <thead *ngIf="showHeader" class="thead-default">
    <tr scope="row">
      <th *ngIf="showCount">#</th>
      <th *ngFor="let header of headers; let i = index">
        <div class="d-flex align-items-center">
          <span (click)="sortHeader(i)">{{header?.name}}</span>
          <button class="btn btn-link btn-sm d-print-none">
            <i *ngIf="header.sortOrder === 0" aria-hidden="true" class="fa fa-fw"></i>
            <i *ngIf="header.sortOrder === 1" aria-hidden="true" class="fa fa-fw fa-sort-asc"></i>
            <i *ngIf="header.sortOrder === 2" aria-hidden="true" class="fa fa-fw fa-sort-desc"></i>
          </button>
          <div *ngIf="header.filterable" class="ml-auto d-print-none" ngbDropdown autoClose="outside" placement="bottom-right">
            <button class="btn btn-sm btn-secondary" ngbDropdownToggle>
              <i aria-hidden="true" class="fa fa-fw fa-filter"></i>
            </button>
            <div ngbDropdownMenu>
              <button (click)="toggleOmit(header.value,d)" *ngFor="let d of data | distinct : header.value"
                      class="dropdown-item">
                <i *ngIf="!isOmitted(header.value,d)" aria-hidden="true" class="fa fa-fw fa-square-o"></i>
                <i *ngIf="isOmitted(header.value,d)" aria-hidden="true" class="fa fa-check-square-o"></i>
                {{d}}
              </button>
            </div>
          </div>
        </div>
      </th>
      <th *ngIf="showOptions()" class="options d-print-none">Options</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let d of data | arrayFilter : omittedValues; let i = index" [class]="color(d)"
        [ngClass]="{'row-disabled':disableRow(i)}">
      <th *ngIf="showCount" scope="row">{{i + 1}}</th>
      <td *ngFor="let h of headers">
        <div *ngIf="!editRow(i)">
          <span *ngIf="h.dataType !== 1">{{d[h.value]}}</span>
          <span *ngIf="h.dataType === 1">{{d[h.value] | date: h.dateFormat}}</span>
        </div>
        <div *ngIf="editRow(i)">
          <input *ngIf="h.dataType === 0" [(ngModel)]="duped[h.value]" [name]="h.value" class="form-control"
                 type="text">
          <input *ngIf="h.dataType === 1" [(ngModel)]="duped[h.value]" [name]="h.value" class="form-control"
                 type="date">
          <input *ngIf="h.dataType === 2" [(ngModel)]="duped[h.value]" [name]="h.value" class="form-control"
                 type="number">
          <textarea *ngIf="h.dataType === 3" [(ngModel)]="duped[h.value]" [name]="h.value"
                    class="form-control"></textarea>
          <select *ngIf="h.dataType === 5" [(ngModel)]="duped[h.value]" [name]="h.value" class="form-control">
            <option *ngFor="let o of h.dropdownValues" [value]="o">{{o}}</option>
          </select>
        </div>
      </td>
      <td *ngIf="showOptions()" class="options d-print-none" style="white-space: nowrap">
        <div *ngIf="editting !== i">
          <button-edit (btnClick)="edit(d,i)" *ngIf="showEdit" btnClass="mr-1 btn-sm"></button-edit>
          <button-more (btnClick)="more(d)" *ngIf="showMore" btnClass="btn-sm"></button-more>
          <button-delete (btnClick)="del(d)" *ngIf="showDelete" btnClass="mr-1 btn-sm"></button-delete>
        </div>
        <div *ngIf="editting === i">
          <button-save (btnClick)="save(duped)" btnClass="mr-1 btn-sm"></button-save>
          <button-cancel (btnClick)="cancel()" btnClass="btn-sm"></button-cancel>
        </div>
      </td>
    </tr>
    </tbody>
    <!-- IF THE TABLE NEEDS TO ADD AS WELL -->
    <tfoot *ngIf="showAdd" [ngClass]="{'row-disabled':editting !== -1}" class="d-print-none">
    <tr>
      <th *ngIf="showCount" scope="row"></th>
      <td *ngFor="let h of headers">
        <input *ngIf="h.dataType === 0" [(ngModel)]="newValue[h.value]" [name]="h.value" class="form-control"
               type="text">
        <input *ngIf="h.dataType === 1" [(ngModel)]="newValue[h.value]" [name]="h.value" class="form-control"
               type="date">
        <input *ngIf="h.dataType === 2" [(ngModel)]="newValue[h.value]" [name]="h.value" class="form-control"
               type="number">
        <textarea *ngIf="h.dataType === 3" [(ngModel)]="newValue[h.value]" [name]="h.value"
                  class="form-control"></textarea>
        <select *ngIf="h.dataType === 5" [(ngModel)]="newValue[h.value]" [name]="h.value" class="form-control">
          <option *ngFor="let o of h.dropdownValues" [value]="o">{{o}}</option>
        </select>
      </td>
      <td *ngIf="showOptions()" class="options d-print-none" style="white-space: nowrap">
        <button-add (btnClick)="save(newValue)" btnClass="mr-1 btn-sm"></button-add>
        <button-cancel (btnClick)="cancel()" btnClass="btn-sm"></button-cancel>
      </td>
    </tr>
    </tfoot>
  </table>
</form>

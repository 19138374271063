import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from "@angular/core";
import {DistrictService} from "../../../services/district.service";
import {DistrictMeetingProblemDto, DistrictMeetingSubProblemDto} from "../../../common/dtos/districtMeetingProblemDto";
import {NgForm} from "@angular/forms";
import {Level, ToastService} from "../../../services/toast.service";

;

@Component({
  selector: 'module-meetings-problem',
  templateUrl: 'meetingProblem.component.html'
})
export class ModuleMeetingProblemComponent implements OnChanges {
  subProblemTypes = [
    {name: "String", value: "string"},
    {name: "Number", value: "number"},
  ];

  @ViewChild('problemForm') problemForm: NgForm;
  @Input() districtId: string;
  @Input() problem: DistrictMeetingProblemDto;
  @Output() problemChange: EventEmitter<DistrictMeetingProblemDto> = new EventEmitter();
  @Output() unclean: EventEmitter<boolean> = new EventEmitter();
  problemDelta: DistrictMeetingProblemDto;

  saveMeetingProblem() {
    this.problemDelta.type = "meeting_problem";
    this.districtService.patchMeetingProblems(this.districtId, this.problemDelta).subscribe(r => {
      this.notifier.AddNotification(Level.SUCCESS, "Meeting Problem Saved", "The meeting problem has been saved successfully");
      this.problem = r;
      this.problemDelta = r;
      this.problemChange.emit(r);
      this.problemForm.form.markAsUntouched();
      this.problemForm.form.markAsPristine();
    }, err => {
      this.notifier.AddNotification(Level.ERROR, "Meeting Problem Save Failed", "The meeting problem could not be saved");
    });
  }

  addSubProblem() {
    let subProblem = new DistrictMeetingSubProblemDto();
    subProblem.name = "New Sub Problem";
    subProblem.type = this.subProblemTypes[0].value;
    this.problemDelta.sub_problems = this.problemDelta.sub_problems || [];
    this.problemDelta.sub_problems.push(subProblem);
    this.problemForm.form.markAsDirty();
    this.problemForm.form.markAsTouched();
  }

  deleteSubProblem(index: number) {
    this.problemDelta.sub_problems.splice(index, 1);
    this.problemForm.form.markAsDirty();
    this.problemForm.form.markAsTouched();
  }

  resetMeetingProblem() {
    if(this.problemDelta.sub_problems.length > 0){
      this.problemDelta.sub_problems.splice(0, this.problemDelta.sub_problems.length);
    }
    this.problemDelta = {...this.problem}
    this.problemForm.form.markAsPristine();
    this.problemForm.form.markAsUntouched()
    this.cdRef.markForCheck();
  }

  constructor(private districtService: DistrictService, private cdRef: ChangeDetectorRef, private notifier: ToastService) {
  }

  ngOnChanges(): void {
    this.problemDelta = {...this.problem}
    // this.unclean.observers.splice(0, this.unclean.observers.length);
    // this.problemForm.valueChanges.subscribe(() => {
    //   this.unclean.emit(this.problemForm.dirty && this.problemForm.touched);
    // });
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AuthService} from '../../services/authService';
import {LoggingService} from '../../services/logging';

@Component({
  selector: 'app-token-view',
  templateUrl: './token-view.component.html',
  styleUrls: ['./token-view.component.scss']
})
export class TokenViewComponent implements OnInit {
  tokenExchange = false;
  status: string;

  isNegotiating = true;

  constructor(private activatedRoute: ActivatedRoute,
              private authService: AuthService,
              private router: Router,
              private log: LoggingService) {
  }

  ngOnInit(): void {
    this.status = '';
    // this.userService.isLoggedIn.subscribe(resp => {
    //   if (resp && this.tokenExchange) {
    //     this.status = 'Going to Home page';
    //     this.router.navigate(['/home']);
    //   }
    // });
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      const code = params['code'];
      const state = params['state'];
      this.status = 'Logging you in';

      this.authService.clear();

      this.authService.exchangeToken(code).subscribe(resp => {
        this.log.Log('received token');
        this.tokenExchange = true;
        this.router.navigate(['/home']);
      }, r => {
        console.log(r);
        if (r.status === 401) {
          this.status = 'Your account is not authorized to use SIDR';
          this.authService.clear();
        } else {
          this.status = 'An unknown error has occurred, please try again later';
        }
      });
    });
  }
}

<div class="d-flex flex-column">
  <div class="back_image back_image_one d-flex flex-column justify-content-center align-items-center">
    <div class="container d-flex flex-column welcome-bg">
      <h1>
        What is SIDR Online?
      </h1>
      <p class="lead">
        SIDR Online is a tool that unlike others, focuses on the student first. We help teachers and staff track
        assessments, interventions, meetings and more! We focus on a fast and easy to use interface, as we
        understand that a day in the job of an educator is busy.
      </p>
    </div>
  </div>
  <div class="down-arrow d-flex flex-column justify-content-center align-items-center">
    <h3><i class="fa fa-angle-down" aria-hidden="true"></i></h3>
  </div>
  <div class="back_image back_image_two d-flex flex-column justify-content-center align-items-center">
    <div class="container d-flex flex-column welcome-bg">
      <h1>
        What can it do for my district?
      </h1>
      <p class="lead">
        We provide a centralized interface that brings all the information entered in a quick and easy interface. By
        connecting students to teachers, they receive a easy interface to click on a student by their photo when
        uploaded. Staff can enter data that follows the student year to year. To find out more click <a [routerLink]="['sales']">here</a>
      </p>
    </div>
  </div>
  <div class="down-arrow d-flex flex-column justify-content-center align-items-center">
    <h3><i class="fa fa-angle-down" aria-hidden="true"></i></h3>
  </div>
  <div class="back_image back_image_three d-flex flex-column justify-content-center align-items-center">
    <div class="container d-flex flex-column welcome-bg">
      <h1>When to use it?</h1>
      <p class="lead">
        Anytime! Day or night, rain or shine. Other tools may be only accessible internally, but with SIDR you can
        access information at home or in school. Think of something in the middle of the night? You can log in and
        add a note to a student.

        But also, why should teachers reinvent the wheel every year. Data and advice can follow a student from each
        grade level. Track what worked best for one teacher so that teachers and staff can make that connection
        faster and sooner.
      </p>
    </div>
  </div>
</div>

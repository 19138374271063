import {Component, Input, OnChanges, OnInit} from '@angular/core';

import {StudentService} from '../../services/student.service';
import {ActivatedRoute} from '@angular/router';
import {Observation} from '../../models/student/observation.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StudentObservationDialogComponent} from './student.observationdialog.component';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";

@Component({
  selector: `student-observations`,
  templateUrl: 'student.observations.component.html'
})
export class StudentObservationsComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;
  observations: Observation[];

  ngOnChanges(): void {
    this.getData();
  }

  newObservation() {
    const m = this.modal.open(StudentObservationDialogComponent, {
      size: 'lg'
    });
    m.componentInstance.edit = true;
    m.componentInstance.observation = new Observation();
    m.componentInstance.studentId = this.studentId;
    m.componentInstance.districtId = this.districtId;
    m.result.then(() => {
      this.getData();
    }, () => {
      this.getData();
    });
  }

  open(i: Observation) {
    const m = this.modal.open(StudentObservationDialogComponent, {
      size: 'lg'
    });
    m.componentInstance.edit = false;
    m.componentInstance.observation = i;
    m.componentInstance.studentId = this.studentId;
    m.componentInstance.districtId = this.districtId;
    m.result.then(() => {
      this.getData();
    }, () => {
      this.getData();
    });
  }

  getData() {
    if (!this.districtId && !this.studentId) {
      return;
    }
    this.studentService.getObservations(this.districtId, this.studentId).subscribe(resp => {
      this.observations = resp || [];
      this.observations.sort((a: Observation, b: Observation) => {
        return a.date > b.date ? -1 : 1;
      });
    }, (resp) => {
      this.observations = [];
    });
  }

  constructor(private studentService: StudentService, private route: ActivatedRoute, private modal: NgbModal) {
  }
}

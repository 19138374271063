export class IRIPIntervention {
  intervention: string;
  implementer: string;
  how_often: string;
  start_date: Date;
  end_date: Date;
  result: string;
  constructor() {
    this.intervention = '';
    this.implementer = '';
    this.how_often = '';
    this.start_date = null;
    this.end_date = null;
    this.result = '';
  }
}

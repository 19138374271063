import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import { ToastService } from './services/toast.service';
import {AuthService} from "./services/authService";
import {ActivityService} from "./services/activity.service";

@Component({
  selector: 'app-root',
  templateUrl:'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  homeRoute: string[]
  isLoading: boolean;

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(x => {
      this.homeRoute = x ? ['/','home'] : ['/']
    });
  }

  goHome() {
    this.router.navigate(['/']);
  }

  constructor(private router: Router, public toastService: ToastService, private authService: AuthService, private activity: ActivityService) {
    this.activity.isLoading?.subscribe(x => {
      this.isLoading = x;
    });
  }
}

<div class="container">
  <div class="jumbotron d-flex flex-column" uploader #sidrUploader="SIDRUploader" (filesChangedEmitter)="onFilesChangedEmitter($event)">
    <h3>Upload Files Here</h3>
    <p>Drop files in this box to begin the upload. Progress will be shown below. For description of files, click <a
              routerLink="../importFiles">here</a>.</p>
  </div>
  <table class="table table-sm">
    <thead>
    <tr>
      <th>File</th>
      <th>Date</th>
      <th>Size</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let f of imports">
      <td>{{f.fileName}}</td>
      <td>{{f.uploadedOn | date:'medium'}}</td>
      <td nowrap>
        <ng-container *ngIf="f.size >= 1024 * 1024">
          {{ f.size / 1024 / 1024 | number:'.2' }} MB
        </ng-container>
        <ng-container *ngIf="f.size < 1024 * 1024 && f.size >= 1024">
          {{ f.size / 1024 | number:'.2' }} KB
        </ng-container>
        <ng-container *ngIf="f.size < 1024">
          {{ f.size }} B
        </ng-container>
      </td>
      <td>
        <span class="text-info" *ngIf="f.state === 'pending'">Uploaded. Awaiting processing</span>
        <span class="text-info" *ngIf="f.state === 'processing'">Processing.</span>
        <span class="text-danger" *ngIf="f.state == 'complete' && !f.success">A problem occurred processing this file</span>
        <span class="text-success" *ngIf="f.state == 'complete' && f.success">Successfully Processed.</span>
      </td>
    </tr>
    </tbody>
    <tfoot>
    <tr>
      <td colspan="3" class="text-right">
        <button class="btn btn-primary" (click)="goToFirstPage()" [disabled]="pageNumber === 0" aria-label="First page">
          <span class="fa fa-angle-double-left">&nbsp;</span>
        </button>
        <button class="btn btn-primary" (click)="prevPage()" [disabled]="pageNumber === 0" aria-label="Previous page">
          <span class="fa fa-chevron-left">&nbsp;</span>
        </button>
        <span class="mx-2 align-self-center">{{ pageNumber + 1 }} of {{ totalPages }}</span>
        <button class="btn btn-primary" (click)="nextPage()" [disabled]="pageNumber * pageSize >= totalImports" aria-label="Next page">
          <span class="fa fa-chevron-right">&nbsp;</span>
        </button>
        <button class="btn btn-primary" (click)="goToLastPage()" [disabled]="pageNumber * pageSize >= totalImports" aria-label="Last page">
          <span class="fa fa-angle-double-right">&nbsp;</span>
        </button>
      </td>
    </tr>
    </tfoot>
  </table>
</div>

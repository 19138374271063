<div class="grid-container">
  <div class="student-header">
    <nav class="navbar navbar-dark bg-primary d-print-none d-flex flex-row">
      <span class="navbar-brand pr-1">{{student.firstName}} {{student.lastName}}</span>
      <ul class="navbar-nav row d-flex flex-row">
        <li class="nav-item">
          <button class="btn btn-primary" [ngClass]="{active: selected === 'Information'}"
                  (click)="select('Information')">Information
          </button>
        </li>
        <div class="nav-item dropdown" ngbDropdown>
          <li class="nav-item">
            <button class="btn btn-primary" ngbDropdownToggle [ngClass]="{active: selected === 'data'}"
                    id="studentDropdown">Student
            </button>
          </li>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="studentDropdown">
            <button class="dropdown-item" (click)="select('data','Assessments')"
                    [ngClass]="{active: (selected === 'data') && subselect === 'Assessments'}">
              Assessments
            </button>
            <button class="dropdown-item" (click)="select('data','Attendance')"
                    [ngClass]="{active: (selected === 'data') && subselect === 'Attendance'}">
              Attendance
            </button>
            <button class="dropdown-item" (click)="select('data','Attendance by Period')"
                    [ngClass]="{active: (selected === 'data') && subselect === 'Attendance by Period'}">Attendance
              by
              Period
            </button>
            <button class="dropdown-item" (click)="select('data','Historical Behaviors')"
                    [ngClass]="{active: (selected === 'data' && subselect === 'Historical Behaviors')}">Historical
              Behaviors
            </button>
          </div>
        </div>
        <div class="nav-item dropdown" ngbDropdown>
          <li class="nav-item">
            <button class="btn btn-primary" ngbDropdownToggle [ngClass]="{active: selected === 'logs'}"
                    id="staffDropdown">Staff
            </button>
          </li>
          <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="staffDropdown">
            <button class="dropdown-item" (click)="select('logs','Achievements')"
                    [ngClass]="{active: (selected === 'logs') && subselect === 'Achievements'}">
              Achievements
            </button>
<!--            <button class="dropdown-item" (click)="select('logs','Appropriate Instruction')"-->
<!--                    [ngClass]="{active: (selected === 'logs' && subselect === 'Appropriate Instruction')}">Appropriate-->
<!--              Instruction-->
<!--            </button>-->
            <button class="dropdown-item" (click)="select('logs','Communication')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'Communication')}">Communication
            </button>
            <button class="dropdown-item" (click)="select('logs','Running Record')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'Running Record')}">Running
              Record
            </button>
            <button class="dropdown-item" (click)="select('logs','Behavior Interventions')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'Behavior Interventions')}">Behavior
              Interventions
            </button>
            <button class="dropdown-item" (click)="select('logs','Intervention Logs')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'Intervention Logs')}">Intervention
              Logs
            </button>
            <button class="dropdown-item" (click)="select('logs','IRIP')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'IRIP')}">IRIP
            </button>
            <button class="dropdown-item" (click)="select('logs','Observational Data')"
                    [ngClass]="{active: (selected === 'logs' && subselect === 'Observational Data')}">Observational
              Data
            </button>
            <button class="dropdown-item" (click)="select('logs','Other Factors')"
                    [ngClass]="{active: (selected === 'logs') && subselect === 'Other Factors'}">Other
              Factors
            </button>
          </div>
        </div>
        <li class="nav-item">
          <button class="btn btn-primary" [ngClass]="{active: selected === 'Meetings'}" (click)="select('Meetings')">
            Meetings
          </button>
        </li>
      </ul>
      <span class="mr-auto"></span>
      <student-print [options]="printOptions"></student-print>
    </nav>
  </div>
  <div class="student-photo">
    <ng-container *ngIf="student">
      <ng-container *ngIf="student.photoUrl && student.photoUrl.length > 0">
        <img [src]="student.photoUrl" class="student_image d-print-none img-thumbnail" (error)="student.photoUrl = null;">
      </ng-container>
      <ng-container *ngIf="!student.photoUrl || student.photoUrl.length == 0">
        <img src="assets/student_nophoto.png" class="student_image d-print-none img-thumbnail" style="opacity: 0.5">
      </ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="student">
    <div class="student-content">
      <app-student-information [(printOptions)]="printOptions"
                               [studentId]="student.id"
                               [districtId]="student.districtId"
                               [ngClass]="{'hide-view': hide('Information'), 'd-print-none':!printOptions.getValue('display.info')}"
                               class="student_page"></app-student-information>
      <app-student-assessments #assessments [(printOptions)]="printOptions"
                               [studentId]="student.id"
                               [districtId]="student.districtId"
                               [ngClass]="{'hide-view': hide('data','Assessments'),'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.assessments'))}"
                               class="student_page"></app-student-assessments>
      <app-student-achievements #achievements [(printOptions)]="printOptions"
                                [studentId]="student.id"
                                [districtId]="student.districtId"
                                [ngClass]="{'hide-view': hide('logs','Achievements'),'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.achievements'))}"
                                class="student_page"></app-student-achievements>
      <app-student-attendance [(printOptions)]="printOptions"
                              [studentId]="student.id"
                              [districtId]="student.districtId"
                              class="student_page"
                              [ngClass]="{'hide-view': hide('data','Attendance'),'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.attendance'))}"></app-student-attendance>
      <app-student-attendance-by-period [(printOptions)]="printOptions"
                                        [studentId]="student.id"
                                        [districtId]="student.districtId"
                                        class="student_page"
                                        [ngClass]="{'hide-view': hide('data','Attendance by Period'),'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.attendanceperiod'))}"></app-student-attendance-by-period>
      <student-historicalbehaviors [(printOptions)]="printOptions"
                                   [studentId]="student.id"
                                   [districtId]="student.districtId"
                                   class="student_page"
                                   [ngClass]="{'hide-view': hide('data','Historical Behaviors'),'d-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.behaviors'))}"></student-historicalbehaviors>
      <student-otherfactors #otherfactors [(printOptions)]="printOptions"
                            [studentId]="student.id"
                            [districtId]="student.districtId"
                            class="student_page"
                            [ngClass]="{'hide-view': hide('logs','Other Factors'), 'd-print-none': (!printOptions.getValue('display.student') || !printOptions.getValue('data.otherfactor'))}"></student-otherfactors>
      <student-communication [(printOptions)]="printOptions"
                             [studentId]="student.id"
                             [districtId]="student.districtId"
                             class="student_page"
                             [ngClass]="{'hide-view': hide('logs','Communication'),'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.communication'))}"></student-communication>
      <app-student-runningrecord #runningRecord [(printOptions)]="printOptions"
                                 [studentId]="student.id"
                                 [districtId]="student.districtId"
                                 class="student_page"
                                 [ngClass]="{'hide-view': hide('logs','Running Record'), 'd-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.runningrecord'))}"></app-student-runningrecord>
      <student-bhvrintv #bhvrintv [(printOptions)]="printOptions"
                        [studentId]="student.id"
                        [districtId]="student.districtId"
                        class="student_page"
                        [ngClass]="{'hide-view': hide('logs','Behavior Interventions'),'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.bhvrintv'))}"></student-bhvrintv>
      <student-intvlog [(printOptions)]="printOptions"
                       [studentId]="student.id"
                       [districtId]="student.districtId"
                       class="student_page"
                       [ngClass]="{'hide-view': hide('logs','Intervention Logs'),'d-print-none':(!printOptions.getValue('display.staff') || !printOptions.getValue('staff.intvlog'))}"></student-intvlog>
      <app-student-irip-list [(printOptions)]="printOptions"
                             [studentId]="student.id"
                             [districtId]="student.districtId"
                             class="student_page"
                             [ngClass]="{'hide-view': hide('logs','IRIP'),'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.irip'))}"></app-student-irip-list>
<!--      <app-student-appropriate-instruction [(printOptions)]="printOptions"-->
<!--                                           [studentId]="student.id"-->
<!--                                           [districtId]="student.districtId"-->
<!--                                           class="student_page"-->
<!--                                           [ngClass]="{'hide-view': hide('logs','Appropriate Instruction'),'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.appr_inst'))}"></app-student-appropriate-instruction>-->
      <student-observations [(printOptions)]="printOptions"
                            [studentId]="student.id"
                            [districtId]="student.districtId"
                            class="student_page"
                            [ngClass]="{'hide-view': hide('logs','Observational Data'),'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.observations'))}"></student-observations>
      <student-meetings [(printOptions)]="printOptions"
                        [studentId]="student.id"
                        [districtId]="student.districtId"
                        [ngClass]="{'hide-view': hide('Meetings'),'d-print-none': (!printOptions.getValue('display.meetings'))}"
                        class="student_page"></student-meetings>
    </div>
  </ng-container>

</div>





import {Component, OnInit} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StaffDialogComponent} from '../../staff/staffDialog/staffDialog.component';
import {Role} from '../../models/district/role.model';
import {StaffService} from '../../services/staff.service';
import {Staff} from '../../models/staff.model';
import {TitleService} from '../../services/title.service';
import {DistrictBuildingDto} from '../../models/district/districtBuildingDto';
import {StaffDto} from '../../common/dtos/staffDto';

@Component({
  selector: `district-staff`,
  templateUrl: `staff.component.html`
})
export class DistrictStaffComponent implements OnInit {
  active: string;
  suspended: boolean;
  buildingId: string;
  districtId: string;

  buildings: DistrictBuildingDto[];
  staff: StaffDto[];
  roles: Role[];

  selectBuilding(i: number) {
    if (i === this.buildings.length) {
      this.buildingId = 'unsorted';
      this.getData('unsorted');
    } else {
      this.buildingId = this.buildings[i].id;
      this.getData(this.buildings[i].id);
    }
  }

  ngOnInit(): void {
    this.suspended = false;
    this.buildingId = '';
    this.buildings = [];
    this.staff = [];

    this.activeRoute.parent.params.subscribe(p => {
      if (p['districtId'] == undefined || p['districtId'] == null) {
        return;
      }

      this.districtId = p['districtId'];
      this.getData();
    });
    this.titleService.setTitle('District Staff');
  }

  openStaff(s: Staff) {
    const m = this.modal.open(StaffDialogComponent, {
      size: 'lg'
    });
    m.componentInstance.staff = s;
    m.componentInstance.buildings = this.buildings;
    m.componentInstance.roles = this.roles;
    m.componentInstance.admin = true;
  }

  gotoStaff(s: StaffDto) {
    this.route.navigate(['/staff/' + s.id]);
  }

  getData(buildingId?: string) {
    if (buildingId) {
      this.districtService.getBuildingStaff(this.districtId, buildingId).subscribe(r => {
        this.staff = r || [];
      });
    } else {
      this.districtService.getBuildings(this.districtId).subscribe(r => {
        this.buildings = r || [];
        console.log('this');
        this.buildings = this.buildings.filter(x => !x.disabled);
        this.buildings.sort((a, b) => (a.name > b.name) ? 1 : -1);
        this.buildings.push({
          disabled: false,
          name: 'Unsorted',
          id: 'unsorted',
          code: 'unsorted',
          admins: [],
          phone: '',
          address: '',
          city: '',
          state: '',
          zip: ''
        });
      });

      this.districtService.getRoles(this.districtId).subscribe(r => {
        this.roles = r;
      });
    }
  }

  constructor(
    private districtService: DistrictService,
    private staffService: StaffService,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private modal: NgbModal,
    private titleService: TitleService) {

  }
}

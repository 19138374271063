import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable()
export class ActivityService {
  public isLoading: BehaviorSubject<boolean>;
  public requests: BehaviorSubject<number>;

  constructor() {
    this.isLoading = new BehaviorSubject<boolean>(false);
    this.requests = new BehaviorSubject<number>(0);
  }
}

import {DistrictTypeDto} from "./districtTypeDto";

export class DistrictMeetingSubProblemDto {
  name: string;
  type: string;
}

export class DistrictMeetingProblemDto implements DistrictTypeDto {
  id: string;
  districtId: string;
  name: string;
  type: string;
  sub_problems: DistrictMeetingSubProblemDto[];
}

export class Student {
  id: string;
  districtId: string;
  buildingId: string;
  uic: number;
  active: boolean;
  schoolId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  gender: string;
  photoUrl: string;
  ethnicity: string;
  enrollment_date: string;
  grade: string;
  phone: string;

}


import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {DistrictService} from '../../../services/district.service';
import {ActivatedRoute} from '@angular/router';
import {IDirty} from '../../../services/canDeactivate.service';
import {Animations} from '../../../common/animations/animations';
import {DistrictTypeDto} from '../../../common/dtos/districtTypeDto';
import {TitleService} from '../../../services/title.service';
import {Level, ToastService} from '../../../services/toast.service';
import {TypesService} from '../../../services/types.service';
import {LoggingService} from '../../../services/logging';

@Component({
  selector: `module-otherTypes`,
  templateUrl: 'moduleBasicTypes.component.html',
  animations: [
    Animations.validationFadeInOut
  ]
})
export class ModuleBasicTypesComponent implements OnInit, IDirty {
  @Input() districtId: string;
  @ViewChild('basicTypeForm') form: NgForm;
  @ViewChild('patchForm') patchForm: NgForm;

  title: string;
  description: string;
  basicType: string;
  placeholderText: string;
  requiredText: string;
  minLengthText: string;
  minLength: number;
  defaults: string[];

  editing: string | null;
  value: string;
  types: DistrictTypeDto[];
  editValue: DistrictTypeDto | null;

  constructor(private districtService: DistrictService,
              private activeRoute: ActivatedRoute,
              private titleService: TitleService,
              private toastService: ToastService,
              private typesService: TypesService,
              private log: LoggingService) {
    this.value = '';
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (p['districtId'] === undefined || p['districtId'] == null) {
        return;
      }

      this.districtId = p['districtId'];

    });
    this.activeRoute.data.subscribe(p => {
      console.log(p);
      this.title = p.title;
      this.description = p.description;
      this.basicType = p.basicType;
      this.placeholderText = p.placeholderText;
      this.requiredText = p.requiredText;
      this.minLengthText = p.minLengthText;
      this.defaults = p.defaults;
      if (p.minLength != null && p.minLength !== undefined) {
        this.minLength = p.minLength;
      } else {
        this.minLength = 3;
      }
      console.log(p);
      this.titleService.setTitle(`Module - ${this.title}`);
      this.getData();
    });
  }

  edit(t: DistrictTypeDto) {
    this.editing = t.id;
    this.editValue = Object.assign({}, t);
  }

  cancel(index: number) {
    console.log(index);
    this.editing = null;
    this.types[index] = Object.assign({}, this.editValue);
    this.editValue = null;
  }

  delete(t: DistrictTypeDto) {
    const index = this.types.indexOf(t);
    this.districtService.deleteBasicType(this.districtId, this.basicType, t.id).subscribe(r => {
      // Remove it and get fresh data
      this.types.splice(index, 1);
      this.getData();
    }, err => {
      // Basic error handle
      this.toastService.AddNotification(Level.ERROR, this.title, 'Failed to delete data');
    });
  }

  patch(f: NgForm, type: DistrictTypeDto, index: number) {
    if (f.form.invalid) {
      return;
    }

    this.form.form.disable();
    f.form.disable();
    this.districtService.patchBasicType(this.districtId, this.basicType, type.id, type).subscribe(r => {
      this.form.form.enable();
      f.form.enable();
      this.types[index] = r;
      this.editValue = null;
      this.editing = null;
    }, err => {
      this.form.form.enable();
      f.form.enable();
      this.toastService.AddNotification(Level.ERROR, this.title, 'Failed to save data');
    });
  }

  save() {
    if (!this.form.valid) {
      this.log.Log(`form is ${this.form.valid ? 'valid' : 'invalid'}`);
      return;
    }

    const dType: DistrictTypeDto = {
      name: this.value,
      districtId: this.districtId,
      id: ''
    };

    this.form.form.disable();
    this.districtService.postBasicType(this.districtId, this.basicType, dType).subscribe(r => {
      // Add the data, and then get fresh data
      this.types.push(r);
      this.form.reset();
      this.form.form.enable();
      this.toastService.AddNotification(Level.SUCCESS, this.title, 'Data saved successfully');
    }, () => {
      // Basic error handle
      this.form.form.enable();
      this.toastService.AddNotification(Level.ERROR, this.title, 'Failed to save data');
    });
  }

  getData() {
    this.typesService.getBasicType(this.districtId, this.basicType).subscribe(r => {
      this.types = r || [];
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.title, 'Failed to retrieve data');
    });
  }

  isDirty(): boolean {
    return this.form.dirty || (this.editing != null && this.patchForm.dirty);
  }

  addDefaults() {
    let promises = this.defaults.map(d => {
      if (this.types.find(t => t.name === d)) {
        // If the type already exists, return a resolved promise
        return Promise.resolve();
      }

      const dType: DistrictTypeDto = {
        name: d,
        districtId: this.districtId,
        id: ''
      };

      this.districtService.postBasicType(this.districtId, this.basicType, dType).subscribe(r => {
        // Add the data, and then get fresh data
        this.types.push(r);
      }, () => {
        // Basic error handle
        this.toastService.AddNotification(Level.ERROR, this.title, 'Failed to save data');
      });
    });

    Promise.all(promises).then(() => {
      this.toastService.AddNotification(Level.SUCCESS, this.title, 'Data saved successfully');
    });
  }
}

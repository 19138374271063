import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: `button-more`,
  template: `
    <button class="btn btn-info d-print-none {{btnClass}}" (click)="onClick()" type="button" [disabled]="disabled"
            aria-label="More" ngbTooltip="More"><i class="fas fa-search"></i> {{label}}
    </button>    `
})
export class MorebuttonComponent {
  @Output() btnClick = new EventEmitter();
  @Input() disabled = false;
  @Input() btnClass = '';
  @Input() label = '';

  onClick(): void {
    this.btnClick.emit();
  }
}

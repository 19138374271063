import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: `button-edit`,
  template: `
    <button class="btn btn-warning {{btnClass}}" [type]="type" [disabled]="disabled" aria-label="Edit"
            (click)="onClick()" ngbTooltip="Edit">
      <i class="fa fa-pencil" aria-hidden="true"></i> {{label}}
    </button>
  `
})
export class EditbuttonComponent {
  @Output() btnClick = new EventEmitter();
  @Input() disabled = false;
  @Input() type = 'button';
  @Input() btnClass = '';
  @Input() label = '';

  onClick(): void {
    this.btnClick.emit();
  }
}

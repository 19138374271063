import {Component, Input, ViewChild} from '@angular/core';
import {Staff} from '../../models/staff.model';
import {StaffService} from '../../services/staff.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Building} from '../../models/district/building.model';
import {Role} from '../../models/district/role.model';
import {NgForm} from '@angular/forms';
import {StaffDto} from "../../common/dtos/staffDto";

@Component({
  selector: `staff-dialog`,
  template: `
    <form #staffDialog="ngForm" *ngIf="staff !== null">
      <div class="modal-header">
        <h3 class="modal-title">Staff Information</h3>
        <button-close (btnClick)="close()"></button-close>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col">Firstname</div>
          <div class="col">Lastname</div>
        </div>
        <div class="row">
          <div class="col"><input class="form-control" name="firstname" [(ngModel)]="staff.firstName"></div>
          <div class="col"><input class="form-control" name="lastname" [(ngModel)]="staff.lastName"></div>
        </div>
        <div class="row">
          <div class="col" *ngIf="admin">E-Mail</div>
          <div class="col">Title</div>
        </div>
        <div class="row">
          <div class="col" *ngIf="admin"><input class="form-control" name="email" [(ngModel)]="staff.email"></div>
          <div class="col"><input class="form-control" name="title" [(ngModel)]="staff.title"></div>
        </div>
        <div class="row" *ngIf="admin">
          <div class="col">Suspended</div>
          <div class="col">&nbsp;</div>
        </div>
        <div class="row" *ngIf="admin">
          <div class="col">
            <button class="btn col" type="button"
                    [ngClass]="{'btn-success':!staff.suspended, 'btn-danger' : staff.suspended}"
                    (click)="staff.suspended = !staff.suspended">
              {{staff.suspended ? 'Suspended' : 'Active' }}
            </button>
          </div>
          <div class="col"></div>
        </div>
        <div class="row" *ngIf="admin">
          <div class="col">Buildings</div>
          <div class="col">Roles</div>
        </div>
        <div class="row" *ngIf="admin">
          <building-toggle ngDefaultControl [ngForm]="staffDialog" class="col" name="building"
                           [(ngModel)]="staff.buildings" [buildings]="buildings"></building-toggle>
          <role-toggle ngDefaultControl [ngForm]="staffDialog" class="col" name="roles" [(ngModel)]="staff.roles"
                       [roles]="roles"></role-toggle>
        </div>
      </div>
      <div class="modal-footer">
        <button-save label="Save" type="submit" (btnClick)="submit(staffDialog)"
                     [disabled]="!staffDialog.form.dirty"></button-save>
        <button-close label="Cancel" (btnClick)="close()"></button-close>
      </div>
    </form>
  `
})
export class StaffDialogComponent {
  @Input() staff: StaffDto;
  @Input() buildings: Building[] = [];
  @Input() roles: Role[] = [];
  @ViewChild(NgForm) form: NgForm;
  @Input() admin: boolean;

  constructor(private staffService: StaffService, private modal: NgbActiveModal) {
  }

  close() {
    this.modal.close();
  }

  submit(f: NgForm) {
    this.staffService.patch(this.staff).subscribe(r => {
      this.staff = r;
      this.modal.close();
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MeetingsStrCon} from '../../models/student/strcon.model';
import {Meeting} from '../../models/student/meeting.model';

@Component({
  selector: `meeting-strcons`,
  template: `
    <h3 class="d-none d-print-block">Information</h3>
    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Strength</th>
        <th>Concern</th>
      </tr>
      </thead>
      <tbody>
        <tr *ngFor="let sc of meeting.strcons">
          <td>{{sc.name}}</td>
          <td *ngIf="!edit">{{sc.strength | date : 'shortDate' | blankDate}}</td>
          <td *ngIf="!edit">{{sc.concern | date : 'shortDate' | blankDate}}</td>
          <td *ngIf="edit"><input type="date" class="form-control" name="{{sc.name + 'str'}}" [(ngModel)]="sc.strength" (ngModelChange)="change()"></td>
          <td *ngIf="edit"><input type="date" class="form-control" name="{{sc.name + 'con'}}" [(ngModel)]="sc.concern" (ngModelChange)="change()"></td>
        </tr>
      </tbody>
    </table>
  `
})
export class StudentMeetingStrconsComponents {
  @Input() meeting: Meeting;
  @Output() meetingChange = new EventEmitter<Meeting>();
  @Input() strcons: MeetingsStrCon[];
  @Input() edit: boolean;
  @Output() editChange = new EventEmitter<boolean>();

  change() {
    this.meetingChange.emit(this.meeting);
  }
}

<form #intvlogForm="ngForm" (ngSubmit)="save(intvlogForm)">
  <div class="modal-header">
    <h4 class="modal-title">Intervention Log</h4>
    <button-close (btnClick)="close()" btnClass="pull-right btn-outline-primary"></button-close>
  </div>
  <div class="modal-body d-flex flex-column">
    <table class="table">
      <tbody>
      <tr>
        <td>Date Notified</td>
        <td *ngIf="!edit">{{log.date | date: 'shortDate'}}</td>
        <td *ngIf="edit">
          <input [(ngModel)]="log.date" class="form-control" name="date" required type="date">
        </td>
      </tr>
      <tr>
        <td>Implementer</td>
        <td *ngIf="!edit">{{log.implementer | staffId | async}}{{log.legacy}}</td>
        <td *ngIf="edit">
          <select [(ngModel)]="log.implementer" class="custom-select" name="implementer">
            <option *ngFor="let s of staff" [ngValue]="s?.id">{{s.displayName}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Intervention</td>
        <td *ngIf="!edit">{{log.intervention}}</td>
        <td *ngIf="edit">
          <select [(ngModel)]="log.intervention" class="custom-select" name="intervention" required>
            <option *ngFor="let i of interventions" [ngValue]="i.name">{{i.name}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Tier</td>
        <td *ngIf="!edit">{{log.tier}}</td>
        <td *ngIf="edit">
          <select [(ngModel)]="log.tier" class="custom-select" name="tier" required>
            <option *ngFor="let t of tiers" [ngValue]="t.name">{{t.name}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Start</td>
        <td *ngIf="!edit">{{log.start | date: 'shortDate' | blankDate}}</td>
        <td *ngIf="edit">
          <input [(ngModel)]="log.start" class="form-control" name="start" required type="date">
        </td>
      </tr>
      <tr>
        <td>End</td>
        <td *ngIf="!edit">{{log.end | date: 'shortDate' | blankDate}}</td>
        <td *ngIf="edit">
          <input [(ngModel)]="log.end" class="form-control" name="end" required type="date">
        </td>
      </tr>
      <tr>
        <td>Performance</td>
        <td *ngIf="!edit">{{log.performance}}</td>
        <td *ngIf="edit">
          <textarea [(ngModel)]="log.performance" class="form-control" name="performance"></textarea>
        </td>
      </tr>
      <tr>
        <td>Days Per Week</td>
        <td *ngIf="!edit">{{log.days_per_week}}</td>
        <td *ngIf="edit">
          <input [(ngModel)]="log.days_per_week" class="form-control" name="days_per_week" type="number">
        </td>
      </tr>
      <tr>
        <td>Minutes Per Day</td>
        <td *ngIf="!edit">{{log.min_per_day}}</td>
        <td *ngIf="edit"><input [(ngModel)]="log.min_per_day" class="form-control" name="min_per_day" type="number">
        </td>
      </tr>
      <tr>
        <td>Other</td>
        <td *ngIf="!edit">{{log.other}}</td>
        <td *ngIf="edit">
          <textarea [(ngModel)]="log.other" class="form-control" name="other"></textarea>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
  <div class="modal-footer">
    <button-edit (btnClick)="edit = true" *ngIf="!edit" btnClass="pull-right" label="Edit"></button-edit>
    <button-save (btnClick)="save(intvlogForm)" *ngIf="edit" btnClass="pull-right" label="Save"></button-save>
    <button-close (btnClick)="close()" btnClass="pull-right" label="Close"></button-close>
  </div>
</form>

import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { StudentService } from 'src/app/services/student.service';
import {TitleService} from "../../services/title.service";

@Component({
  selector: 'app-home-view',
  templateUrl: './home-view.component.html',
  styleUrls: ['./home-view.component.scss']
})
export class HomeViewComponent {
  hasStudents = false;

  constructor(private userService: UserService, private studentService: StudentService, private titleService: TitleService) {
    this.titleService.setTitle("Home")
  }
}

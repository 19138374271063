
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError,  Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { District } from '../models/district/district.model';
import { MailResponse, Message } from '../models/gmail/gmail.models';
import { Staff } from '../models/staff.model';
import {AuthService} from "./authService";

@Injectable()
export class AdminService implements CanActivate {
  private baseRoute = '/api/admin/';
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.tokenService.parseJwt().sidrAdmin;
  }

  public getDistrict(id: string): Observable<District> {
    return this.http.get<District>(this.baseRoute + 'district/' + id);
  }

  public getDistricts(): Observable<District[]> {
    return this.http.get<District[]>(this.baseRoute + 'districts');
  }

  public getMailRedirect() {
    return this.http.get<any>(this.baseRoute + 'services/mail/redirect');
  }

  public getMailCode(code: string): Observable<any> {
    return this.http.get<any>(this.baseRoute + 'services/mail/code?code=' + encodeURIComponent(code));
  }

  public getMailMessages(): Observable<MailResponse> {
    return this.http.get<any>(this.baseRoute + 'services/mail/messages');
  }

  public getMailMessage(id: string): Observable<Message> {
    return this.http.get<any>(this.baseRoute + 'services/mail/message/' + id);
  }

  protected handleError(error: Response) {
    return observableThrowError(error || 'Server error');
  }

  constructor(private http: HttpClient, private userService: UserService, private tokenService: AuthService) {}
}

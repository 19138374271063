<ng-container *ngIf="visible">
  <div class="card border-dark">
    <div class="card-header d-flex justify-content-between">
      Upload progress {{ overallProgress | number:'1.0-0' }}%
      <button type="button" class="btn btn-link btn-sm btn-outline-dark" (click)="toggleExpandOrClose()">
        <span class="fa" [ngClass]="{'fa-expand': !allFilesUploaded, 'fa-window-close': allFilesUploaded}">&nbsp;</span>
      </button>
    </div>
    <div class="card-body" *ngIf="expanded">
      <table class="table table-sm">
        <thead>
        <tr>
          <th>File Name</th>
          <th>Progress</th>
          <th>&nbsp;</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of paginatedFiles">
          <td>{{ file.file.name }}</td>
          <td>{{ file.progress }}%</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td colspan="3" class="text-right">

          </td>
        </tr>
        </tfoot>
      </table>
    </div>
    <div class="card-footer d-flex" *ngIf="expanded">
      <button class="btn btn-primary" (click)="prevPage()" [disabled]="currentPage === 1" aria-label="Previous page">
        <span class="fa fa-chevron-left">&nbsp;</span>
      </button>
      <span class="mx-2 align-self-center">{{ importService.uploads.length }} files</span>
      <button class="btn btn-primary ml-auto" (click)="nextPage()" [disabled]="currentPage * itemsPerPage >= importService.uploads.length" aria-label="Next page">
        <span class="fa fa-chevron-right">&nbsp;</span>
      </button>
    </div>
  </div>
</ng-container>

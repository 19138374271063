<h3>District Contact</h3>
<p>This is the information that will be used for when a staff member is having an issue for in district support.</p>
<ng-container *ngIf="contact">
  <form ngForm #contactForm="ngForm" (ngSubmit)="save(contactForm)">
    <div class="form-group">
      <label for="districtContactName">Contact Name</label>
      <input class="form-control" type="text" id="districtContactName" name="name" [(ngModel)]="contact.name" placeholder="Contact Name" required>
    </div>
    <div class="form-group">
      <label for="districtContactEmail">Contact E-Mail</label>
      <input class="form-control" type="email" id="districtContactEmail" name="email" [(ngModel)]="contact.email" placeholder="Contact E-Mail" required>
    </div>
    <div class="form-group">
      <label for="districtContactNumber">Contact Number</label>
      <input class="form-control" type="tel" id="districtContactNumber" name="number" [(ngModel)]="contact.number" placeholder="Contact Phone Number">
    </div>
    <div class="form-group row">
      <button label="Save"
              [disabled]="!contactForm.form.dirty"
              (click)="save(contactForm)"
              class="btn btn-success pull-right ml-auto"
              type="button">Save
      </button>
    </div>
  </form>
</ng-container>

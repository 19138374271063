<p class="text-center">{{description}}</p>
<ng-container *ngIf="defaults">
  <div class="row">
    <button class="btn btn-outline-info ml-auto mb-1 mr-3" (click)="addDefaults()">Create defaults</button>
  </div>
</ng-container>
<ng-container *ngFor="let type of types; index as i">
  <ng-container *ngIf="editing == type.id">
    <form #patchForm="ngForm" novalidate (ngSubmit)="patch(patchForm, type, i)">
      <div class="form-group mb-2">
        <div class="input-group">
          <input type="text" [readonly]="editing == null && editing != type.id" #update class="form-control"
                 [name]="type.id" [(ngModel)]="type.name" minlength="3" required>
          <div class="input-group-append">
            <button class="btn btn-warning" type="button" (click)="cancel(i)" ngbTooltip="Cancel">
              <i class="fas fa-ban"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button class="btn btn-success" type="button" (click)="patch(patchForm, type, i)" ngbTooltip="Save"
                    [disabled]="patchForm.form.invalid || update.length < 3">
              <i class="fas fa-save"></i>
            </button>
          </div>


          <ng-container *ngIf="editing != type.id">
            <div class="input-group-append">
              <button class="btn btn-warning" type="button" (click)="edit(type)" [disabled]="editing != null"
                      ngbTooltip="Edit">
                <i class="fas fa-pencil-alt"></i>
              </button>
            </div>
            <div class="input-group-append">
              <button class="btn btn-danger" type="button" (click)="delete(type)" [disabled]="editing != null"
                      ngbTooltip="Delete">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="editing == null || editing != type.id">
    <div class="form-group mb-2">
      <div class="input-group">
        <input type="text" readonly class="form-control" [name]="type.id" [(ngModel)]="type.name" [minlength]="minLength" required>
        <div class="input-group-append">
          <button class="btn btn-warning" type="button" (click)="edit(type)" [disabled]="editing != null"
                  ngbTooltip="Edit">
            <i class="fas fa-pencil-alt"></i>
          </button>
        </div>
        <div class="input-group-append">
          <button class="btn btn-danger" type="button" (click)="delete(type)" [disabled]="editing != null"
                  ngbTooltip="Delete">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<form #basicTypeForm="ngForm" novalidate (ngSubmit)="save()">
  <div class="form-group">
    <div *ngIf="newValue.invalid && (newValue.dirty || newValue.touched)">
      <span [hidden]="newValue.errors?.required">{{reuiredText}}</span>
      <span [hidden]="newValue.errors?.minlength">{{minLengthText}}</span>
    </div>
    <div *ngIf="!(newValue.invalid && (newValue.dirty || newValue.touched))">
      <span>&nbsp;</span>
    </div>
    <div class="input-group">
      <input type="text" #newValue="ngModel" [(ngModel)]="value" name="newValue" [minlength]="minLength" class="form-control"
             [disabled]="editing != null"
             [placeholder]="placeholderText" required>
      <div class="input-group-append">
        <button class="btn btn-success" type="button" (click)="save()"
                [disabled]="basicTypeForm.form.invalid || value.length < minLength" ngbTooltip="Add new type">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</form>


export class OtherFactor {
  id: string;
  date: Date;
  type: string;
  desc: string;

  static createFrom(source: any) {
    const result = new OtherFactor();
    result.id = source['id'];
    result.date = new Date(source['date']);
    result.type = source['type'];
    result.desc = source['desc'];
    return result;
  }

  constructor() {
    this.date = new Date();
    this.type = '';
    this.desc = '';
  }
}

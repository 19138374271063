/**
 * Created by ptdave on 6/25/17.
 */

import {Pipe, PipeTransform} from '@angular/core';
import {StaffService} from '../../services/staff.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'blankDate'
})
export class BlankDatePipePipe implements PipeTransform {
  private output: Observable<string>;

  constructor(private staffService: StaffService) {

  }
  transform(value: string, ...args: any[]): string {
    if (!value) {
      return '';
    }
    if (value === '12/31/1900') {
      return '';
    }
    return value;
  }
}

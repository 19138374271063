<form #staffForm="ngForm">
  <div class="d-flex flex-row" *ngIf="staff != null">
    <div class="hidden-md-down col-2">
      <img class="img-thumbnail mb-1" *ngIf="staff?.photoUrl != null" [src]="staff?.photoUrl" style="width: 100%">
      <button-edit label="Edit" (btnClick)="editStaff()" btnClass="btn-block" requireRole="can_admin,modify_staff"
                   *ngIf="!edit"></button-edit>
      <button-save label="Save" *ngIf="edit" btnClass="btn-block mb-1"
                   [disabled]="!staffForm.form.dirty" (btnClick)="save()"></button-save>
      <button-close label="Cancel" *ngIf="edit" btnClass="btn-block mb-1" (btnClick)="cancel()"></button-close>
      <button class="btn btn-primary btn-block mb-1" type="button" (click)="impersonate()" roles="can_impersonate">
        <i class="fas fa-user-secret"></i> Impersonate
      </button>
    </div>
    <div class="col">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <div class="row mt-2">
              <div class="col-2">First name</div>
              <div class="col" *ngIf="!edit">{{staff.firstName}}</div>
              <div class="col" *ngIf="edit"><input type="text" class="form-control" name="firstName"
                                                   [(ngModel)]="staff.firstName">
              </div>
              <div class="col-2">Last name</div>
              <div class="col" *ngIf="!edit">{{staff.lastName}}</div>
              <div class="col" *ngIf="edit"><input type="text" class="form-control" name="lastName"
                                                   [(ngModel)]="staff.lastName">
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-2">E-Mail</div>
              <div class="col" *ngIf="!edit">{{staff.email}}</div>
              <div class="col" *ngIf="edit"><input type="text" class="form-control" name="email"
                                                   [(ngModel)]="staff.email"></div>
              <div class="col-2">Title</div>
              <div class="col" *ngIf="!edit">{{staff.title}}</div>
              <div class="col" *ngIf="edit"><input type="text" class="form-control" name="title"
                                                   [(ngModel)]="staff.title"></div>
            </div>
            <div class="row mt-2">
              <div class="col-2">Status</div>
              <div class="col" *ngIf="!edit">
                    <span class="badge  badge-pill"
                          [ngClass]="{'badge-success':!staff?.disabled, 'badge-danger':staff?.disabled}">
                        {{staff?.disabled ? 'Disabled' : 'Active'}}
                    </span>
              </div>
              <div class="col" *ngIf="edit">
                <button class="btn" type="button"
                        (click)="toggleEnableDisabled()"
                        [ngClass]="{'btn-success':!staff.disabled, 'btn-danger' : staff.disabled}">
                  {{staff.disabled ? 'Disabled' : 'Active' }}
                </button>
              </div>
              <div class="row" *ngIf="admin">
                <div class="col">Buildings</div>
                <div class="col">Roles</div>
              </div>
              <div class="row" *ngIf="admin">
                <building-toggle ngDefaultControl [ngForm]="staffDialog" class="col" name="building"
                                 [(ngModel)]="staff.buildings" [buildings]="buildings"></building-toggle>
                <role-toggle ngDefaultControl [ngForm]="staffDialog" class="col" name="roles"
                             [(ngModel)]="staff.roles" [roles]="roles"></role-toggle>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>Buildings</a>
          <ng-template ngbNavContent>
            <p>
              This manages the buildings a staff member is attached to. This is particularly important for staff members
              who travel between buildings that are not directly attached to a student.
            </p>
            <div class="d-flex flex-wrap">
              <ng-container *ngFor="let building of buildings">
                <div class="col-md-4 mb-2">
                  <button type="button" [disabled]="!edit" class="btn btn-block"
                          [ngClass]="{'btn-success':hasBuilding(building.id),'btn-outline-secondary':!hasBuilding(building.id)}"
                          (click)="toggleBuilding(building.id)">{{building.name}}
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>Students</a>
          <ng-template ngbNavContent>
            <div class="input-group" *ngIf="edit">
              <input name="school_id" class="form-control" placeholder="Enter school id to add a student"
                     [(ngModel)]="school_id">
              <div class="input-group-append">
                <button-add (btnClick)="addStudent(school_id)"></button-add>
              </div>

            </div>
            <table class="table table-sm table-striped">
              <thead>
              <tr>
                <td>Lastname</td>
                <td>Firstname</td>
                <td>Grade</td>
                <td>School Id</td>
                <td *ngIf="edit"></td>
              </tr>
              </thead>
              <tbody>
              <tr class="mb-1" *ngFor="let s of students">
                <td>{{s.lastName}}</td>
                <td>{{s.firstName}}</td>
                <td>{{s.grade}}</td>
                <td>{{s.schoolId}}</td>
                <td *ngIf="edit">
                  <button-delete btnClass="btn-sm pull-right" [confirm]="false" (btnClick)="removeStudent(s.id)"
                                 label="Remove"></button-delete>
                </td>
              </tr>
              </tbody>
            </table>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a ngbNavLink>Roles</a>
          <ng-template ngbNavContent>
            <p>Roles give extra functionality to staff members in SIDR.</p>
            <div class="d-flex flex-wrap">
              <ng-container *ngFor="let role of roles">
                <div class="col-md-4 mb-2">
                  <button type="button" [disabled]="!edit" class="btn btn-block"
                          [ngClass]="{'btn-success':hasRole(role.id),'btn-outline-secondary':!hasRole(role.id)}"
                          (click)="toggleRole(role.id)">{{role.name}}
                  </button>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</form>

import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {DistrictBuildingDto} from "../../models/district/districtBuildingDto";
import {StaffDto} from "../../common/dtos/staffDto";
import {AuthService} from "../../services/authService";
import {StudentDto} from "../../common/dtos/studentDto";
import {Role} from "../../models/district/role.model";
import {DistrictService} from "../../services/district.service";
import {StaffService} from "../../services/staff.service";
import {RoleDto} from "../../common/dtos/roleDto";
import {IDirty} from "../../services/canDeactivate.service";
import {Level, ToastService} from "../../services/toast.service";
import {LoggingService} from "../../services/logging";

@Component({
  selector: 'app-staff-view',
  templateUrl: './staffPage.component.html'
})
export class StaffPageComponent implements OnInit, IDirty {
  title: string = "Staff Edit"

  edit: boolean;
  canEdit: boolean;

  staffId: string;
  staff: StaffDto;
  students: StudentDto[];

  school_id: string;
  buildings: DistrictBuildingDto[];
  roles: RoleDto[];

  original: StaffDto;

  user_roles: Role;

  @ViewChild('staffForm') staffForm: NgForm;

  constructor(private router: ActivatedRoute,
              private staffService: StaffService,
              private districtService: DistrictService,
              private tokenService: AuthService,
              private toastService: ToastService,
              private log: LoggingService) {
    this.edit = false;
    this.canEdit = false;
  }

  editStaff() {
    this.original = JSON.parse(JSON.stringify(this.staff));
    this.edit = true;
  }

  cancel() {
    this.staff = this.original;
    this.staffForm.form.reset();
    this.staffForm.form.markAsPristine();
    this.edit = false;
  }


  hasBuilding(id: string): boolean {
    return this.staff.buildings.indexOf(id) > -1;
  }

  toggleBuilding(id: string) {
    const index = this.staff.buildings.indexOf(id);
    this.log.Log(`Index of building ${index}`);
    if (index > -1) {
      this.log.Log(`Removed building ${id}`)
      this.staff.buildings.splice(index, 1);
    } else {
      this.log.Log(`Adding building ${id}`)
      this.staff.buildings.push(id);
    }
    this.staffForm.form.markAsDirty();
  }

  hasRole(id: string): boolean {
    return this.staff.roleIds.indexOf(id) > -1;
  }

  impersonate() {
    this.staffService.impersonate(this.staffId).subscribe(x => {
      this.tokenService.setToken(x.token);
      this.tokenService.setRefresh(x.refresh);
      //window.location.reload();
    })
  }

  toggleEnableDisabled() {
    this.log.Log(`Setting disabled from ${this.staff.disabled} to ${!this.staff.disabled}`);
    this.staff.disabled = !this.staff.disabled;
    this.staffForm.form.markAsDirty();
  }

  toggleRole(id: string) {
    const index = this.staff.roleIds.indexOf(id);
    this.log.Log(`Role index ${index}`)
    if (index > -1) {
      this.log.Log(`Removing role ${id}`)
      this.staff.roleIds.splice(index, 1);
    } else {
      this.log.Log(`Adding role ${id}`)
      this.staff.roleIds.push(id);
    }
    this.staffForm.form.markAsDirty();
  }

  ngOnInit(): void {
    this.router.params.subscribe(p => {
      this.staffId = p['staff_id'];
      this.staffService.get(this.staffId, true, true).subscribe(r => {
        this.staff = r;
        this.staff.buildings = this.staff.buildings || [];
        this.staff.roleIds = this.staff.roleIds || [];
        this.staff.students = this.staff.students || [];
        this.students = [];

        this.districtService.getBuildings(r.districtId).subscribe(b => {
          this.buildings = b;
        }, err => {
          this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve buildings");
        });
        this.districtService.getRoles(r.districtId).subscribe(roles => {
          this.roles = roles;
        }, err => {
          this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve roles");
        });
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve staff member");
      });
      this.staffService.getStudent(this.staffId).subscribe(r => {
        this.students = r;
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve students");
      });
      const me = this.tokenService.parseJwt();

      const roles = this.tokenService.parseJwt().roles;
      this.canEdit = roles.indexOf('can_admin') > -1 && roles.indexOf('modify_staff') > -1;

      // this.staffService.getRoles('me').subscribe(r => {
      //   this.canEdit = r.can_admin && r.modify_staff;
      //
      //   if (!this.canEdit) {
      //     this.staffService.get('me').subscribe(v => {
      //       this.canEdit = v.isSIDRAdmin;
      //     });
      //   }
      // });
    });
  }

  reload() {
    this.edit = false;
    this.staffService.get(this.staffId, true, true).subscribe(r => {
      this.staff = r;
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve staff member");
    });
    this.staffService.getStudent(this.staffId).subscribe(r => {
      this.students = r;
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve students");
    });

  }

  addStudent(school_id: string) {
    this.districtService.getStudentBySchoolId(this.staff.districtId, school_id).subscribe(resp => {
      if (this.staff.students.indexOf(resp.id) > -1) {
        return;
      }
      this.students.push(resp);
      this.staff.students.push(resp.id);
      this.school_id = '';
    }, () => {
      this.toastService.AddNotification(Level.ERROR, this.title, "Failed to retrieve student information");
    });
  }

  removeStudent(id) {
    // for (let i = 0; i < this.students.length; i++) {
    //   if (this.students[i].id === id) {
    //     this.log.Log(`Removing student ${id}`)
    //     this.students.splice(i, 1);
    //     break;
    //   }
    // }
    if (this.staff.students.indexOf(id) > -1) {
      this.log.Log(`Removing ${id}`)
      this.staff.students.splice(this.staff.students.indexOf(id), 1);
    }

    this.staffForm.form.markAsDirty();
  }

  save() {
    this.staffService.patch(this.staff).subscribe(r => {
      this.staffForm.form.reset();
      this.staffForm.form.markAsPristine();
      this.reload();
      this.toastService.AddNotification(Level.SUCCESS, this.title, "Successfully saved");
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.title, "Failed to save");
    });
  }

  isDirty(): boolean {
    return this.staffForm.form.dirty;
  }
}

import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {finalize} from "rxjs/operators";
import {ActivityService} from "../activity.service";
import {LoggingService} from "../logging";

@Injectable()
export class ActivityInterceptor implements HttpInterceptor {
  public requests: number = 0;

  constructor(private activity: ActivityService, private log: LoggingService) {
  }

  private tickUp() {
    this.log.Log('tickUp');
    this.requests++;
    this.activity.isLoading.next(this.requests > 0);
    this.activity.requests.next(this.requests);
  }

  private tickDown() {
    this.log.Log('tickDown');
    this.requests--;
    this.activity.isLoading.next(this.requests > 0);
    this.activity.requests.next(this.requests);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(req.url.indexOf('refresh') > -1)
      return next.handle(req);

    this.tickUp();
    this.activity.requests.next(this.requests);

    return next?.handle(req).pipe(finalize(() => {
      this.tickDown();
    }));

  }

}

<p>
  Buildings are used to bind students to staff that have administrative roles. A student is required to be bound to a building when importing
  and the building code is used in the import process. To add a button, select the green Add building button. To modify or delete an existing
  building, click on the building.
</p>

<div class="d-flex">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
    <ng-container *ngFor="let building of buildings">
      <li [ngbNavItem]="building.id">
        <a ngbNavLink>{{building.name}}</a>
        <ng-template ngbNavContent>
          <form ngForm #buildingForm="ngForm" (ngSubmit)="submit(buildingForm, building)">
            <div class="form-group row">
              <div class="col-sm-6">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="building.name" required>
              </div>
              <div class="col-sm-6">
                <label for="code">Code</label>
                <input type="text" class="form-control" id="code" name="code" [(ngModel)]="building.code" required
                       ngbTooltip="Building code is used to reference inside of imports and other internal features">
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input type="text" class="form-control" id="phone" name="phone" [(ngModel)]="building.phone">
                </div>
                <div class="form-group">
                  <label for="email">E-Mail</label>
                  <input type="email" class="form-control" id="email" name="email" [(ngModel)]="building.email">
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="address">Address</label>
                  <input type="text" class="form-control" id="address" name="address" [(ngModel)]="building.address"
                         ngbTooltip="Address of the building. This is used in generating some reports.">
                </div>
                <div class="form-group">
                  <label for="city">City</label>
                  <input type="text" class="form-control" id="city" name="city" [(ngModel)]="building.city">
                </div>
                <div class="form-group">
                  <label for="state">State</label>
                  <input type="text" class="form-control" id="state" name="state" [(ngModel)]="building.state">
                </div>
                <div class="form-group">
                  <label for="zip">Zip</label>
                  <input type="text" class="form-control" id="zip" name="zip" [(ngModel)]="building.zip">
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-sm-12 text-right">
                <button type="button" class="btn btn-danger mr-2" (click)="delete(building)">Delete</button>
                <button type="button" class="btn btn-secondary mr-2" (click)="cancel(buildingForm)">Cancel</button>
                <button type="submit" class="btn btn-primary" [disabled]="buildingForm.valid && !buildingForm.touched">Save</button>
              </div>
            </div>
          </form>
        </ng-template>
      </li>
    </ng-container>
    <li ngbNavItem="create">
      <a ngbNavLink (click)="addBuilding(nav)">Add Building</a>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="ml-2 mt-2 flex-grow-1"></div>
</div>

export class InterventionLog {
  id: string;
  intervention: string;
  other: string;
  date: Date;
  tier: string;
  start: Date;
  end: Date;
  implementer: string;
  legacy: string;
  days_per_week: string;
  min_per_day: string;
  performance: string;

  static createFrom(source: any) {
    const result = new InterventionLog();
    result.id = source['id'];
    result.intervention = source['intervention'];
    result.other = source['other'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.tier = source['tier'];
    result.start = source['start'] ? new Date(source['start']) : null;
    result.end = source['end'] ? new Date(source['end']) : null;
    result.implementer = source['implementer'];
    result.legacy = source['legacy'];
    result.days_per_week = source['days_per_week'];
    result.min_per_day = source['min_per_day'];
    result.performance = source['performance'];
    return result;
  }
}


import {throwError as observableThrowError, Observable} from 'rxjs';
/**
 * Created by ptdave on 4/28/17.
 */
import {Injectable} from '@angular/core';
import {Location} from '@angular/common';


import {Router} from '@angular/router';
import {Evidence} from '../models/student/evidence.model';
import {ApprInst} from '../models/student/apprinst.model';
import {BehaviorIntervention} from '../models/student/bhvrintv.model';
import {InterventionLog} from '../models/student/intvlog.model';
import {Observation} from '../models/student/observation.model';
import {Student} from '../models/student/student.model';
import {Achievement} from '../models/student/achievement.model';
import {Assessment} from '../models/student/assessment.model';
import {AttendanceByPeriod} from '../models/student/attendance_by_period.model';
import {AttendanceOverview} from '../models/student/attendance_overview.model';
import {Behavior} from '../models/student/behavior.model';
import {ContactLog} from '../models/student/contactlog.model';
import {InitialContact} from '../models/student/initial_contact.model';
import {OtherFactor} from '../models/student/otherfactor.model';
import {Attendance} from '../models/student/attendance.model';
import {Meeting} from '../models/student/meeting.model';
import {IRIP} from '../models/student/irip.model';
import { HttpClient } from '@angular/common/http';
import { StudentAppropriateinstructionComponent } from '../student/appropriateInstructions/student.appropriateinstruction.component';
import { ToastService } from './toast.service';
import { AppropriateInstruction } from '../models/student/appropriate-instruction';

@Injectable()
export class StudentService {
  static handleError(error: Response) {
    console.error(error.url);
    console.error(error.statusText);
    console.error(error.type);
    console.error(error);

    return observableThrowError(error || 'Server error');
  }

  search(value: string): Observable<Student[]> {
    return this.http.get<Student[]>(`/api/students/search/${value}`);
  }

  photos(studentIds: string[]): Observable<Record<string, string>> {
    return this.http.post<Record<string, string>>(`/api/students/photos`, studentIds);
  }

  getStudentPhoto(districtId:string, studentId: string): Observable<string> {
    return new Observable<string>((observer) => {
      this.http.get(`/api/students/${districtId}/${studentId}/photo`, {responseType: 'blob'})
        .subscribe(data => {
          const reader = new FileReader();
          reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
          };
          if(data) {
            reader.readAsDataURL(data);
          }
        }, err => {
          observer.error(err);
        });
    })
  }

  goto(student: Student) {
    this.router.navigate(['/student', student.id]);
  }

  get(districtId: string, studentId: string): Observable<Student> {
    return this.http.get<Student>(`/api/students/${districtId}/${studentId}`);
  }

  getAchievements(districtId:string, studentId: string): Observable<Achievement[]> {
    return this.http.get<Achievement[]>(`/api/students/${districtId}/${studentId}/achievements`);
  }

  postAchievement(districtId:string, studentId: string, achievement: Achievement): Observable<Achievement> {
    return this.http.post<Achievement>(`/api/students/${districtId}/${studentId}/achievements`, achievement);
  }

  patchAchievement(districtId:string, studentId: string, achievement: Achievement): Observable<Achievement> {
    return this.http.patch<Achievement>(`/api/students/${districtId}/${studentId}/achievements/${achievement.id}`, achievement);
  }

  deleteAchievement(districtId:string, studentId: string, achievementId: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/achievements/${achievementId}`);
  }

  getAppropriateInstruction(districtId:string, studentId: string): Observable<AppropriateInstruction> {
    return this.http.get<AppropriateInstruction>(`/api/students/${districtId}/${studentId}/appropriate_instruction`);
  }

  patchAppropriateInstruction(districtId:string, studentId: string, model: AppropriateInstruction) {
    return this.http.patch<AppropriateInstruction>(`/api/students/${districtId}/${studentId}/appropriate_instruction`, model);
  }

  getAppropriateInstructionLegacy(districtId:string, studentId: string): Observable<ApprInst> {
    return this.http.get<ApprInst>(`/api/students/${districtId}/${studentId}/appropriate_instruction/v1`);
  }

  getAssessments(districtId:string, studentId: string): Observable<Assessment[]> {
    return this.http.get<Assessment[]>(`/api/students/${districtId}/${studentId}/assessments`);
  }

  postAssessment(districtId:string, studentId: string, assessment: Assessment): Observable<Assessment> {
    return this.http.post<Assessment>(`/api/students/${districtId}/${studentId}/assessments`, assessment);
  }

  patchAssessment(districtId:string, studentId: string, assessment: Assessment): Observable<Assessment> {
    return this.http.post<Assessment>(`/api/students/${districtId}/${studentId}/assessments/${assessment.id}`, assessment);
  }

  getAttedance(districtId:string, studentId: string): Observable<AttendanceOverview[]> {
    return this.http.get<AttendanceOverview[]>(`/api/students/${districtId}/${studentId}/attendance_overview`);
  }

  getAttedanceByPeriod(districtId:string, studentId: string): Observable<AttendanceByPeriod[]> {
    return this.http.get<AttendanceByPeriod[]>(`/api/students/${districtId}/${studentId}/attendance_by_period`);
  }

  getBehaviors(districtId:string, studentId: string): Observable<Behavior[]> {
    return this.http.get<Behavior[]>(`/api/students/${districtId}/${studentId}/behaviors`);
  }

  getContacts(districtId:string, studentId: string): Observable<ContactLog[]> {
    return this.http.get<ContactLog[]>(`/api/students/${districtId}/${studentId}/contacts`);
  }

  getInitialContact(districtId:string, studentId: string): Observable<InitialContact> {
    return this.http.get<InitialContact>(`/api/students/${districtId}/${studentId}/initial_contact`);
  }

  patchInitialContact(districtId:string, studentId: string, init: InitialContact): Observable<InitialContact> {
    return this.http.patch<InitialContact>(`/api/students/${districtId}/${studentId}/initial_contact`, init);
  }

  postContact(districtId:string, studentId: string, contact: ContactLog): Observable<ContactLog> {
    return this.http.post<ContactLog>(`/api/students/${districtId}/${studentId}/contacts`, contact);
  }

  patchContact(districtId:string, studentId: string, contact: ContactLog): Observable<ContactLog> {
    return this.http.patch<ContactLog>(`/api/students/${districtId}/${studentId}/contacts/${contact.id}`, contact);
  }

  getOtherFactors(districtId:string, studentId: string): Observable<OtherFactor[]> {
    return this.http.get<OtherFactor[]>(`/api/students/${districtId}/${studentId}/other_factors`);
  }

  postOtherFactors(districtId:string, studentId: string, other_factor: OtherFactor): Observable<OtherFactor> {
    return this.http.post<OtherFactor>(`/api/students/${districtId}/${studentId}/other_factors`, other_factor);
  }

  deleteOtherFactors(districtId:string, studentId: string, other_factorId: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/other_factors/${other_factorId}`);
  }

  patchOtherFactors(districtId:string, studentId: string, other_factor: OtherFactor): Observable<OtherFactor> {
    return this.http.patch<OtherFactor>(`/api/students/${districtId}/${studentId}/other_factors/${other_factor.id}`, other_factor);
  }

  getEvidence(districtId:string, studentId: string): Observable<Evidence[]> {
    return this.http.get<Evidence[]>(`/api/students/${districtId}/${studentId}/evidence`);
  }

  postEvidence(districtId:string, studentId: string, evidence: Evidence): Observable<Evidence> {
    return this.http.post<Evidence>(`/api/students/${districtId}/${studentId}/evidence`, evidence);
  }

  patchEvidence(districtId:string, studentId: string, evidence: Evidence): Observable<Evidence> {
    return this.http.patch<Evidence>(`/api/students/${districtId}/${studentId}/evidence/${evidence.id}`, evidence);
  }

  deleteEvidence(districtId:string, studentId: string, evidenceId: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/evidence/${evidenceId}`);
  }

  getApprInst(districtId:string, studentId: string): Observable<ApprInst> {
    return this.http.get<ApprInst>(`/api/students/${districtId}/${studentId}/appropriate_instruction`);
  }

  patchApprInst(districtId:string, studentId: string, apprInst: ApprInst) {
    return this.http.patch<ApprInst>(`/api/students/${districtId}/${studentId}/appropriate_instruction`, apprInst);
  }

  getBehaviorIntervention(districtId:string, studentId: string): Observable<BehaviorIntervention[]> {
    return this.http.get<BehaviorIntervention[]>(`/api/students/${districtId}/${studentId}/behavior_interventions`);
  }

  patchBehaviorIntervention(districtId:string, studentId: string, bhvrintv: BehaviorIntervention): Observable<BehaviorIntervention> {
    return this.http.patch<BehaviorIntervention>(`/api/students/${districtId}/${studentId}/behavior_interventions/${bhvrintv.id}`, bhvrintv);
  }

  postBehaviorIntervention(districtId:string, studentId: string, bhvrintv: BehaviorIntervention): Observable<BehaviorIntervention> {
    return this.http.post<BehaviorIntervention>(`/api/students/${districtId}/${studentId}/behavior_interventions`, bhvrintv);
  }

  deleteBehaviorIntervention(districtId:string, studentId: string, bhvrintv: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/behavior_interventions/${bhvrintv}`);
  }

  getInterventionLogs(districtId:string, studentId: string): Observable<InterventionLog[]> {
    return this.http.get<InterventionLog[]>(`/api/students/${districtId}/${studentId}/interventions`);
  }

  getInterventionLog(districtId:string, studentId: string, id: string): Observable<InterventionLog> {
    return this.http.get<InterventionLog>(`/api/students/${districtId}/${studentId}/intervention/${id}`);
  }

  postInterventionLog(districtId:string, studentId: string, intv: InterventionLog): Observable<InterventionLog> {
    return this.http.post<InterventionLog>(`/api/students/${districtId}/${studentId}/intervention`, intv);
  }

  patchInterventionLog(districtId:string, studentId: string, intv: InterventionLog): Observable<InterventionLog> {
    return this.http.patch<InterventionLog>(`/api/students/${districtId}/${studentId}/intervention/${intv.id}`, intv);
  }

  deleteInterventionLog(districtId:string, studentId: string, intvId: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/intervention/${intvId}`);
  }

  postIRIP(districtId:string, studentId: string, data: IRIP): Observable<IRIP> {
    return this.http.post<IRIP>(`/api/students/${districtId}/${studentId}/irips`, data);
  }

  patchIRIP(districtId:string, studentId: string, data: IRIP): Observable<IRIP> {
    return this.http.patch<IRIP>(`/api/students/${districtId}/${studentId}/irips/${data.id}`, data);
  }

  deleteIRIP(districtId:string, studentId: string, id: string): Observable<any> {
    return this.http.delete(`/api/students/${districtId}/${studentId}/irips/${id}`);
  }

  getIRIPs(districtId:string, studentId: string): Observable<IRIP[]> {
    return this.http.get<IRIP[]>(`/api/students/${districtId}/${studentId}/irips`);
  }

  getIRIPPrint(districtId: string, studentId:string, id: string): Observable<any> {
    return this.http.get<any>(`/api/students/${districtId}/${studentId}/irips/${id}/print`, {responseType: 'blob' as 'json'});
  }

  getObservations(districtId:string, studentId: string): Observable<Observation[]> {
    return this.http.get<Observation[]>(`/api/students/${districtId}/${studentId}/observations`);
  }

  postObservation(districtId:string, studentId: string, obs: Observation): Observable<Observation> {
    return this.http.post<Observation>(`/api/students/${districtId}/${studentId}/observation`, obs);
  }

  patchObservation(districtId:string, studentId: string, obs: Observation): Observable<Observation> {
    return this.http.patch<Observation>(`/api/students/${districtId}/${studentId}/observation/${obs.id}`, obs);
  }

  deleteObservation(districtId:string, studentId: string, obs: Observation): Observable<Observation> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/observation/${obs.id}`);
  }

  getMeetings(districtId:string, studentId: string): Observable<Meeting[]> {
    return this.http.get<Meeting[]>(`/api/students/${districtId}/${studentId}/meetings`);
  }

  postMeeting(districtId:string, studentId: string, m: Meeting): Observable<Meeting> {
    return this.http.post<Meeting>(`/api/students/${districtId}/${studentId}/meeting`, m);
  }
  patchMeeting(districtId:string, studentId: string, m: Meeting): Observable<Meeting> {
    return this.http.patch<Meeting>(`/api/students/${districtId}/${studentId}/meeting/${m.id}`, m);
  }
  deleteMeeting(districtId:string, studentId: string, meetingId: string): Observable<Response> {
    return this.http.delete<any>(`/api/students/${districtId}/${studentId}/meeting/${meetingId}`);
  }

  constructor(
    private http: HttpClient,
    private location: Location,
    private router: Router,
    private toastService: ToastService
  ) {}
}

/**
 * Created by ptdave on 4/27/17.
 */

import {Component, OnInit} from '@angular/core';
import {UserService} from '../../services/user.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StaffDialogComponent} from '../../staff/staffDialog/staffDialog.component';
import {StaffService} from '../../services/staff.service';
import {Role} from '../../models/district/role.model';
import {AuthService} from "../../services/authService";
import {LoggingService} from "../../services/logging";

@Component({
  selector: 'login',
  styles: [
    `
    .btn-login {
      font-family: 'Roboto', sans-serif;
    }
    .btn-login-google-text {
      padding-left: 18px;
    }
    i.fa.fa-google {
      padding-left: 8px;
      padding-right: 8px;
      height: 8px;
      width: 8px;
    }
    `
  ],
  template: `
    <div *ngIf="loggedIn" class="pl-2" ngbDropdown>
      <button class="btn btn-primary" ngbDropdownToggle>
        <i class="fa fa-user-circle" aria-hidden="true"></i>
      </button>
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-label="User menu">
        <button class="dropdown-item" (click)="openProfile()">User Profile</button>
        <a class="dropdown-item" [routerLink]="['/district',districtId]" roles="can_admin">District Settings</a>
        <a class="dropdown-item" [routerLink]="['/admin']" isSIDRAdmin>SIDR Admin</a>
        <button class="dropdown-item" (click)="Logout()">Logout</button>
      </div>
    </div>
    <a (click)="Login()" class="google-signin" *ngIf="!loggedIn"></a>
  `
})
export class LoginComponent implements OnInit {
  loggedIn = false;
  role: Role;
  districtId: string;

  constructor(private authService: AuthService,
              private userService: UserService,
              private modal: NgbModal,
              private router: Router,
              private tokenService: AuthService,
              private log: LoggingService) {
    this.loggedIn = false;
  }

  ngOnInit(): void {
    this.authService.isLoggedIn.subscribe(resp => {
      this.log.Log(`LoginButton logged in == ${resp}`);
      this.loggedIn = resp;
      const token = this.tokenService.parseJwt();
      if (token != null) {
        this.districtId = token.districtId
      }
    });
  }

  openProfile() {
    this.userService.get('me').subscribe(r => {
      const m = this.modal.open(StaffDialogComponent);
      m.componentInstance.staff = r;
      m.componentInstance.admin = false;
    });
  }

  openDistrict() {
    this.router.navigate(['/district',this.tokenService.parseJwt().districtId]);
  }

  Login() {
    this.userService.authenticate();
  }

  Logout() {
    this.userService.logout();
    this.router.navigate(['/welcome']);
    this.loggedIn = false;
  }
}

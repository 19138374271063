import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Meeting} from '../../models/student/meeting.model';
import {StudentService} from '../../services/student.service';
import {DistrictService} from '../../services/district.service';
import {NgForm} from '@angular/forms';
import {Level, ToastService} from '../../services/toast.service';
import {TypesService} from "../../services/types.service";
import {DistrictTypeDto} from "../../common/dtos/districtTypeDto";

@Component({
  selector: `student-meeting`,
  styles: [
    `@media print {
      .showSelected {
        display: block;
      }

      .hideSelected {
        display: block;
      }
    }`,
      `@media screen {
      .showSelected {
        display: block;
      }

      .hideSelected {
        display: none;
      }

      .show {
        display: block;
      }

      .hide {
        display: none;
      }
    }`
  ],
  template: `
    <div class="py-1 d-flex flex-row">
      <div class="btn-group d-print-none">
        <button type="button" class="btn"
                [ngClass]="{'btn-outline-primary':selectedSection !== 'information', 'btn-primary':selectedSection === 'information'}"
                (click)="selectedSection = 'information'">
          Information
        </button>
        <div class="btn-group">
          <button type="button" class="btn col"
                  [ngClass]="{'btn-outline-primary':selectedSection !== 'problems', 'btn-primary':selectedSection === 'problems'}"
                  (click)="selectedSection = 'problems'">
            Problems
          </button>
          <div class="btn-group" ngbDropdown [ngClass]="{'d-none':!editting}">
            <button type="button" class="btn btn-outline-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" *ngIf="editting" ngbDropdownMenu>
              <button *ngFor="let p of meeting_problems" type="button"
                      class="dropdown-item" (click)="addProblem(p)">{{p.name}}
              </button>
            </div>
          </div>
        </div>
        <div class="btn-group">
          <button type="button" class="btn"
                  [ngClass]="{'btn-outline-primary':selectedSection !== 'strcons', 'btn-primary':selectedSection === 'strcons'}"
                  (click)="selectedSection = 'strcons'">
            Strength / Concerns
          </button>
          <div class="btn-group" ngbDropdown [ngClass]="{'d-none':!editting}">
            <button type="button" class="btn btn-outline-primary dropdown-toggle-split" ngbDropdownToggle></button>
            <div class="dropdown-menu" *ngIf="editting" ngbDropdownMenu>
              <button *ngFor="let s of meeting_strcons" type="button"
                      class="dropdown-item" (click)="addStrcon(s)">{{s.name}}
              </button>
            </div>
          </div>
        </div>
        <button type="button" class="btn"
                [ngClass]="{'btn-outline-primary':selectedSection !== 'nextsteps', 'btn-primary':selectedSection === 'nextsteps'}"
                (click)="selectedSection = 'nextsteps'">
          Next Steps
        </button>
        <button type="button" class="btn btn-success" (click)="save()" [disabled]="canSave" ngbTooltip="Save"
                aria-label="Save" *ngIf="editting">
          <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
        </button>
        <button type="button" class="btn btn-warning" (click)="editting = true" ngbTooltip="Edit" aria-label="Edit" *ngIf="!editting">
          <i class="fa fa-pencil" aria-hidden="true"></i> Edit
        </button>
      </div>
      <div ngbDropdown class="mx-auto">
        <button class="btn btn-primary" [ngClass]="{'d-none':!editting}" ngbDropdownToggle>
          Copy Meeting
        </button>
        <div ngbDropdownMenu>
          <button class="dropdown-item" *ngFor="let m of meetings" (click)="copyFrom(m)">{{m.date | date : 'shortDate'}}</button>
        </div>
      </div>
    </div>

    <div class="py-1 d-flex flex-row">
      <form #meetingForm="ngForm" class="col">
        <div class="col"
             [ngClass]="{'showSelected':selectedSection==='information','hideSelected':selectedSection!=='information'}">
             <h3 class="d-none d-print-block">Information</h3>
          <div class="form-group row">
            <label class="col-2" for="date">Date</label>
            <div class="col-10" *ngIf="editting">
              <input type="date" id="date" #dateInput="ngModel" name="date" [(ngModel)]="meeting.date"
                     placeholder="Date of Meeting" class="form-control d-print-none">
            </div>
            <div class="col-sm-10" *ngIf="!editting">
              {{meeting.date | date: 'shortDate' }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="topic">Topic</label>
            <div class="col-sm-10" *ngIf="editting">
              <select class="form-control d-print-none" name="topic" id="topic" [(ngModel)]="meeting.topic"
                      #topicInput="ngModel">
                <option *ngFor="let t of meeting_topics" [value]="t.name">{{t.name}}</option>
              </select>
            </div>
            <div class="col-sm-10" *ngIf="!editting">
              {{meeting.topic}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="participants">Participants</label>
            <div class="col-sm-10">
              <staff-select id="participants" [multiple]="true" [districtId]="districtId"
                            [(selected)]="meeting.participant_ids" (onChange)="meetingForm.form.markAsDirty();"
                            [edittable]="editting"></staff-select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="other_participants">Other Participants</label>
            <div class="col-sm-10" *ngIf="editting">
              <textarea class="form-control d-print-none" name="other_participants" id="other_participants"
                        #oParticipants="ngModel"
                        [(ngModel)]="meeting.other_participants"></textarea>
            </div>
            <div class="col-sm-10" *ngIf="!editting">
              {{meeting.other_participants}}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-2" for="notes">Notes</label>
            <div class="col-sm-10" *ngIf="editting">
              <textarea class="form-control d-print-none" name="notes" id="notes" #notes="ngModel"
                        [(ngModel)]="meeting.notes"></textarea>
            </div>
            <div class="col-sm-10" *ngIf="!editting">
              {{meeting.notes}}
            </div>
          </div>
        </div>
        <div [ngClass]="{'showSelected':selectedSection==='problems','hideSelected':selectedSection!=='problems'}">
          <h3 class="d-none d-print-block">Problems</h3>
          <div class="container" *ngFor="let problem of meeting.problems">
            <div class="card"
                 [ngClass]="getClass(problem.value)">
              <div class="card-header d-flex flex-row"
                   [ngClass]="getClassFont(problem.value)">
                <div class="align-self-start align-self-center mr-auto">{{problem.name}}</div>
                <div class="align-self-end d-flex flex-row">
                  <div class="input-group">
                    <select [(ngModel)]="problem.value" class="form-control" [name]="problem.name" [disabled]="!editting">
                        <option *ngFor="let v of values" [value]="v">{{v | problemDesc}}</option>
                      </select>
                    <div class="input-group-append">
                      <button class="btn btn-primary d-print-none" (click)="toggleProblemsExpand(problem.name)">
                        <i class="fa fa-chevron-down" aria-hidden="true"
                           [ngClass]="{'fa-chevron-down':!problemsExpand(problem.name), 'fa-chevron-up': problemsExpand(problem.name)}"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ul class="list-group list-group-flush d-print-none"
                  [ngClass]="{'show':problemsExpand(problem.name), 'hide': !problemsExpand(problem.name)}">
                <li *ngFor="let sub of problem.sub_problems" class="list-group-item">
                  <div class="d-flex flex-row">
                    <span class="align-self-start align-self-center mr-auto">{{sub.name}}</span>
                    <span class="align-self-end">
                      <span *ngIf="sub?.type === 'integer'">
                        <span *ngIf="!editting">{{sub.int_value | problemDesc}}</span>
                        <select *ngIf="editting" [(ngModel)]="sub.int_value" class="form-control" [name]="sub.name">
                          <option *ngFor="let v of values" [value]="v">{{v | problemDesc}}</option>
                        </select>
                      </span>
                      <span *ngIf="sub?.type === 'string'">
                      <span *ngIf="!editting">{{sub.str_value}}</span>
                      <textarea *ngIf="editting" class="form-control" [name]="sub.name"
                                [(ngModel)]="sub.str_value"></textarea>
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
              <ul class="list-group list-group-flush d-none d-print-block">
                <li *ngFor="let sub of problem.sub_problems" class="list-group-item">
                  <div class="d-flex flex-row">
                    <span class="align-self-start align-self-center mr-auto">{{sub.name}}</span>
                    <span class="align-self-end">
                      <span *ngIf="!editting">{{sub.int_value | problemDesc}}</span>
                      <span *ngIf="sub?.type === 'string'">
                        {{sub.str_value}}
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <meeting-strcons
          [ngClass]="{'showSelected':selectedSection==='strcons','hideSelected':selectedSection!=='strcons'}"
          [(meeting)]="meeting" (meetingChange)="onMeetingChange($event)" [edit]="editting"
          [strcons]="meeting_strcons"></meeting-strcons>
        <meeting-nextsteps
          [ngClass]="{'showSelected':selectedSection==='nextsteps','hideSelected':selectedSection!=='nextsteps'}"
          [(meeting)]="meeting" (meetingChange)="onMeetingChange($event)" [edit]="editting"
          [nextsteps]="meeting_nextsteps"></meeting-nextsteps>
      </form>
    </div>
  `
})
export class StudentMeetingComponent implements OnInit {
  @ViewChild('meetingForm') form: NgForm;
  @Input() studentId: string;
  @Input() districtId: string;
  @Input() meeting: Meeting;
  @Output() meetingChange: EventEmitter<Meeting> = new EventEmitter<Meeting>();
  @Input() meetings: Meeting[];
  @Input() meeting_topics: DistrictTypeDto[];
  @Input() meeting_strcons: DistrictTypeDto[];
  @Input() meeting_problems: DistrictTypeDto[];
  @Input() meeting_nextsteps: DistrictTypeDto[];
  @Input() editting = false;


  NotificationTitle = 'Meetings';
  problems_expand: any;
  selectedSection = '';
  values: number[];

  constructor(
    private studentService: StudentService,
    private districtService: DistrictService,
    private typesService: TypesService,
    private toastService: ToastService) {
    this.meeting_problems = [];
    this.meeting_strcons = [];
    this.meeting_topics = [];
    this.editting = false;
    this.values = [0, 4, 3, 2, 1];
    this.problems_expand = {};
  }

  onMeetingChange(m: Meeting) {
    this.meeting = m;
    this.form.form.markAsDirty();
    this.form.form.markAsTouched();
    this.meetingChange.emit(m);
  }

  copyFrom(m: Meeting) {
    this.meeting.problems = m.problems;
    this.meeting.strcons = m.strcons;
    this.meeting.notes = m.notes;
    this.meeting.next_steps = m.next_steps;
    this.meeting.topic = m.topic;
    this.meeting.participant_ids = m.participant_ids;
    this.meeting.other_participants = m.other_participants;
    this.form.form.markAsDirty();
    this.form.form.markAsTouched();
  }

  get canSave(): boolean {
    return !(this.dirty || this.touched);
  }

  get dirty(): boolean {
    return this.form.form.dirty;
  }

  get touched(): boolean {
    return this.form.form.touched;
  }

  problemsExpand(name: string): boolean {
    if (!this.problems_expand[name]) {
      return false;
    }
    return this.problems_expand[name];
  }

  toggleProblemsExpand(name: string) {
    if (!this.problems_expand[name]) {
      this.problems_expand[name] = true;
      return;
    }

    this.problems_expand[name] = !this.problems_expand[name];
  }

  getClass(value: any) {
    if (typeof value === 'string') {
      switch (value) {
        case '4':
          return 'bg-success';
        case '3':
          return 'bg-info';
        case '2':
          return 'bg-warning';
        case '1':
          return 'bg-danger';
        case '0':
          break;
      }
    } else {
      switch (value) {
        case 4:
          return 'bg-success';
        case 3:
          return 'bg-info';
        case 2:
          return 'bg-warning';
        case 1:
          return 'bg-danger';
        case 0:
          break;
      }
    }
  }

  getClassFont(value: any) {
    if (typeof value === 'string') {
      switch (value) {
        case '4':
          return 'text-white';
        case '3':
          return 'text-white';
        case '2':
          return '';
        case '1':
          return 'text-white';
        case '0':
          break;
      }
    } else {
      switch (value) {
        case 4:
          return 'text-white';
        case 3:
          return 'text-white';
        case 2:
          return '';
        case 1:
          return 'text-white';
        case 0:
          break;
      }
    }
  }

  save() {
    (!this.meeting.id ?
      this.studentService.postMeeting(this.districtId, this.studentId, this.meeting) :
      this.studentService.patchMeeting(this.districtId, this.studentId, this.meeting)
    ).subscribe(r => {
      this.meeting = r;
      this.editting = false;
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, "Meeting saved successfully");
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, "Failed to save meeting");
    });
  }

  addProblem(p: any) {
    if (this.meeting.problems) {
      this.meeting.problems = [];
    }
    p.value = 0;
    if (p.id) {
      delete p.id;
    }
    if (p.district_id) {
      delete p.district_id;
    }

    if (this.meeting.problems.length > 0) {
      for (let v = 0; v < this.meeting.problems.length; v++) {
        if (p.name === this.meeting.problems[v].name) {
          this.toastService.AddNotification(Level.WARNING, this.NotificationTitle, 'Cannot add Problem twice');
          return;
        }
      }
    }

    p.value = '0';
    if (p.sub_problems) {
      for (let i = 0; i < p.sub_problems.length; i++) {
        if (p.sub_problems[i].type === 'integer') {
          p.sub_problems[i].int_value = '0';
        } else {
          p.sub_problems[i].str_value = '';
        }
      }
    }

    this.meeting.problems.push(p);
    this.form.form.markAsTouched();
    this.form.form.markAsDirty();
    this.selectedSection = 'problems';
  }

  addStrcon(strcon: any) {
    if (!this.meeting.strcons) {
      this.meeting.strcons = [];
    }
    if (strcon.id) {
      delete strcon.id;
    }
    if (strcon.district_id) {
      delete strcon.district_id;
    }

    if (this.meeting.strcons.length > 0) {
      for (let v = 0; v < this.meeting.strcons.length; v++) {
        if (strcon.name === this.meeting.strcons[v].name) {
          this.toastService.AddNotification(Level.WARNING, this.NotificationTitle, 'Cannot add Strength or Concern twice');
          return;
        }
      }
    }

    this.meeting.strcons.push(strcon);
    this.form.form.markAsTouched();
    this.form.form.markAsDirty();
    this.selectedSection = 'strcons';
  }

  ngOnInit(): void {
    this.selectedSection = 'information';
  }
}

import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {DistrictService} from "../../../services/district.service";
import {DistrictMeetingProblemDto} from "../../../common/dtos/districtMeetingProblemDto";
import {Level, ToastService} from "../../../services/toast.service";
import {NgbNav} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'module-meetings-problems',
  templateUrl: 'meetingProblems.component.html'
})
export class ModuleMeetingProblemsComponent implements OnInit {
  @Input() districtId: string;
  @ViewChild('problemNav') problemNav : NgbNav;
  problems: DistrictMeetingProblemDto[];

  getData() {
    this.districtService.getMeetingProblems(this.districtId).subscribe(r => {
      this.problems = r;
      for (let problem of this.problems) {
        problem.sub_problems = problem.sub_problems || [];
      }
    }, () => {
      this.problems = [];
      this.toastService.AddNotification(Level.ERROR, "Meeting Problems Load Failed", "The meeting problems could not be loaded")
    });
  }

  addProblem() {
    this.problems = this.problems || [];
    let newproblem = new DistrictMeetingProblemDto();
    newproblem.name = "Unnamed Problem";
    newproblem.districtId = this.districtId;
    newproblem.type = "meeting_problem";
    this.districtService.postMeetingProblems(this.districtId, newproblem).subscribe(r => {
      r.sub_problems = r.sub_problems || [];
      this.problems.push(r);
      this.toastService.AddNotification(Level.SUCCESS, "Meeting Problem Added", "The meeting problem has been added successfully");
      this.problemNav.select(r.id);
    }, err => {
      this.toastService.AddNotification(Level.ERROR, "Meeting Problem Add Failed", "The meeting problem could not be added");
    });
    return false;
  }

  deleteProblem(index: number) {
    this.districtService.deleteMeetingProblem(this.districtId, this.problems[index].id).subscribe(() => {
      this.problems.splice(index, 1);
      this.toastService.AddNotification(Level.SUCCESS, "Meeting Problem Deleted", "The meeting problem has been deleted successfully");
    }, () => {
      this.toastService.AddNotification(Level.ERROR, "Meeting Problem Delete Failed", "The meeting problem could not be deleted");
    });

  }

  ngOnInit() {
    this.getData();
  }

  constructor(private districtService: DistrictService, private toastService: ToastService) {
  }
}

export class Evidence {
  id: string;
  date: Date;
  concern: string;
  evidence: string;

  static createFrom(source: any) {
    const result = new Evidence();
    result.id = source['id'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.concern = source['concern'];
    result.evidence = source['evidence'];
    return result;
  }
}

import {Component, Input} from '@angular/core';
import {PrintOptions} from '../../common/components/buttons/print-options.helper';
import {InterventionLog} from '../../models/student/intvlog.model';
import {InterventionType} from '../../models/district/interventions.model';
import {InterventionTier} from '../../models/district/interventiontiers.model';
import {StaffDto} from '../../common/dtos/staffDto';
import {NgForm} from '@angular/forms';

import {Level, ToastService} from '../../services/toast.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StudentService} from '../../services/student.service';
import {DistrictService} from '../../services/district.service';
import {AuthService} from '../../services/authService';
import {TypesService} from '../../services/types.service';

@Component({
  selector: `student-intvlogdialog`,
  templateUrl: 'intvlog.modal.html'
})
export class StudentIntvlogDialogComponent {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;
  @Input() log: InterventionLog;
  @Input() edit: boolean;

  update: boolean;

  interventions: InterventionType[];
  tiers: InterventionTier[];
  staff: StaffDto[];

  NotificationTitle = 'Intervention Log';

  constructor(public activeModal: NgbActiveModal,
              private studentService: StudentService,
              private districtService: DistrictService,
              private typesService: TypesService,
              private authService: AuthService,
              private toastService: ToastService) {
    this.edit = false;

    const districtId = this.authService.parseJwt().districtId;

    this.districtService.getActiveStaff(districtId).subscribe(resp => {
      this.staff = resp || [];
    });
    this.typesService.getBasicType(districtId, TypesService.InterventionTier).subscribe(resp => {
      this.tiers = resp || [];
    });
    this.typesService.getBasicType(districtId, TypesService.InterventionType).subscribe(resp => {
      this.interventions = resp || [];
    });
    this.update = false;
  }

  close() {
    this.activeModal.dismiss(this.update);
  }

  save(f: NgForm) {
    this.log.days_per_week = this.log.days_per_week + '';
    this.log.min_per_day = this.log.min_per_day + '';

    (!this.log.id ?
      this.studentService.postInterventionLog(this.districtId, this.studentId, this.log) :
      this.studentService.patchInterventionLog(this.districtId, this.studentId, this.log)
    ).subscribe(resp => {
      this.edit = false;
      this.log = resp;
      f.form.reset();
      this.update = true;
      this.close();
      this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Successfully added intervention log');
    }, err => {
      this.toastService.AddNotification(Level.ERROR, this.NotificationTitle, 'Failed to save intervention log');
    });
  }
}

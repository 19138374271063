import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: `distinct` })
export class DistinctPipe implements PipeTransform {
  transform(value: any[], ...args: any[]): any[] {
    if (!args) {
      return [];
    }
    const key = args[0];
    const ret: any[] = [];
    for (let i = 0; i < value.length; i++) {
      if (ret.indexOf(value[i][key]) === -1) {
        ret.push(value[i][key]);
      }
    }

    ret.sort();

    return ret;
  }

}

<h3>District Domains</h3>
<p>
  During authentication, a Google user's domain is used to authenticate against SIDR. Below is a list of allowed domains when logging into SIDR Online for your school district.
  You are required to have at least one domain listed.
</p>
<form #domainForm="ngForm">
  <ng-container *ngFor="let domain of domains; let i = index">
    <div class="form-row mb-1">
      <div class="input-group">
        <input type="text" class="form-control" [(ngModel)]="domains[i]" name="domain{{i}}" required>
        <div class="input-group-append">
          <span class="input-group-text">
            <ng-container *ngIf="validateDomain(domains[i]); else failTemp">
              <i class="fas fa-check text-success"></i>
            </ng-container>
            <ng-template #failTemp>
              <i class="fas fa-exclamation-circle text-danger" ngbTooltip="This does not appear to be a valid domain name"></i>
            </ng-template>
          </span>
          <button class="btn btn-danger" (click)="removeDomain(i, domainForm)" ngbTooltip="Remove domain name"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="form-row">
    <button class="btn btn-info mr-1" (click)="domains.push(''); domainForm.form.markAsDirty()" type="button">Add Domain</button>
    <button label="Save"
            [disabled]="!domainForm.form.dirty && !allValid()"
            (click)="save(domainForm)"
            class="btn btn-success pull-right mr-2"
            type="button">Save
    </button>
  </div>
</form>

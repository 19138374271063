import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {DataType, Header, SortableTableComponent} from "../../components/helpers/sortableTable/sortableTable.component";
import {Level, ToastService} from "../../services/toast.service";
import {Evidence} from "../../models/student/evidence.model";
import {StudentService} from "../../services/student.service";

@Component({
  selector: 'app-student-runningrecord',
  templateUrl: './student-runningrecord.component.html',
  styleUrls: ['./student-runningrecord.component.scss']
})
export class StudentRunningRecordComponent implements OnChanges {
  loading: boolean;
  @Input() districtId: string;
  @Input() studentId: string;
  @Input() printOptions: PrintOptions;

  notificationTitle = 'Evidence';

  evidence: Evidence[];
  newEvidence: Evidence;

  @ViewChild(SortableTableComponent) public Table: SortableTableComponent;
  headers: Header[];

  constructor(private studentService: StudentService, private toastService: ToastService, private route: ActivatedRoute) {
    this.loading = false;
    this.evidence = [];
    this.newEvidence = new Evidence();

    this.headers = [];
    this.headers.push(new Header('Date', 'date', false, DataType.Date, { dateFormat: 'shortDate' }));
    this.headers.push(new Header('Concern', 'concern', false, DataType.String));
    this.headers.push(new Header('Evidence', 'evidence', false, DataType.String));
  }

  getData() {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.loading = true;
    this.studentService.getEvidence(this.districtId, this.studentId).subscribe(r => {
      this.loading = false;
      this.evidence = r || [];
    }, (err) => {
      this.loading = false;
      this.toastService.AddNotification(Level.ERROR, this.notificationTitle, err.message);
    });
  }

  save(d: Evidence) {
    this.loading = true;
    if (!d.id) {
      this.studentService.postEvidence(this.districtId, this.studentId, d).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.notificationTitle, 'Added evidence');
        this.loading = false;
        this.getData();
        this.Table.clear();
      }, error => {
        this.toastService.AddNotification(Level.ERROR, this.notificationTitle, 'Error adding evidence');
        this.loading = false
      });
    } else {
      this.studentService.patchEvidence(this.districtId, this.studentId, d).subscribe(resp => {
        this.toastService.AddNotification(Level.SUCCESS, this.notificationTitle, 'Saved evidence');
        this.loading = false;
        this.getData();
        this.Table.clear();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, this.notificationTitle, 'Error saving evidence');
        this.loading = false
      });
    }
  }

  del(d: Evidence) {
    this.loading = true;
    this.studentService.deleteEvidence(this.districtId, this.studentId, d.id).subscribe(resp => {
      this.loading = false;
      this.getData();
    });
  }

  ngOnChanges() {
    this.getData();
  }
}

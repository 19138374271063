import {Component, Input, OnChanges} from '@angular/core';

import {StudentService} from '../../services/student.service';
import {ActivatedRoute} from '@angular/router';
import {InterventionLog} from '../../models/student/intvlog.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {StudentIntvlogDialogComponent} from "./intvlog.modal";

@Component({
  selector: `student-intvlog`,
  template: `
    <div class="d-print-none"
         [ngClass]="{'d-print-none': (!printOptions.getValue('display.staff') || !printOptions.getValue('staff.intvlogs'))}">
      <nav class="navbar" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}"><span
        class="navbar-brand">Intervention Logs</span></nav>
      <table class="table table-striped d-print-none">
        <thead>
        <tr>
          <th>Date Notified</th>
          <th>Intervention</th>
          <th>Tier</th>
          <th>Start</th>
          <th>End</th>
          <th>
            <button-new btnClass="btn-sm" (btnClick)="new()"></button-new>
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let i of intvlogs">
          <td>{{i.date | date: 'shortDate'}}</td>
          <td>{{i.intervention}}</td>
          <td>{{i.tier}}</td>
          <td>{{i.start | date: 'shortDate' | blankDate}}</td>
          <td>{{i.end | date: 'shortDate' | blankDate}}</td>
          <td>
            <button-more btnClass="btn-sm" (btnClick)="open(i)"></button-more>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  `
})
export class StudentIntvLogComponent implements OnChanges {
  intvlogs: InterventionLog[];
  intvlog: InterventionLog;
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  getData() {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.studentService.getInterventionLogs(this.districtId, this.studentId).subscribe(resp => {
      this.intvlogs = resp || [];
      if (this.intvlogs !== null) {
        this.intvlogs.sort((a: InterventionLog, b: InterventionLog) => {
          return a.date > b.date ? -1 : 1;
        });
      }
    }, (resp) => {
      this.intvlogs = [];
    });
  }

  ngOnChanges(): void {
    this.getData();
  }

  new() {
    const m = this.modal.open(StudentIntvlogDialogComponent, {
      size: 'lg'
    });
    m.componentInstance.log = new InterventionLog();
    m.componentInstance.edit = true;
    m.componentInstance.studentId = this.studentId;
    m.result.then(r => {
      if (r) {
        this.getData();
      }
    }, reason => {
      if (reason) {
        this.getData();
      }
    });
  }

  open(i: InterventionLog) {
    const m = this.modal.open(StudentIntvlogDialogComponent, {
      size: 'lg'
    });
    m.componentInstance.log = {...i};
    m.componentInstance.studentId = this.studentId;

    m.result.then(r => {
      if (r) {
        this.getData();
      }
    }, reason => {
      if (reason) {
        this.getData();
      }
    });
  }

  constructor(private studentService: StudentService, private modal: NgbModal) {
    this.intvlogs = [];
    this.intvlog = new InterventionLog();
  }
}

export class Observation {
  id: string;
  date: Date;
  academic_area: string;
  observer_ids: string[];
  observer_str: string;
  materials: string;
  activities: string;
  presentation: string;
  observation: string;
  peer_comparison: string;

  static createFrom(source: any) {
    const result = new Observation();
    result.id = source['id'];
    result.date = source['date'] ? new Date(source['date']) : null;
    result.academic_area = source['academic_area'];
    result.observer_ids = source['observer_ids'] ? source['observer_ids'] : [];
    result.observer_str = source['observer_str'];
    result.materials = source['materials'];
    result.activities = source['activities'];
    result.presentation = source['presentation'];
    result.observation = source['observation'];
    result.peer_comparison = source['peer_comparison'];
    return result;
  }
}

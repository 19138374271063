import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {Meeting} from '../../models/student/meeting.model';
import {StudentService} from '../../services/student.service';
import {ActivatedRoute} from '@angular/router';

import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {DataType, Header, SortDirection} from '../../components/helpers/sortableTable/sortableTable.component';
import {DistrictService} from '../../services/district.service';
import {StudentMeetingComponent} from './student.meeting.component';
import {TypesService} from "../../services/types.service";
import {DistrictTypeDto} from "../../common/dtos/districtTypeDto";

;

@Component({
  selector: `student-meetings`,
  styles: [
    `@media screen {
        .meeting-selected {
          display: none;
        }
      }`
  ],
  template: `
    <nav class="navbar" [ngClass]="{'d-print-none': !printOptions.getValue('display.headers')}">
        <span class="navbar-brand">Meetings</span>
        <span class="mx-auto"></span>
        <button-new (btnClick)="newMeeting()" btnClass="btn-sm" *ngIf="selectedIndex < 0"></button-new>
      </nav>
      <sortable-table [showDelete]="true" [showMore]="true" [data]="meetings" [headers]="headers"
                      (moreCall)="open($event)" (delCall)="delete($event)" defaultSort="date"
                      [ngClass]="{'meeting-selected' : selectedIndex > -1}" class="d-print-none"></sortable-table>
      <div class="d-print-none d-flex flex-row" *ngIf="selectedIndex > -1">
        <student-meeting #openMeeting class="col"
                         [(meeting)]="meetings[selectedIndex]" [student_id]="studentId"
                         [district_id]="districtId" [meeting_topics]="topics" [editting]="editting"
                         [meeting_nextsteps]="nextsteps" [meeting_problems]="problems"
                         [meeting_strcons]="strcons" [meetings]="meetings">
        </student-meeting>
        <button-close (btnClick)="closeMeeting()"></button-close>
      </div>
      <student-meeting *ngFor="let m of meetings" class="d-none d-print-block" [meeting]="m"
                       [district_id]="districtId" [student_id]="studentId"
                       [meeting_topics]="topics"
                       [meeting_nextsteps]="nextsteps" [meeting_problems]="problems"
                       [meeting_strcons]="strcons"></student-meeting>
  `
})
export class StudentMeetingsComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  @ViewChild('openMeeting') openMeeting: StudentMeetingComponent;


  editting: boolean;
  topics: DistrictTypeDto[];
  problems: DistrictTypeDto[];
  nextsteps: DistrictTypeDto[];
  strcons: DistrictTypeDto[];

  selectedIndex: number;

  meetings: Meeting[];
  meeting: string;
  headers: Header[];

  constructor(
    private studentService: StudentService,
    private districtService: DistrictService,
    private typesService: TypesService,
    private router: ActivatedRoute,
    private modal: NgbModal) {
    this.headers = [];
    this.selectedIndex = -1;
    this.headers.push(new Header('Meeting Date', 'date', false, DataType.Date, {
      sortOrder: SortDirection.Descending,
      dateFormat: 'shortDate'
    }));
    this.headers.push(new Header('Topic', 'topic', false, DataType.String));
    this.editting = false;
  }

  ngOnChanges(): void {
    this.getData();
  }

  closeMeeting() {
    if (this.openMeeting.dirty &&
      this.openMeeting.touched &&
      this.openMeeting.editting &&
      !confirm('Unsaved data, are you sure you want to close?')) {
      return;
    } else {
      this.getData();
    }
    this.selectedIndex = -1;
  }

  newMeeting() {
    const i = this.meetings.push({
      id: null,
      creator: null,
      notes: '',
      date: null,
      topic: '',
      last_update: null,
      next_step_list: [],
      participant_ids: [],
      problems: [],
      strcons: [],
      next_steps: '',
      other_participants: ''
    });

    this.selectedIndex = this.meetings.length - 1;
  }

  open(meeting: Meeting) {
    for (let i = 0; i < this.meetings.length; i++) {
      if (meeting.id === this.meetings[i].id) {
        this.selectedIndex = i;
        this.editting = false;
        break;
      }
    }
  }

  delete(meeting: Meeting) {
    this.studentService.deleteMeeting(this.districtId, this.studentId, meeting.id).subscribe(r => {
      this.getData();
    });
  }

  getData() {
    if (!this.districtId && !this.studentId) {
      return;
    }
    this.studentService.getMeetings(this.districtId, this.studentId).subscribe(r => {
      this.meetings = r || [];
      this.meetings.sort((a: Meeting, b: Meeting) => {
        return a.date > b.date ? -1 : 1;
      });
    });
    this.typesService.getBasicType(this.districtId, TypesService.MeetingTopic).subscribe(r => {
      this.topics = r || [];
    });
    this.typesService.getBasicType(this.districtId, TypesService.MeetingNextStep).subscribe(r => {
      this.nextsteps = r || []
    });
    this.typesService.getBasicType(this.districtId, TypesService.MeetingProblem).subscribe(r => {
      this.problems = r || [];
    });
    this.typesService.getBasicType(this.districtId, TypesService.MeetingStrCon).subscribe(r => {
      this.strcons = r || [];
    });
  }
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDialog} from './confirmDialog.component';

@Component({
  selector: `button-delete`,
  template: `
    <button class="btn btn-danger d-print-none {{btnClass}}" type="button" (click)="onClick()" [disabled]="disabled"
            aria-label="Delete" ngbTooltip="Delete"><i class="fas fa-trash"></i></button>    `
})
export class DeletebuttonComponent {
  @Output() btnClick = new EventEmitter();
  @Input() disabled = false;
  @Input() btnClass = '';
  @Input('confirm') confirm = true;

  onClick(): void {
    if (this.confirm) {
      const m = this.modal.open(ConfirmDialog);
      m.componentInstance.message = 'Are you sure you want to delete?';
      m.result.then(result => {
        if (result === 'yes') {
          this.btnClick.emit();
        }
      }, result => {
        if (result === 'yes') {
          this.btnClick.emit();
        }
      });
    } else {
      this.btnClick.emit();
    }


  }

  constructor(private modal: NgbModal) {
  }
}

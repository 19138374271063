import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";
import {ChartRow} from "../../components/helpers/chartrow.class";
import {AttendanceOverview} from "../../models/student/attendance_overview.model";
import {StudentService} from "../../services/student.service";

@Component({
  selector: 'app-student-attendance',
  templateUrl: './student-attendance.component.html',
  styleUrls: ['./student-attendance.component.scss']
})
export class StudentAttendanceComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;

  public lineChartOptions: any = {responsive: true};
  public lineChartLegend = true;
  public lineChartType = 'bar';

  attendance: AttendanceOverview[];
  ready = false;
  showChart = false;

  get loading(): boolean {
    return !this.ready;
  }

  public dataset: Array<ChartRow> = [];
  public labels: string[] = ['Tardy', 'Excused', 'Unexcused', 'Total Days'];

  getDataSet() {
    this.dataset = new Array(this.attendance.length);

    for (let i = 0; i < this.attendance.length; i++) {
      this.dataset[i] = new ChartRow(
        [
          Number(this.attendance[i].tardy),
          Number(this.attendance[i].excused),
          Number(this.attendance[i].unexcused),
          Number(this.attendance[i].total_days)
        ],
        this.attendance[i].school_year
      );
    }
  }

  getData() {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.ready = false;
    this.studentService.getAttedance(this.districtId, this.studentId).subscribe(
      resp => {
        this.attendance = resp || [];
        this.attendance.sort(
          (a: AttendanceOverview, b: AttendanceOverview) => {
            return a.school_year.localeCompare(b.school_year);
          }
        );

        this.getDataSet();
      },
      () => {
        this.attendance = [];
      },
      () => {
        this.showChart = this.attendance.length > 0;
        this.ready = true;
      }
    );
  }

  constructor(private studentService: StudentService) {
    this.ready = false;
    this.attendance = [];
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }
}

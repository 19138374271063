
import {throwError as observableThrowError, Observable} from 'rxjs';
/**
 * Created by ptdave on 5/1/17.
 */
import {Injectable} from '@angular/core';
import {NewsArticle} from '../models/newsarticle.model';
import { HttpClient } from '@angular/common/http';




@Injectable()
export class NewsService {
  constructor(private http: HttpClient) {}

  getNewsArticles(): Observable<NewsArticle[]> {
    return this.http.get<NewsArticle[]>('/o/news');
  }

  private handleError(error: Response) {
    console.error(error);
    return observableThrowError(error.json() || 'Server error');
  }
}

import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {Observation} from '../../models/student/observation.model';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {StudentService} from '../../services/student.service';
import {DistrictAcademicArea, DistrictService} from '../../services/district.service';
import {Level, ToastService} from '../../services/toast.service';
import {StaffDto} from '../../common/dtos/staffDto';
import {TypesService} from '../../services/types.service';
import {LoggingService} from '../../services/logging';

@Component({
  selector: `student-observationdialog`,
  templateUrl: 'student.observationdialog.component.html'
})
export class StudentObservationDialogComponent implements OnInit {
  @Input() observation: Observation;
  @Input() edit: boolean;
  @Input() districtId: string;
  @Input() studentId: string;

  update: boolean;
  staff: StaffDto[];
  academic_areas: DistrictAcademicArea[];

  NotificationTitle = 'Observations';

  constructor(public activeModal: NgbActiveModal,
              private studentService: StudentService,
              private districtService: DistrictService,
              private typesService: TypesService,
              private toastService: ToastService,
              private log: LoggingService) {
    this.edit = false;
    this.academic_areas = [];

    this.update = false;
  }

  close() {
    if (this.edit && confirm('Unsaved data, are you sure you want to close?')) {
      this.activeModal.dismiss(this.update);
    } else if (!this.edit) {
      this.activeModal.dismiss(this.update);
    }
  }

  onStaffChange(form: NgForm, ids: string[]) {
    this.log.Log('new staff id\'s received', ids);

    this.observation.observer_ids = ids || [];
    form.form.markAsDirty();
  }

  submit(f: NgForm) {
    this.log.Log(this.observation);
    if (!this.observation.id) {
      this.studentService.postObservation(this.districtId, this.studentId, this.observation)
        .subscribe(r => {
          f.form.reset();
          this.observation = r;
          this.edit = false;
          this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved observation');
        });
    } else {
      this.studentService.patchObservation(this.districtId, this.studentId, this.observation)
        .subscribe(r => {
          f.form.reset();
          this.observation = r;
          this.edit = false;
          this.toastService.AddNotification(Level.SUCCESS, this.NotificationTitle, 'Saved observation');
        });
    }
  }

  ngOnInit(): void {
    this.typesService.getBasicType(this.districtId, TypesService.AcademicArea).subscribe(resp => {
      this.academic_areas = resp || [];
    });
    this.districtService.getActiveStaff(this.districtId).subscribe(resp => {
      this.staff = resp || [];
    });
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {StaffService} from '../../services/staff.service';
import {Building} from '../../models/district/building.model';
import {Staff} from '../../models/staff.model';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DistrictService} from '../../services/district.service';
import {Observable} from 'rxjs';

import {DistrictBuildingDto} from "../../models/district/districtBuildingDto";
import {StaffDto} from "../../common/dtos/staffDto";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: `staff-select-dialog`,
  styles: [
    `.list-group-slide {
      height: 30vh;
      max-height: 30vh;
      overflow-y: auto;
      overflow-x: hidden;
    }`
  ],
  template: `
    <div class="modal-header">
      <h4>Staff select</h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col d-flex flex-column">
          <!-- BUILDINGS -->
          <ul class="list-group list-group-slide">
            <li class="list-group-item" *ngFor="let b of buildings" [ngClass]="{'active':selectedBuilding === b.id}"
                (click)="selectBuilding(b)">
              {{b.name}}
            </li>
          </ul>
        </div>
        <div class="col d-flex flex-column">
          <ul class="list-group list-group-slide">
            <li class="list-group-item" *ngFor="let s of staff" [ngClass]="{'active':staffSelected(s.id)}"
                (click)="selectStaffMember(s)">
              {{s.displayName}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button-save (btnClick)="c(selected)" label="Save"></button-save>
      <button-cancel (btnClick)="d()" label="Cancel"></button-cancel>
    </div>
  `
})
export class StaffButtonSelectDialogComponent {
  @Input() multiple: boolean;             // Can we select multiple staff members?
  @Input() districtId: string;           // District_id of the student
  @Input() buildings: DistrictBuildingDto[];
  @Input() staff: StaffDto[];                 // Staff that are already pulled for the building
  @Input() selected: any;             // All the selected staff members
  @Input() selectedBuilding: string;      // Selected building
  @Output() valid: boolean;
  @Input() required: boolean;

  loading: boolean;

  constructor(private activeModal: NgbActiveModal, private districtService: DistrictService, private staffService: StaffService) {
    this.buildings = [];
    this.staff = [];
    this.multiple = false;
    this.loading = false;
    this.valid = false;
  }

  d() {
    this.activeModal.dismiss('dismiss');
  }

  c(value: any) {
    this.activeModal.close(this.selected);
    if (this.multiple) {
      this.valid = Array.isArray(this.selected) && this.selected.length > 0;
    } else {
      this.valid = this.selected > 0;
    }
  }

  selectBuilding(building: Building) {
    // We selected the same building
    if (this.selectedBuilding === building.id) {
      return;
    }

    this.loading = true;
    this.selectedBuilding = building.id;

    if (this.districtId === null || this.districtId === undefined || this.districtId.length === 0) {
      this.districtId = 'me';
    }

    this.districtService.getBuildingStaff(this.districtId, this.selectedBuilding).subscribe(resp => {
      this.loading = false;
      this.staff = resp;
    });
  }

  selectStaffMember(staff: Staff) {
    if (this.multiple) {
      // Toggle the staff member
      const index = this.selected.indexOf(staff.id);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(staff.id);
      }
    } else {
      // Clear it and put the staff member in
      this.selected = staff.id;
    }
  }

  // Is the staff member selected?
  staffSelected(id: string): boolean {
    if (this.multiple) {
      if (!this.selected) {
        this.selected = [];
      }
      return this.selected.indexOf(id) > -1;
    } else {
      return this.selected === id;
    }
  }
}

@Component({
  selector: `staff-select`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StaffSelectComponent),
      multi: true
    }
  ],
  template: `
    <div class="row">
      <div class="col">
        <ul class="list-group" *ngIf="multiple">
          <li *ngFor="let id of selected" class="list-group-item">
            {{id | staffId | async}}
          </li>
        </ul>
        <div *ngIf="!multiple">
          {{selected | staffId | async}}
        </div>
      </div>
      <div *ngIf="edittable">
        <button type="button" class="btn btn-primary {{btnClass}} d-print-none" aria-label="Select staff member(s)"
                (click)="open()"
                [disabled]="disabled">
          <i class="fa fa-users" aria-hidden="true"></i> {{label}}
        </button>
      </div>
    </div>

  `
})
export class StaffSelectComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() formControl: FormControl       // FormControl
  @Input() btnClass: string;              // Any class to append to the button
  @Input() label: string;                 // Label to append to the button icon
  @Input() multiple: boolean;             // Can we select multiple staff members?
  @Input() districtId: string;           // District_id of the student
  @Input() disabled = false;
  private _selected: any;
  @Input() set selected(value: any) {
    this._selected = value;
    this.checkValid();
  }

  get selected(): any {
    return this._selected;
  }

  @Output() selectedChange = new EventEmitter<any>();
  @Input() edittable = false;
  @Output() onChange = new EventEmitter();


  @Input() required: boolean;
  @Output() invalid: boolean;

  selectedBuilding: string;              // ID of the selected building

  buildings: DistrictBuildingDto[];          // All the buildings for the students district
  staff: StaffDto[];                 // All the staff members for the building

  touched: boolean = false;
  onTouched = () => {
  };

  constructor(private staffService: StaffService, private districtService: DistrictService, private modalService: NgbModal) {
    this.required = false;
    this.invalid = true;
    this.buildings = [];
    this.selected = [];
    this.staff = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkValid();
  }

  writeValue(obj: string): void {
    this.selected = obj;
    this.checkValid();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getStaffValue(id: string): Observable<StaffDto> {
    return this.staffService.get(id);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  checkValid(){
    this.invalid = this.selected?.length == 0;
  }

  open() {
    this.checkValid();
    this.markAsTouched();
    const modal = this.modalService.open(StaffButtonSelectDialogComponent, {
      size: 'lg',
      backdrop: 'static'
    });

    if (this.buildings.length === 0) {
      this.districtService.getBuildings(this.districtId).subscribe(resp => {
        this.buildings = resp;
        modal.componentInstance.buildings = resp;
      });
    } else {
      modal.componentInstance.buildings = this.buildings;
    }

    modal.componentInstance.selected = this.selected;
    modal.componentInstance.district_id = this.districtId;
    modal.componentInstance.districtId = this.districtId;
    modal.componentInstance.multiple = this.multiple;

    modal.result.then((resp) => {
      this.selected = resp;
      this.invalid = this.required && this.selected.length == 0;
      this.selectedChange.emit(this.selected);
      this.onChange.emit(this.selected);
    }, (resp) => {

    });
  }


  ngOnInit(): void {
    // We need to gather all the staff buildings
    this.checkValid();
  }
}

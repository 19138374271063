import {Component, OnInit} from "@angular/core";
import {DistrictService} from "../../services/district.service";
import {ActivatedRoute} from "@angular/router";
import {ImportDto} from "../../common/dtos/importDto";

@Component({
  selector: 'import-descriptions',
  templateUrl: 'importDesc.component.html'
})
export class ImportDescComponent implements OnInit {
  imports: ImportDto[];
  districtId: string;
  active: string;

  constructor(private districtService: DistrictService, private activeRoute: ActivatedRoute) {
    this.imports = [];
  }

  ngOnInit(): void {
    this.activeRoute.parent.params
      .subscribe(p => {
        this.districtId = p['districtId'];
        this.districtService.getImportTypes(this.districtId).subscribe(x => {
          this.imports = x || [];
        });
      });
  }
}

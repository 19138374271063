import { Component, OnInit, Input } from '@angular/core';
import { ToastService, ToastConfig, Toast } from '../../../services/toast.service';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-notification',
  template: `
    <div
      class='card {{toast.Config.BoxClass}}'
      [@changeState]="toast.State"
      (mouseenter)="onMouseEnter($event)"
      (mouseleave)="onMouseLeave($event)"
      style="width: 100%;">
      <div class="card-header">
        <div class="row">
          <div class="col mr-auto"><strong class="{{toast.Config.TextClass}}">{{toast.Title}}</strong></div>
          <button-close (btnClick)="close()" btnClass="btn-sm"></button-close>
        </div>
      </div>
      <div class="card-body bg-light">
        {{toast.Message}}
      </div>
    </div>
  `,
  // animations: [
  //   trigger('changeState', [
  //     state('active', style({
  //       opacity: 1,
  //     })),
  //     state('inactive', style({
  //       opacity: 0,
  //     })),
  //     transition('*=>*', animate('300ms')),
  //   ])
  // ],
  styles: []
})
export class NotificationComponent implements OnInit {
  @Input() toast: Toast;
  timeout: any;
  dismissKey: number;
  dismissValue: number;
  toState: string;

  constructor(private toastService: ToastService) { }

  close() {
    clearTimeout(this.timeout);
    this.toastService.DismissNotification(this.toast);

    if (this.toast.OnDismissed !== null) {
      this.toast.State = 'dismissed';

      this.toast.OnDismissed();
    }
  }

  onMouseEnter(e) {
    if (!this.toast.Config.AutoDismiss) {
      return;
    }
    this.dismissValue--;
    clearTimeout(this.timeout);
  }

  onMouseLeave(e) {
    if (!this.toast.Config.AutoDismiss) {
      return;
    }

    this.createTimer();
  }

  createTimer() {
    const that = this;
    this.dismissKey++;
    this.dismissValue = this.dismissKey;

    window.setTimeout(() => {
      if (that.dismissKey !== that.dismissValue) {
        return;
      }

      this.toastService.DismissNotification(this.toast);
    }, this.toast.Config.DismissedAfter * 1000);

  }

  ngOnInit() {
    this.dismissKey = 0;
    this.dismissValue = 0;
    if (this.toast.Config.AutoDismiss) {
      this.createTimer();
    }
    this.toast.State = 'active';
  }
}

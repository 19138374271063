import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';

import {StudentService} from '../../services/student.service';
import {ApprInst} from '../../models/student/apprinst.model';
import {PrintOptions} from "../../common/components/buttons/print-options.helper";

@Component({
  selector: `student-apprinst`,
  template: `
    <nav
      class="navbar"
      [ngClass]="{ 'd-print-none': !printOptions.getValue('display.headers') }"
    >
      <span class="navbar-brand">Appropriate Instruction</span>
    </nav>
    <table class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th class="overflow">
            <div class="rotateVert">Highly Qualified</div>
          </th>
          <th class="overflow">
            <div class="rotateVert">Curriculum based on CCSS?</div>
          </th>
          <th class="overflow">
            <div class="rotateVert">Teacher trained in curriculum</div>
          </th>
          <th class="overflow">
            <div class="rotateVert">Curriculum implemented in fidelity</div>
          </th>
          <th class="overflow">
            <div class="rotateVert">Student attended 85%</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Basic Reading</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="br_hq"
              [(ngModel)]="apprinst.br_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="br_glce"
              [(ngModel)]="apprinst.br_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="br_curriculum"
              [(ngModel)]="apprinst.br_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="br_fidelity"
              [(ngModel)]="apprinst.br_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="br_attendance"
              [(ngModel)]="apprinst.br_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Reading Fluency</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rf_hq"
              [(ngModel)]="apprinst.rf_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rf_glce"
              [(ngModel)]="apprinst.rf_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rf_curriculum"
              [(ngModel)]="apprinst.rf_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rf_fidelity"
              [(ngModel)]="apprinst.rf_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rf_attendance"
              [(ngModel)]="apprinst.rf_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Reading Comp.</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rc_hq"
              [(ngModel)]="apprinst.rc_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rc_glce"
              [(ngModel)]="apprinst.rc_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rc_curriculum"
              [(ngModel)]="apprinst.rc_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rc_fidelity"
              [(ngModel)]="apprinst.rc_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="rc_attendance"
              [(ngModel)]="apprinst.rc_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Writing Exp.</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="we_hq"
              [(ngModel)]="apprinst.we_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="we_glce"
              [(ngModel)]="apprinst.we_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="we_curriculum"
              [(ngModel)]="apprinst.we_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="we_fidelity"
              [(ngModel)]="apprinst.we_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="we_attendance"
              [(ngModel)]="apprinst.we_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Math Calc.</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mc_hq"
              [(ngModel)]="apprinst.mc_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mc_glce"
              [(ngModel)]="apprinst.mc_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mc_curriculum"
              [(ngModel)]="apprinst.mc_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mc_fidelity"
              [(ngModel)]="apprinst.mc_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mc_attendance"
              [(ngModel)]="apprinst.mc_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Math Problem Solving</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mp_hq"
              [(ngModel)]="apprinst.mp_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mp_glce"
              [(ngModel)]="apprinst.mp_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mp_curriculum"
              [(ngModel)]="apprinst.mp_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mp_fidelity"
              [(ngModel)]="apprinst.mp_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="mp_attendance"
              [(ngModel)]="apprinst.mp_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Listening Comp.</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="lc_hq"
              [(ngModel)]="apprinst.lc_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="lc_glce"
              [(ngModel)]="apprinst.lc_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="lc_curriculum"
              [(ngModel)]="apprinst.lc_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="lc_fidelity"
              [(ngModel)]="apprinst.lc_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="lc_attendance"
              [(ngModel)]="apprinst.lc_attendance"
              value="true"
            />
          </td>
        </tr>
        <tr>
          <td>Oral Expression</td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="oe_hq"
              [(ngModel)]="apprinst.oe_hq"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="oe_glce"
              [(ngModel)]="apprinst.oe_glce"
              value="true"
            />
          </td>
          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="oe_curriculum"
              [(ngModel)]="apprinst.oe_curriculum"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="oe_fidelity"
              [(ngModel)]="apprinst.oe_fidelity"
              value="true"
            />
          </td>

          <td>
            <input
              type="checkbox"
              class="checkbox"
              name="oe_attendance"
              [(ngModel)]="apprinst.oe_attendance"
              value="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
  `
})
export class StudentAppropriateinstructionComponent implements OnChanges {
  @Input() printOptions: PrintOptions;
  @Input() districtId: string;
  @Input() studentId: string;
  apprinst: ApprInst = new ApprInst();

  getData(): void {
    if(!this.districtId || !this.studentId) {
      return;
    }
    this.studentService.getApprInst(this.districtId, this.studentId).subscribe(
      resp => {
        this.apprinst = resp || new ApprInst();
      },
      () => {
        this.apprinst = new ApprInst();
      }
    );
  }

  save() {
    this.studentService
      .patchApprInst(this.districtId, this.studentId, this.apprinst)
      .subscribe(resp => {
        this.apprinst = resp;
      });
  }

  constructor(
    private studentService: StudentService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();
  }
}

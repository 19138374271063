import {Component, OnInit} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {ActivatedRoute} from '@angular/router';
import {TitleService} from '../../services/title.service';
import {DistrictBuildingDto} from '../../models/district/districtBuildingDto';
import {NgbModal, NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {ConfirmModal} from '../../components/modals/confirm/confirm.modal';
import {Level,ToastService} from '../../services/toast.service';

@Component({
  selector: `district-buildings`,
  templateUrl: 'buildings.component.html'
})
export class DistrictBuildingsComponent implements OnInit {
  districtId: string;
  buildings: DistrictBuildingDto[];
  active: string;

  constructor(private districtService: DistrictService,
              private activeRoute: ActivatedRoute,
              private modalService: NgbModal,
              private titleService: TitleService,
              private modal: NgbModal,
              private toastService: ToastService) {
    this.buildings = [];
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      if (p['districtId']) {
        this.districtId = p['districtId'];
        this.getData();
      }
    });
    this.titleService.setTitle('District Buildings');
  }

  getData() {
    this.districtService.getBuildings(this.districtId).subscribe(r => {
      this.buildings = r || [];
      this.buildings = this.buildings.filter(x => !x.disabled);
      this.sort();
    });
  }

  sort() {
    this.buildings.sort((a, b) => a.name.localeCompare(b.name));
  }

  cancel(form: NgForm) {
    this.active = null;
    if (form.touched) {
      this.getData();
    }
  }

  delete(building: DistrictBuildingDto) {
    const ref = this.modal.open(ConfirmModal, {
      backdrop: "static",
      centered: true,
    });
    ref.componentInstance.message = "You are about to delete a building. This will potentially cause issues on staff and students, are you sure?";
    ref.result.then(resp => {
      if(resp) {
        this.districtService.deleteBuilding(this.districtId, building.id).subscribe(() => {
          this.modal.dismissAll();
          this.getData();
        });

      }
    }, x => {});

  }

  submit(form: NgForm, building: DistrictBuildingDto) {
    if (building.id && building.id.length > 0 && building.id !== 'new') {
      this.districtService.patchBuilding(this.districtId, building).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, 'Building Saved', 'Building has been saved');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, 'Building Error', 'Failed to save building');
      });
    } else {
      if (building.id === 'new') {
        delete building.id;
      }
      this.districtService.postBuilding(this.districtId, building).subscribe(() => {
        this.toastService.AddNotification(Level.SUCCESS, 'Building Added', 'Building has been added');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, 'Building Error', 'Failed to add building');
      });
    }
  }

  addBuilding(nav: NgbNav) {
    const newBuilding: DistrictBuildingDto = {
      id: 'new',
      admins: [],
      code: '',
      name: 'New Building',
      disabled: false,
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: ''
    };
    this.buildings.push(newBuilding);
    this.active = 'new';
  }
}

import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DistrictService} from '../../services/district.service';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import {TitleService} from "../../services/title.service";
import {IDirty} from "../../services/canDeactivate.service";
import {Level, ToastService} from "../../services/toast.service";

@Component({
  selector: `district-domains`,
  templateUrl: 'domains.component.html'
})
export class DistrictDomainsComponent implements OnInit, IDirty {
  @Output() ready = new EventEmitter();
  @ViewChild('domainForm') domainForm: NgForm;

  districtId: string;
  domains: string[];
  domainToAdd: string;

  save(f: NgForm) {
    f.form.disable();
    this.domains = this.domains.filter(d => d.trim() !== "");
    if(!this.allValid()) {
      this.toastService.AddNotification(Level.ERROR, "Domain settings", "Not all domains are valid. Please correct and try again.");
      return;
    }
    this.districtService.updateDomains(this.districtId, this.domains)
      .subscribe(r => {
        f.form.markAsPristine();
        f.form.enable();
      }, err => {
        f.form.enable();
      });
  }

  allValid() {
    for(var i = 0; i < this.domains.length; i++) {
      if(this.validateDomain(this.domains[i])) {
        return false;
      }
    }
    return this.domains.length != 0;
  }

  validateDomain(domain: string): boolean {
    var regex = RegExp('(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\\.)+[a-zA-Z]{2,63}$)')
    return regex.test(domain);
  }

  addDomain(f: NgForm) {
    var regex = RegExp('(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\\.)+[a-zA-Z]{2,63}$)')
    if (regex.test(this.domainToAdd)) {
      this.domains.push(this.domainToAdd);
      this.domainToAdd = "";
      f.form.markAsTouched();
    }
  }

  removeDomain(index: number, f: NgForm) {
    this.domains.splice(index, 1);
    f.form.markAsTouched();
  }

  ngOnInit(): void {
    this.activeRoute.parent.parent.params.subscribe(p => {
      this.districtId = p['districtId'];
      this.districtService.getDomains(this.districtId).subscribe(r => {
        this.domains = r || [];
        this.ready.emit();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "Error", "Error loading domain information. Please try again.")

      });
      this.titleService.setTitle("District Domain Settings")
    });
  }

  constructor(
    private districtService: DistrictService,
    private activeRoute: ActivatedRoute,
    private titleService: TitleService,
    private toastService: ToastService) {
    this.domains = [];
  }

  isDirty(): boolean {
    return this.domainForm.dirty;
  }
}

import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: `confirm-dialog`,
  template: `
    <div class="modal-body">
      {{message}}
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-right" (click)="yesButton()">{{yes}}</button>
      <button class="btn btn-primary pull-right" (click)="noButton()">{{no}}</button>
    </div>
  `
})
export class ConfirmDialog {
  @Input() message = 'Are you sure?';
  @Input() yes = 'Yes';
  @Input() no = 'No';
  @Input() yesResult = 'yes';
  @Input() noResult = 'no';

  yesButton() {
    this.activeModal.close(this.yesResult);
  }

  noButton() {
    this.activeModal.close(this.noResult);
  }

  constructor(public activeModal: NgbActiveModal) {

  }
}

import {Component, Input, OnInit} from '@angular/core';
import {DistrictSchoolYear, DistrictService} from '../../../services/district.service';
import {ToastService, Level} from '../../../services/toast.service';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'district-school-year',
  templateUrl: 'schoolYear.component.html'
})
export class DistrictSchoolYearComponent implements OnInit {
  private districtId: string;
  schoolYears: DistrictSchoolYear[];
  active: any;
  selected: DistrictSchoolYear | null;

  ngOnInit(): void {
    this.route.parent.params.subscribe(p => {
      console.log(p);
      if (!p['districtId']) {
        return;
      }

      this.districtId = p['districtId'];
      this.getData();
    });
  }

  getData() {
    this.districtService.getSchoolYears(this.districtId).subscribe(r => {
      this.schoolYears = r || [];
    }, err => {
      this.toastService.AddNotification(Level.ERROR, "School Years", 'Failed to load school years.');
    });
  }

  select(schoolYear: DistrictSchoolYear) {
    this.selected = {...schoolYear};
    this.active = schoolYear.id;
  }

  delete(schoolYear: DistrictSchoolYear) {
    this.districtService.deleteSchoolYear(this.districtId, schoolYear.id).subscribe(r => {
      this.getData();
    }, err => {
      this.toastService.AddNotification(Level.ERROR, "School Years", 'School year failed to delete.');
    });
  }

  submit(form: NgForm, schoolYear: DistrictSchoolYear) {
    if(form.invalid) {
      return;
    }
    if(!schoolYear.id || schoolYear.id === 'new') {
      delete schoolYear.id;
      this.districtService.postSchoolYear(this.districtId, schoolYear).subscribe(r => {
        this.toastService.AddNotification(Level.SUCCESS, "School Years", 'School year saved');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "School Years", 'School year failed to save.');
      });
    } else {

      this.districtService.patchSchoolYear(this.districtId, schoolYear).subscribe(r => {
        this.toastService.AddNotification(Level.SUCCESS, "School Years", 'School year saved');
        this.getData();
      }, err => {
        this.toastService.AddNotification(Level.ERROR, "School Years", 'School year failed to save.');
      });
    }
  }

  addYear() {
    const newYear: DistrictSchoolYear =  {
      id: 'new',
      districtId: this.districtId,
      name: '',
      startDate: null,
      endDate: null
    };
    this.schoolYears.push(newYear);
    this.selected = {...newYear};
    this.active = 'new';
  }

  constructor(
    private districtService: DistrictService,
    private toastService: ToastService,
    private route: ActivatedRoute,
  ) {
  }
}
